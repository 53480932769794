import React from 'react';

import { ChartContent } from './ChartContent';

export const ElevationSpatialViz = () => {
  return (
    <div className="pt-20" style={{ }}>
      <ChartContent
        chart={
          <div className="flex justify-center">
            <img
              src="spatial/Geo_elevation.jpg"
              alt="elevation raster"
              className="w-full max-w-md sm:max-w-lg md:max-w-xl lg:max-w-3xl"
              style={{ height: 'auto' }}
              // style={{  width:950, height: 600}}
            />
          </div>
        }
      />
    </div>
  );
};
