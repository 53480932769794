import React from 'react'
import { scaleLinear } from 'd3-scale'

import { TEMPERATURES_BARS } from '../../lib/constants'
import { TEXT_SHADOW } from '../../lib/styleUtils'
import { MultipleBarsBarchartcopy } from './MultipleBarsBarchartcopy'
import { range } from 'd3'

export const TemperaturesChartcopy = ({ datum, size, colorScale }) => {
    //Temperature chart changing
    const lineLengthScale = scaleLinear().domain([0, 45]).range([0, size])
  
    return (
        
        <div style={{ position: 'relative ' }}   >
            <div style={{ position: 'absolute', right: 2, top: 0, fontSize: 10, textShadow: TEXT_SHADOW }}>{datum.annual && (<div>{datum.annual.toFixed(2)} </div>)}</div>
            <div style={{ position: 'absolute', right: 2, top: 10, fontSize: 10, color: '#A880A1', textShadow: TEXT_SHADOW }}>{datum.diff && (<div>{datum.diff.toFixed(2)} </div>)}</div>
            
            <svg x={0} y={0} width={size} height={size} >
             
                <g  >
                    <MultipleBarsBarchartcopy 
                        groups={TEMPERATURES_BARS }
                        datum={datum}
                        width={size}
                        height={size*1.1}
                        lineLengthScale={lineLengthScale }
                       colorScale={colorScale}
                    />
                </g>
                
            </svg>
            
        </div>
        
       
    )
}
