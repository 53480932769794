import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

import { ChartContent } from './ChartContent'
import { Map } from '../chartContainers/Map'
import { HDIChart } from '../smallCharts/HDIChart'

import { HDI_COLOR_SCALE } from '../../lib/constants'

export const HDIViz = observer(() => {
  const {
    data: { humanDevelopmentIndex },
  } = useMst()

  return (
    <div style={{  paddingTop:20 }}>
    <ChartContent 

      isMapChart={true}
      chart={
        <Map 
          dataset={humanDevelopmentIndex}
          isInteractive={true}
          borderColor="#BEAB70"
          colorScale={HDI_COLOR_SCALE}
          MapTileChart={HDIChart}
        />
      }
    />
    </div>
  )
})
