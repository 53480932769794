import React from 'react'
import { ReactCompareSlider, ReactCompareSliderImage, ReactCompareSliderHandle } from 'react-compare-slider';

const images = { 1975: 'LandUse1975', 1975: 'LandUse_2000', 2020: 'LandUse2021' }

const LandUseSpacial = ({ year }) => {
  return (
    <>
      {year === 1975 ? (
        <div className='flex justify-between'>
          <div>
            {year}
          </div>
          <div style={{ width: 100, backgroundColor: 'white' }}>
          </div>
        </div>
      ) : (
        <div className='flex justify-end'>
          {year}
        </div>
      )}
      <ReactCompareSliderImage src={`spatial/${images[year]}.jpg`} style={{ height: 'auto' }} alt={`Image ${year}`} />
    </>
  )
}

export const LandUseSpatialViz = () => {
  return (
    <div className='pr-1' style={{ height: '90vh'}}>
      <ReactCompareSlider
        handle={<ReactCompareSliderHandle style={{ color: '#8e577d' }} />}
        itemOne={<LandUseSpacial year={1975} />}
        itemTwo={<LandUseSpacial year={2020} />}
      />
    </div>
  )
}
