import React, { useState } from 'react'
import { downloadElementToImage } from '../utils/utils';
import { MapTile } from '../components/chartContainers/MapTile'
import { HDIChart } from '../components/smallCharts/HDIChart'
// import DownloadIcon from '@mui/icons-material/Download';
import { ReactComponent as DownloadIcon } from '../assets/download-icon.svg';

import { MAP_TILE_SIZE_DESKTOP } from '../lib/mapConstants'
import { HDI_COLOR_SCALE2 } from '../lib/constants'
import humanDevelopmentIndex from '../dataset/humanDevelopmentIndex.json'
const loadingIcon = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

export const HDILegend = () => {
  const [isDownloading, setIsDownloading] = useState(false);

  const mockDatumHDI = {
    stateCode: 'LA',
    stateName: 'Lagos',
    HDI: 0.686,
    incomeIndex: 0.676,
    educationIndex: 0.724,
    healthIndex: 0.659,
   
  }
  const mockDatumAvg = {
    stateCode: 'LA',
    stateName: 'Lagos',
    incomeIndex: 0.676,
    educationIndex: 0.724,
    healthIndex: 0.659,
  }

  const legendLabels = ['Health Index', 'Education Index', 'Income Index']
  const handleCaptureDownoadClick = () => {
    const sectionComponent = document.getElementById("subsection");
    const snapshotElem = sectionComponent.getElementsByClassName("chartcontent-component")?.[0];
    if (snapshotElem) {
      setIsDownloading(true);
      setTimeout(async () => {
        await downloadElementToImage(snapshotElem),"HDI.png";
        setIsDownloading(false);
      }, 0)
    }
  }
  return (
    <div className="ml-10 flex flex-col gap-y-8">
      <div className="font-bold">How to read it</div>
      <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP,marginTop:-20 }}>
        <div className="absolute">
          <MapTile
            size={MAP_TILE_SIZE_DESKTOP}
            borderColor="#BEAB70"
            MapTileChart={() => (
              <div className="absolute text-greyDark top-1 left-1 text-xs">LA</div>
            )}
          />
          <div
            className="absolute top-0 text-sm text-greyDark whitespace-nowrap font-medium"
            style={{ left: 90 }}
          >
            State code
          </div>
        </div>
      </div>

      <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP }}>
        <div
          className="absolute"
          style={{ minHeight: MAP_TILE_SIZE_DESKTOP, minWidth: MAP_TILE_SIZE_DESKTOP }}
        >
          <MapTile
            size={MAP_TILE_SIZE_DESKTOP}
            datum={mockDatumHDI}
            borderColor="#BEAB70"
            MapTileChart={HDIChart}
            colorScale={HDI_COLOR_SCALE2}
          />

          <div className="absolute top-0 text-xs text-greyDark" style={{ right: '105%' }}>
            1
          </div>
          <div className="absolute bottom-0 text-xs text-greyDark" style={{ right: '105%' }}>
            0
          </div>
        </div>

        <div
          className="absolute text-sm text-greyDark"
          style={{ left: MAP_TILE_SIZE_DESKTOP + 15 }}
        >
          State HDI score and indicators
        </div>
      </div>

      <div className="flex flex-col gap-y-1">
        {HDI_COLOR_SCALE2.domain().map((label, index) => (
          <div className="legend-element flex justify-between" key={label}>
            <div className="flex gap-x-1">
              <div className="square w-4 h-4" style={{ background: HDI_COLOR_SCALE2(label) }} />
              <div className="label text-sm">{legendLabels[index]}</div>
            </div>
          </div>
        ))}
      </div>

      <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP }}>
        <div
          className="absolute"
          style={{ minHeight: MAP_TILE_SIZE_DESKTOP, minWidth: MAP_TILE_SIZE_DESKTOP }}
        >
          <MapTile
            size={MAP_TILE_SIZE_DESKTOP}
            dataset={humanDevelopmentIndex}
            datum={mockDatumAvg}
            borderColor="#BEAB70"
            MapTileChart={HDIChart}
            colorScale={HDI_COLOR_SCALE2}
          />

          <div className="absolute top-0 text-xs text-greyDark" style={{ right: '105%' }}>
            1
          </div>
          <div className="absolute bottom-0 text-xs text-greyDark" style={{ right: '105%' }}>
            0
          </div>
        </div>

        <div
          className="absolute text-sm text-greyDark"
          style={{ left: MAP_TILE_SIZE_DESKTOP + 15 }}
        >
          National HDI
        </div>
      </div>
      <div style={{ display: 'flex',  alignItems: 'left',marginLeft:-10 }}>
      <button type="button" onClick={handleCaptureDownoadClick} style={{ color: 'rgb(171, 148, 78)' }}disabled={isDownloading} className="flex items-center">
{isDownloading ? 'Downloading...' : <span className=" text-black"style={{  fontSize:16,fontWeight:700,letterSpacing:0.5 }} >DOWNLOAD</span>}
{!isDownloading && (
  <DownloadIcon
    className="ml-2"
    style={{  width: '26px', height: '26px', stroke: 'rgb(171, 148, 78)', strokeWidth: '2' }} 
  />
)}
</button>
        {isDownloading && <img width={24} src={loadingIcon} alt='' />}
      </div>
    </div>
  )
}
