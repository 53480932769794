import React from 'react';
import { useNavigate } from 'react-router-dom';
import { VerticalIndexMenu2 } from './VerticalIndexMenu2';
import Header from './Header';
import { Footer } from './Footer';


export function Annex() {
  const navigate = useNavigate();

  return (
    
    <div className=" flex flex-col min-h-screen"style={{ fontFamily: 'ProximaNova' }}>
      
      <Header />
      <main className="flex-1">
        <section className="p-6">
          <div className="text-3xl font-bold mb-6">Annex</div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {/* Geography & Climate Section */}
            <div style={{backgroundColor:'#ebe1e5'}}  className="p-4  rounded-md">
              <h2 className="text-2xl font-semibold mb-4">Geography & Climate</h2>
              <div className="space-y-4">
                {[
                  {
                    title: 'Geographical tapestry',
                    description: 'NASA. 2020. Shuttle Radar Topography Mission (SRTM). Access source : ',
                    link: 'https://www2.jpl.nasa.gov/srtm/',
                    linkText: 'link'
                  },
                  {
                    title: 'Land Use',
                    description: 'Source: 1975 - 2013: Tappan, G. G., Cushing, W.M., Cotillon, S.E., Mathis, M.L., Hutchinson, J.A., Herrmann, S.M., and Dalsted, K.J., 2016, West Africa Land Use Land Cover Time Series: U.S. Geological Survey data release. Access source: ',
                    link: 'https://www.sciencebase.gov/catalog/item/5deffc05e4b02caea0f4f3fc',
                    linkText: 'link'
                  },
                  {
                    description: '2020: Karra, Kontgis, et al. “Global land use/land cover with Sentinel-2 and deep learning.” IGARSS 2021-2021 IEEE International Geoscience and Remote Sensing Symposium. IEEE, 2021. Access source :',
                    link: 'https://www.arcgis.com/home/item.html?id=d6642f8a4f6d4685a24ae2dc0c73d4ac',
                    linkText: 'link'
                  },
                  {
                    title: 'Urbanisation Trend',
                    description: '	Source:Pesaresi M., Politis P. (2023):GHS-BUILT-S R2023A - GHS built-up surface grid, derived from Sentinel2 composite and Landsat, multitemporal (1975-2030)European Commission, Joint Research Centre (JRC).  Access source :',
                    link: 'https://data.jrc.ec.europa.eu/dataset/9f06f36f-4b11-47ec-abb0-4f8b7b1d72ea,%20doi:10.2905/9F06F36F-4B11-47EC-ABB0-4F8B7B1D72EA',
                    linkText: 'link'
                  },
                  {
                    title: 'Metropolis chronicles: 7 selected cities	',
                    description: '1975 - 2013: Tappan, G. G., Cushing, W.M., Cotillon, S.E., Mathis, M.L., Hutchinson, J.A., Herrmann, S.M., and Dalsted, K.J., 2016, West Africa Land Use Land Cover Time Series: U.S. Geological Survey data release. Access source :  ',
                    link: 'http://dx.doi.org/10.5066/F73N21JF',
                    linkText: 'link'
                  },
                  {
                   
                    description: '2020: Karra, Kontgis, et al. “Global land use/land cover with Sentinel-2 and deep learning.” IGARSS 2021-2021 IEEE International Geoscience and Remote Sensing Symposium. IEEE, 2021. Access source :',
                    link: 'https://www.arcgis.com/home/item.html?id=d6642f8a4f6d4685a24ae2dc0c73d4ac',
                    linkText: 'link'
                  },

                  




                  {
                    title: 'Road density per state	',
                    description: 'Open street map OSM. 2020. OpenStreetMap data for Nigeria. Access source :',
                    link: 'https://download.geofabrik.de/africa/nigeria.html',
                    linkText: 'link'
                  },
                  {
                    title: 'Precipitation',
                    description: 'National Aeronautics and Space Administration (NASA) Langley Research Center (LaRC) Prediction of Worldwide Energy Resource (POWER)Project funded through the NASA Earth Science/Applied Science Program: Monthly & Annual Precipitation, POWER Data Access Viewer v2.0.0,1981-2020. Access source :',
                    link: ' https://developers.google.com/earth-engine/datasets/catalog/MODIS_061_MOD11A2',
                    linkText: 'link'
                  },
                  {
                    title: 'Temperature',
                    description: 'National Aeronautics and Space Administration (NASA) Langley Research Center (LaRC) Prediction of Worldwide Energy Resource (POWER)Project funded through the NASA Earth Science/Applied Science Program: Monthly & Temperature, POWER Data Access Viewer v2.0.0,1981-2020. Access source :',
                    link: 'https://developers.google.com/earth-engine/datasets/catalog/MODIS_061_MOD11A2',
                    linkText: 'link',
                    
                  },
                  {
                    title: 'Surface Temperature',
                    description: 'Wan, Z., S. Hook, G. Hulley. MODIS/Terra Land Surface Temperature/Emissivity Daily L3 Global 1km SIN Grid V061. 2021, distributed by NASA EOSDIS Land Processes DAAC.[Accessed 2022-10-06] Access source :',
                    link: 'https://lpdaac.usgs.gov/products/mod11a1v061/',
                    linkText: 'link'
                  },
                  
                ].map(({ title, description,link,linkText, }, index) => (
                  <div key={index} className="flex flex-col">
                    <div className="font-bold text-lg mb-2">{title}</div>
                    <div>{description} <a class="custom-link"  href={link} target="_blank" rel="noopener noreferrer">{linkText}</a></div>
                  </div>
                ))}
              </div>
            </div>

            {/* Economy Section */}
            <div style={{backgroundColor:'rgba(251, 231, 209, 0.5)'}}  className="p-4   rounded-md">
              <h2 className="text-2xl font-semibold mb-4">Economy</h2>
              <div className="space-y-4">
                {[
                  {
                    title: 'Nigeria compared: GDP and GDP per capita',
                    description: 'International Monetary Fund. 2022. World Economic Outlook [Accessed March 2023].Access source : ',
                    link: 'https://www.imf.org/en/Publications/WEO/weo-database/2022/October',
                    linkText: 'link'
                  },
                  {
                    title: 'Economic structure: Sector contribution to GDP',
                    description: 'National Bureau of Statistics. 2022. Nigerian Gross Domestic Product Report (Q2 2022). Access source : ',
                    link: 'https://nigerianstat.gov.ng/elibrary',
                    linkText: 'link'
                  },
                  {
                    title: 'From Nigeria to Nigeria:Trade flows by partner and product',
                    description: 'United Nations Statistics Division. 2021. UN Comtrade database.[Accessed March 2023] Access source : ',
                    link: ' https://comtrade.un.org/',
                    linkText: 'link'
                    
                  },
                  // Add more data objects as needed
                ].map(({ title, description,link,linkText, }, index) => (
                  <div key={index} className="flex flex-col">
                    <div className="font-bold text-lg mb-2">{title}</div>
                    <div>{description} <a class="custom-link" href={link} target="_blank" rel="noopener noreferrer">{linkText}</a></div>
                  </div>
                ))}
              </div>
            </div>

            {/* Demographics Section */}
            <div style={{backgroundColor:'#e9e1cf'}} className="p-4   rounded-md">
              <h2 className="text-2xl font-semibold mb-4">Demographics</h2>
              <div className="space-y-4">
                {[
                  {
                    title: 'If Nigeria was 100 people',
                    description: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.National Bureau of Statistics. 2020. Nigeria Living Standards Survey. Access source : ',
                    link: 'https://nigerianstat.gov.ng/elibrary?queries=Nigeria%20Living%20Standards%20Survey',
                    linkText: 'link'

                  },
                  {
                    title: 'Population distribution: Est. population density and growth',
                    description: 'WorldPop.2020. Population Counts. Access source : ',
                    link: 'https://hub.worldpop.org/geodata/summary?id=49705',
                    linkText: 'link'
                  },
                  {
                    title: 'Population growth',
                    description: '	Schiavina M., Freire S., MacManus K. (2022) GHS-POP R2022A - GHS population grid multitemporal (1975-2030).European Commission, Joint Research Centre (JRC). Access source : ',
                    link: 'http://data.europa.eu/89h/d6d86a90-4351-4508-99c1-cb074b022c4a, doi:10.2905/D6D86A90-4351-4508-99C1-CB074B022C4A',
                    linkText: 'link'
                  },
                  {
                    title: 'Human Development Index	',
                    description: 'Global Data Lab. Subnational Human Development Index (2020). Nijmegen School of Management of Radboud University.Access source :',
                    link: 'https://globaldatalab.org/areadata/table/shdi/NGA/',
                    linkText: 'link'
                  },
                  {
                    title: 'Nigeria at night	',
                    description: 'Earth Observation Group, Payne Institute for Public Policy. C. D. Elvidge, M. Zhizhin, T. Ghosh, F-C. Hsu, "Annual time series of global VIIRS nighttime lights derived from monthly averages: 2012 to 2019", Remote Sensing (In press). Access source :',
                    link: 'https://developers.google.com/earth-engine/datasets/catalog/NOAA_DMSP-OLS_NIGHTTIME_LIGHTS?hl=en',
                    linkText: 'link'
                  },
                  {
                    title: 'Schools per capita	',
                    description: '	GRID3. 2020. Designated institution to provide learning spaces and learning environments for the teaching of students. Access source :',
                    link: 'https://grid3.gov.ng/datasets?q=schools',
                    linkText: 'link'
                  },
                  {
                    title: 'Health facilities per capita	',
                    description: 'GRID3. 2020. Entities that provide medical and/or healthcare services and/or engage in the use generally of natural and/or artificial materials to create or dispense drugs; hospitals, pharmacy, clinic, health post dispensary. Access source :',
                    link: 'https://grid3.gov.ng/datasets?&sector=[%22health-and-safety%22]',
                    linkText: 'link'
                  },
                  
            
                ].map(({ title, description,link,linkText, }, index) => (
                  <div key={index} className="flex flex-col">
                    <div className="font-bold text-lg mb-2">{title}</div>
                    <div>{description} <a class="custom-link" href={link} target="_blank" rel="noopener noreferrer">{linkText}</a></div>
                  </div>
                ))}
              </div>
            </div>

            {/* Life & Work Section */}
            <div style={{backgroundColor:'#e0f4f3'}} className="p-4  rounded-md">
              <h2 className="text-2xl font-semibold mb-4">Life & Work</h2>
              <div className="space-y-4">
                {[
                  {
                    title: 'A day in the life of: Average time spent on main activities',
                    description: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.'
                  },
                  {
                    title: 'How your hustle be na?: Main Type of Work',
                    description: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.'
                  },
                  {
                    title: 'How you dey move around?: Main modes of transportation',
                    description: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.'
                  },
                  {
                    title: 'How light be for your area?: Average daily power cuts experienced',
                    description: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.'
                  },
                  {
                    title: 'na God o: Attendance of religious services',
                    description: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.'
                  },
                  {
                    title: 'Ajala D’ Traveller: State of origin vs state of residence',
                    description: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.'
                  },
                  {
                    title: 'Japa sef dey pay o!: Receipt of external remittances',
                    description: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.'
                  },
                  {
                    title: 'U get internet?: Internet use',
                    description: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.'
                  },
                  {
                    title: 'Oya, make we check google: (October 2022 to September 2023)',
                    description: 'Google trends. Access source :',
                    link: 'https://trends.google.com/trends/?geo=NG',
                    linkText: 'link'
                    
                  },
                  // Add more data objects as needed
                ].map(({ title, description,link,linkText, }, index) => (
                  <div key={index} className="flex flex-col">
                    <div className="font-bold text-lg mb-2">{title}</div>
                    <div>{description} <a class="custom-link" href={link} target="_blank" rel="noopener noreferrer">{linkText}</a></div>
                  </div>
                ))}
              </div>
            </div>
            {/* Perceptions & Attitudes */}
            <div style={{backgroundColor:'#e2ddda'}}  className="p-4  rounded-md">
              <h2 className="text-2xl font-semibold mb-4">Perceptions & Attitudes
              </h2>
              <div className="space-y-4">
                {[
                  {
                    title: 'Most Significant Issues Facing Nigeria',
                    description: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.'
                  },
                  {
                    title: 'Agree or disagree',
                    description: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.'
                  },
                  {
                    title: 'How concerned are you about...',
                    description: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.'
                  },
                  {
                    title: 'Change in Financial Well-being',
                    description: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.'
                  },
                  {
                    title: 'Civic participation',
                    description: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.'
                  },
                  {
                    title: 'Wetin dey happen?: Ratings of overall satisfaction with life',
                    description: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.'
                  },
                  
             
                ].map(({ title, description }, index) => (
                  <div key={index} className="flex flex-col">
                    <div className="font-bold text-lg mb-2">{title}</div>
                    <div>{description}</div>
                  </div>
                ))}
                
              </div>
              
            </div>

          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
