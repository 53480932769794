import React from 'react'
import { max } from 'lodash-es'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

import { ChartContent } from './ChartContent'
import { DoubleSquareGrid } from '../chartContainers/DoubleSquareGrid'
import { ImportExportChart } from '../smallCharts/ImportExportChart'
import { TreeMapChart } from '../smallCharts/TreeMapChart'

import { SQUARE_TILE_SIZE } from '../../lib/constants'

export const ImportExportViz2 = observer(() => {
  const {
    data: { fromNigeriaToNigeriaTop5ByCategory,fromNigeriaToNigeriaImportExport  },
    filter: { importExportSelectedDataset, setImportExportSelectedDataset },
  } = useMst()

  return (
    <div className="import-component"style={{  paddingTop: 85 }}>
    <ChartContent
      chart={
        <DoubleSquareGrid
          SquareTileChart={
            importExportSelectedDataset === 'Import and export(USD billion)' ? TreeMapChart : ImportExportChart 
          }
          dataset={
            importExportSelectedDataset === 'Import and export(USD billion)'
              ? fromNigeriaToNigeriaTop5ByCategory
              : fromNigeriaToNigeriaImportExport
          }
          size={SQUARE_TILE_SIZE + 4}
          gridGap={10}
          borderColor="#F2B572"
          labels={['import', 'export']}
          isInteractive={importExportSelectedDataset !== 'Import and export(USD billion)'}
          maxValue={Math.max(
            max(fromNigeriaToNigeriaImportExport.map((d) => d.import)),
            max(fromNigeriaToNigeriaImportExport.map((d) => d.export))
          )}
        />
      }
    />
    <br/><br/>
    </div>
  )
})
