import React from 'react'
import { max } from 'lodash-es'
import { scaleSqrt, scaleLinear } from 'd3-scale'

import roadDensity from '../../dataset/roadDensity.json'
import { TEXT_SHADOW } from '../../lib/styleUtils'

export const StrengthChart = ({ datum, size }) => {
    
    const densityScale = scaleLinear()
        .domain([0, 40])
        .range([0, size - 5])

    const lengthScale = scaleLinear()
        .domain([0, max(roadDensity.map((d) => d.length))])
        .range([0, size - 5])

    const width = densityScale(datum.density)
    return (
        <>
            {console.log(datum, size)}
            <svg x={0} y={0} width={size} height={size}>
                {datum.density && (
                    <rect
                        x={0}
                        y={size - width}
                        width={width}
                        height={width}
                        fill="rgba(82, 164, 157)"
                    />
                )}
            </svg>

            {datum.density && (
                <div
                    className="absolute left-1 bottom-0.5 text-black text-sm font-bold"
                    style={{ textShadow: TEXT_SHADOW }}
                >
                    {(datum.density).toFixed(2)}%
                </div>
            )}
        </>
    )
}
