import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

const FILTER_DATA = {
    significantIssues: '',
    significantIssues: '',
}

export const TreeChartFilter2 = observer(({ chartType = 'significantIssues' }) => {
    const {
        filter: { treeChartSelected, setTreeChartSelected },
    } = useMst()

    const [filterData, setFilterData] = useState(FILTER_DATA.concern);
    const isSelected = 1;

    useEffect(() => {
        switch (chartType) {
            case 'significantIssues':
                setFilterData(FILTER_DATA.significantIssues)
                break;
            case 'significantIssues':
                setFilterData(FILTER_DATA.significantIssues)
                break;
            default:
                break;
        }
        setTreeChartSelected(chartType)
    }, [chartType])

    return chartType !== 'significantIssues' ?
        (
            <div className="flex flex-col" >
                <div
                    className={`flex flex-col cursor-pointer ${isSelected ? 'font-bold' : ''}`}
                    style={{ width: 300, color: isSelected ? '#8D796E' : '#000000' }}
                >
                    <div className="capitalize" style={{ textOverflow: 'ellipsis',fontSize: 20 }}>{filterData}</div>
                </div>
            </div>
        ) : null
})
