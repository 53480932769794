import { observer } from 'mobx-react-lite'
import React from 'react'
export const PopulationIncreaseSummerystatus = observer(() => {

  
  return (
    <div className=" ">
      <div style={{ borderTop: '1px solid lightgray',marginLeft:-10 ,marginBottom:0}}>   </div> 
      
      <div className=' xl:col-span-2 flex flex-col  top-0 'style={{ marginRight: 0,marginTop: 0 }}>

<table className=' border-b    flex-1 ' style={{  marginRight: 0 }}>
<tr >
<th ></th>
<th></th>
<th></th>
<th></th>
</tr>


<tr>
<td style={{ minWidth:0 }}>
 <tr class="custom-Demographics1"> State with the .... </tr>
</td>





<td style={{ minWidth:0 }}>
 <tr class="custom-Demographics1"> highest population density</tr>
<tr class="custom-Demographics2"> Lagos (4,033 persons/km<sup>2</sup>) </tr>


</td>

<td style={{ minWidth:0 }}>
 <tr class="custom-Demographics1"> lowest population density</tr>
<tr class="custom-Demographics2">  Taraba (53 persons/km<sup>2</sup>) </tr>

</td>

<td style={{ minWidth:0 }}>
 <tr class="custom-Demographics1"> highest %change in population density (2000 to 2020)</tr>
<tr class="custom-Demographics2"> FCT (400%)  </tr>


</td>



<td style={{ minWidth:0 }}>
 <tr class="custom-Demographics1"> Lowest %change in population density (2000 to 2020)</tr>
<tr class="custom-Demographics2"> Anambra (55%)  </tr>
</td>
</tr>
 
 
 
 </table>
 <div  className='flex-1 text-greyDark' style={{ fontSize: 10 ,fontWeight:500,marginTop: 5  }}>
Source: WorldPop.2020. Population Counts. Available <a class="custom-link" href="https://hub.worldpop.org/geodata/summary?id=49705" target="_blank" > here</a>
 
      
</div>
 </div>
 
 
      
 
      </div>

      


  )

})
