import { observer } from 'mobx-react-lite'
import React from 'react'
export const GrowthSpacialSummerystatus = observer(() => {

  
  return (
    <div className=" ">
      <div style={{ borderTop: '1px solid lightgray',marginLeft:-10 ,marginBottom:-12}}>   </div> 
      
        <div className=' xl:col-span-2 flex flex-col  top-0 'style={{ marginRight: 0,marginTop: 12 }}>

 <table className=' border-b    flex-1 ' style={{  marginRight: 0,maxWidth:3000  }}>
 <tr >
 
 <th ></th>
 <th></th>
 <th></th>
 <th></th>
 </tr>

 
 <tr>
 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> Akwa Ibom/Uyo</tr>
 <tr class="custom-Geography2"> 1975:51k  2020:1.136M (sqkm)<br/>Increase: 75%</tr>
 </td>
 


 
 
 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> Anambra /Onitsha</tr>
 <tr class="custom-Geography2"> 1975:224K 2020:1.415M (sqkm)<br/>Increase: 37%</tr>
 </td>
 
 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> FCT /Abuja</tr>
 <tr class="custom-Geography2"> 1975:77K 2020: 3.278M (sqkm)<br/>Increase:590%</tr>
 </td>
 
 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> Kano/Kano</tr>
 <tr class="custom-Geography2"> 1975:855K  2020:3.999M (sqkm)<br/>Increase:266%</tr>
 </td>


 <td style={{ minWidth:0 }}>
 <tr style={{ fontSize: 11,textTransform: 'uppercase' ,fontWeight: 900,padding:3 ,color: '#966084'}}> Lagos/Lagos</tr>
 <tr class="custom-Geography2"> 1975:1.890M  2020:14.368M (sqkm)
<br/>Increase: 1103%</tr>
 </td>
 
 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> Borno/Maiduguri</tr>
 <tr class="custom-Geography2"> 1975:218K  2020:786K (sqkm)<br/>Increase:60%</tr>
 </td>


 </tr>
 
 </table>
 <div  className='flex-1 text-greyDark' style={{ fontSize: 10 ,minHeight:40,fontWeight:500,marginTop: 5  }}>
Source: 1975 - 2013: Tappan, G. G., Cushing, W.M., Cotillon, S.E., Mathis, M.L., Hutchinson, J.A., Herrmann, S.M., and Dalsted, K.J., 2016, West Africa Land Use Land Cover Time Series: U.S. Geological Survey data release. Available <a class="custom-link" href="http://dx.doi.org/10.5066/F73N21JF" target="_blank" > here</a>  .
2020: Karra, Kontgis, et al. “Global land use/land cover with Sentinel-2 and deep learning.” IGARSS 2021-2021 IEEE International Geoscience and Remote Sensing Symposium. IEEE, 2021. Available <a class="custom-link" href="https://www.arcgis.com/home/item.html?id=d6642f8a4f6d4685a24ae2dc0c73d4ac" target="_blank" > here</a>   

 
      
</div>
 </div>
 
 
      
 
      </div>

      


  )

})
