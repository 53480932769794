import { observer } from 'mobx-react-lite'
import React from 'react'
export const UrbanisationSummerystatus = observer(() => {

  
  return (
    <div className=" ">
            <div style={{ borderTop: '1px solid lightgray',marginLeft:-10 }}>   </div> 
      
        <div className=' xl:col-span-2 flex flex-col  top-0 'style={{ marginRight: 0,marginTop: 0 }}>

 <table className=' border-b    flex-1 ' style={{  marginRight: 0,maxWidth:3000  }}>
 <tr >
 <th ></th>
 <th></th>
 <th></th>
 <th></th>
 </tr>

 
 <tr>
 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> States with...</tr>
 </td>
 


 
 
 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1">highest built-up area 2020 (%) </tr>
 <tr class="custom-Geography2">Lagos</tr>
 <tr class="custom-Geography2">Imo</tr>
 <tr class="custom-Geography2">Anambra</tr>
 </td>
 
 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> lowest built-up area 2020 (%)  </tr>
 <tr class="custom-Geography2"> Taraba </tr>
 <tr class="custom-Geography2"> Yobe </tr>
 <tr class="custom-Geography2">Borno  </tr>
 </td>
 
 <td style={{ minWidth:0 }}>
 <tr style={{ fontSize: 11,textTransform: 'uppercase' ,fontWeight: 900,padding:3,color: '#966084' }}> greatest increase in built-up area (%) 1975-2020  </tr>
 <tr class="custom-Geography2">FCT </tr>
 <tr class="custom-Geography2">Enugu </tr>
 <tr class="custom-Geography2">Plateau </tr>
 </td>



 
 </tr>
 
 </table>
 <div  className='flex-1 text-greyDark' style={{ fontSize: 10 ,minHeight:29,fontWeight:500,marginTop: 3  }}>
 Source:Pesaresi M., Politis P. (2023):GHS-BUILT-S R2023A - GHS built-up surface grid, derived from Sentinel2 composite and Landsat, multitemporal (1975-2030)European Commission, Joint Research Centre (JRC) Available <a class="custom-link" href="http://data.europa.eu/89h/9f06f36f-4b11-47ec-abb0-4f8b7b1d72ea, doi:10.2905/9F06F36F-4B11-47EC-ABB0-4F8B7B1D72EA" target="_blank" > here</a>
 
      
</div>
 </div>
 
 
      
 
      </div>

      


  )

})
