import React, { useState } from 'react'
import { SquareTile } from './SquareTile'

export const DoubleSquareGrid = ({
  dataset,
  SquareTileChart = () => null,
  size,
  borderColor,
  gridGap,
  labels,
  maxValue,
  isInteractive = false
}) => {
  const padding = 12
  const tileSize = size * 2
  const [tileHover, setTileHover] = useState(false)

  return (
    <div
      style={{
        marginTop: 10,
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, ${tileSize}px)`,
        rowGap: gridGap,
        columnGap: gridGap,
        padding: padding,
        transform: 'translateY(-10%)'
      }}
    >
      {dataset.map((datum, index) => {
        return (
          <div className="flex flex-col gap-y-2 items-center" key={index}>
            <div className="flex">
              <div
                style={{ display: 'flex', flexDirection: 'row', position: 'relative' }}
                onMouseEnter={() => {
                  setTileHover(index)
                }}
                onMouseLeave={() => {
                  setTileHover(null)
                }}
              >
                <SquareTile
                  isAlignedRight={true}
                  SquareTileChart={SquareTileChart}
                  dataset={dataset}
                  datum={datum}
                  size={size}
                  borderColor={borderColor}
                  fill="#DAA367"
                  label={labels[0]}
                  maxValue={maxValue}
                />
                <SquareTile
                  SquareTileChart={SquareTileChart}
                  dataset={dataset}
                  datum={datum}
                  size={size}
                  borderColor={borderColor}
                  fill="#F5C48E"
                  label={labels[1]}
                  maxValue={maxValue}
                />
                {
                  isInteractive && tileHover === index && (
                    <div style={{ position: 'absolute', right: size + 10, top: -20, border: '1px solid #EEA55F', background: 'white', width: 250, height: 'auto', zIndex: 10 }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ paddingTop: 5, paddingLeft: 10, paddingRight: 10 }}>
                          <div style={{ fontSize: 15, fontWeight: 1000 }}>
                            {datum[0].productCategory}
                          </div>
                          <div style={{ fontSize: 15, fontWeight: 800, }}>
                            Import
                          </div>
                          {datum.map((entry, index) => (
                            <div>
                              {index === 0 || index === 6 && <div style={{ fontSize: 15, fontWeight: 800, }}>
                                {index === 0 ? 'Import' : 'Export'}
                              </div>}
                              <div kye={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div className="flex gap-x-1">
                                  <div className="label text-sm">{entry.partner}</div>
                                </div>
                                <div className="percentage text-sm font-semibold">{entry.pct}%</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
            <span className="text-sm">
              {datum[0] ? datum[0].productCategory : datum.productCategory}
            </span>
          </div>
        )
      })}
    </div>
  )
}
