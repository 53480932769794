import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMst } from '../../state';

export const IfNigeriaWas100PeopleVizFilter = observer(() => {
    const {
        data: { ifNigeriaWas100Categories },
        filter: { selected100PeopleCategory, setSelected100PeopleCategory },
        ui: { isMobile },
    } = useMst();

    const handleKeyDown = (event, category) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            setSelected100PeopleCategory(category);
        }
    };

    return (
        <div>
            <div  
                className="grid grid-cols-1 gap-x-5"
                style={!isMobile ? { maxWidth: '50vw' } : { maxWidth: '100%' }}
            >
                {ifNigeriaWas100Categories.map((category) => {
                    const isSelected = selected100PeopleCategory === category;
                    return (
                        <div 
                            key={category}
                            className={`flex items-center cursor-pointer activity-item ${isSelected ? 'selected font-bold' : ''}`}
                            onClick={() => setSelected100PeopleCategory(category)}
                            onKeyDown={(event) => handleKeyDown(event, category)}
                            tabIndex={0}  // Makes the item focusable
                            style={{ color: isSelected ? '#AF9A5B' : '#000000', backgroundColor: isSelected ? '#e9e1cf' : 'transparent', padding: '5px', marginTop: -6 }}
                            aria-label={`Select ${category}`}  // Provides a descriptive label for screen readers
                            role="button"  // Indicates the element behaves like a button
                        >
                            <div
                                className={`bg-black self-start mt-2 ${isSelected ? '' : 'w-0'}`}
                                style={{ height: 1 }}
                            />
                            <div
                                className="max-w-42 overflow-hidden whitespace-nowrap"
                                style={{ textOverflow: 'ellipsis', fontSize: 20 }}
                            >
                                {category}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
});
