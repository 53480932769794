import React, { useState } from 'react'
import { downloadElementToImage } from '../utils/utils';
// import DownloadIcon from '@mui/icons-material/Download';
import { ReactComponent as DownloadIcon } from '../assets/download-icon.svg';
import { MultipleSquaresTile } from '../components/chartContainers/MultipleSquaresTile'
import { ReactComponent as MovementScale } from '../assets/movement-scale.colors.svg'
const loadingIcon = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

export const MovementLegend = () => {
  const [isDownloading, setIsDownloading] = useState(false);

  const mockDatum = [
    {
      stateCurrent: 'Federal Capital Territory',
      stateCode: 'FC',
      share: 41.2,
    },
    {
      stateCurrent: 'Federal Capital Territory',
      stateCode: 'KO',
      share: 7.6,
    },
    {
      stateCurrent: 'Federal Capital Territory',
      stateCode: 'NA',
      share: 6.2,
    },
    {
      stateCurrent: 'Federal Capital Territory',
      stateCode: 'BE',
      share: 5.6,
    },
    {
      stateCurrent: 'Federal Capital Territory',
      stateCode: 'EN',
      share: 5.2,
    },
    {
      stateCurrent: 'Federal Capital Territory',
      stateCode: 'Other',
      share: 34.3,
    },
  ]

  const handleCaptureDownoadClick = () => {
    const sectionComponent = document.getElementById("subsection");
    const snapshotElem = sectionComponent.getElementsByClassName("chartcontent-component")?.[0];
    if (snapshotElem) {
      setIsDownloading(true);
      setTimeout(async () => {
        await downloadElementToImage(snapshotElem,"Movement.png");
        setIsDownloading(false);
      }, 0)
    }
  }
  return (
    <div className="ml-10 flex flex-col gap-y-2">
       <div className="font-bold">How to read it</div>
      <div style={{ width: 200 }}>
        <div className="text-sm text-greyDark">Current State</div>
        <div className="bg-gray-500 h-6 ml-6" style={{ width: 0.5 }} />
        <MultipleSquaresTile datum={mockDatum} borderColor="#309992" />
        <div className="bg-gray-500 h-6 ml-6 my-1" style={{ width: 0.5 }} />
        <div className="text-sm text-greyDark">Origin State</div>
      </div>

      <div className="text-sm text-greyDark">Share of people</div>
      <div className="relative" style={{ width: '100px' }}>
        <MovementScale width={100} />
        <div className="absolute top-5 text-sm font-semibold">0%</div>
        <div className="absolute top-5 text-sm font-semibold" style={{ left: 90 }}>
          100%
        </div>
      </div>
      <br/>
      <div style={{ display: 'flex',  alignItems: 'left' ,marginTop:20,marginLeft:-10}}>
      <button type="button" onClick={handleCaptureDownoadClick} style={{ color: 'rgb(9, 126, 118)' }}disabled={isDownloading} className="flex items-center">
          {isDownloading ? 'Downloading...' : <span className=" text-black"style={{  fontSize:16,fontWeight:700,letterSpacing:0.5 }} >DOWNLOAD</span>}
          {!isDownloading && (
            <DownloadIcon
              className="ml-2"
              style={{  width: '26px', height: '26px', stroke: 'rgb(9, 126, 118)', strokeWidth: '2' }} 
            />
          )}
        </button>
        {isDownloading && <img width={24} src={loadingIcon} alt='' />}
      </div>
    </div>
  )
}
