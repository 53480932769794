import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import useSize from '@react-hook/size';
import { SLICE_WIDTH_CLOSED, TRANSLATE_X_HOVER } from '../lib/constants';
import { VerticalIndexMenu } from './VerticalIndexMenu';
import { VerticalIndexMenu4 } from './VerticalIndexMenu4';
import { SubsectionOpen } from './SubsectionOpen';
import { SubsectionClosed } from './SubsectionClosed';
import { useNavigate } from 'react-router-dom';
import { Footer } from './Footer';
import Header from './Header';

export const SectionDesktop = observer(({ section, activeSubsection = 0 }) => {
  const { subsections } = section;

  const defaultOpenSubsection = subsections[activeSubsection];

  const subsectionsContainerRef = useRef(null);
  const [subsectionsContainerWidth, subsectionsContainerHeight] = useSize(subsectionsContainerRef);
  const [openSubsectionId, setOpenSubsectionId] = useState(defaultOpenSubsection.key);
  const [hoveredSubsectionId, setHoveredSubsectionId] = useState(null);

  const sliceWidthTotal = subsectionsContainerWidth - (subsections.length - 1) * SLICE_WIDTH_CLOSED;
  const navigate = useNavigate();

  return (
    <>
      <div className="w-full h-screen flex flex-col ">
        <Header />
        <div className="flex flex-1 w-full">
          <VerticalIndexMenu activeSection={section} />
          <div className="flex-grow overflow-auto" ref={subsectionsContainerRef}>
            <div className="relative h-full">
              {subsections.map((subsection, i) => {
                const isLast = i === subsections.length - 1;
                const isActive = openSubsectionId === subsection.key;
                const isSlided = subsections.findIndex((d) => d.key === openSubsectionId) > i;
                const width = sliceWidthTotal;
                const left = i * SLICE_WIDTH_CLOSED;
                const translateX = computeTranslateX({
                  isActive,
                  isLast,
                  isSlided,
                  subsection,
                  hoveredSectionId: hoveredSubsectionId,
                  sliceWidthTotal,
                });
                const zIndex = subsections.length - i;

                return (
                  <section
                    key={i}
                    className={`border-l border-r border-black border-t-white border-b-white bg-white absolute top-0 h-full transition-all px-0 py-0
                      ${isActive ? 'overscroll-y-auto border-l-0' : ''}`}
                    style={{
                      width,
                      left,
                      zIndex,
                      transform: `translateX(${translateX}px)`,
                    }}
                  >
                    <div className="w-full h-full flex">
                      {isActive ? (
                        <SubsectionOpen section={section} subsection={subsection} />
                      ) : (
                        <SubsectionClosed
                          section={section}
                          subsection={subsection}
                          setOpenSubsectionId={setOpenSubsectionId}
                          setHoveredSubsectionId={setHoveredSubsectionId}
                        />
                      )}
                    </div>
                  </section>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full">
        <Footer />
      </div>
    </>
  );
});

function computeTranslateX({
  isActive,
  isLast,
  isSlided,
  subsection,
  hoveredSectionId,
  sliceWidthTotal,
}) {
  const isHovered = hoveredSectionId !== null && hoveredSectionId === subsection.key;

  if (!isSlided && !isActive && !isLast && isHovered) {
    return TRANSLATE_X_HOVER;
  }
  if (isSlided) {
    const leftTranslate = -sliceWidthTotal + SLICE_WIDTH_CLOSED;
    if (isHovered) {
      return leftTranslate + TRANSLATE_X_HOVER;
    } else {
      return leftTranslate;
    }
  }
  return 0;
}
