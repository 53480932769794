import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'
import { ChartContent } from './ChartContent'
import { Map } from '../chartContainers/Map'
import { SemicircleChart } from '../smallCharts/SemicircleChart'

export const ReligiousCentersPerCapitaViz = observer(() => {
  const {
    data: { religiousCentersPerCapita },
  } = useMst()

  return (
    <div style={{ height: '90vh',paddingTop: 20 }}>
    <ChartContent
      isMapChart={true}
      chart={
        <Map
          dataset={religiousCentersPerCapita}
          isInteractive={false}
          borderColor="transparent"
          fillColor="#F5F4F4"
          semicircleLabels={['mosques', 'churches']}
          MapTileChart={SemicircleChart}
        />
      }
    />
    </div>
  )
})
