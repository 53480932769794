import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

import { ChartContent } from './ChartContent'
import { Map } from '../chartContainers/Map'
import { PopulationChart } from '../smallCharts/PopulationChart'

export const PopulationIncreaseViz = observer(() => {
  const {
    data: { populationIncrease, populationLatestYear, populationOldestYear },
  } = useMst()

  return (
    <div style={{  paddingTop: 20 }}>
    <ChartContent
      isMapChart={true}
      chart={
        <Map
          dataset={populationIncrease}
          latestYear={populationLatestYear}
          oldestYear={populationOldestYear}
          isInteractive={false}
          borderColor="#BEAB70"
          MapTileChart={PopulationChart}
          chartFill="#B7AA7F"
        />
      }
    />
   </div>
  )
})
