import { maxBy } from 'lodash-es'

export const COLUMNS_COUNTER = 8
export const INTERNAL_PADDING = 5
export const MAP_TILE_SIZE_MOBILE = 65
export const MAP_TILE_SIZE_DESKTOP = 75
export const MAP_LARGE_TILE_SIZE_DESKTOP = 155

export const STATES_CONFIG = [
  { id: 'NGA', r: 1, c: 1, largeTile: true },
  { id: 'SO', r: 1, c: 3 },
  { id: 'ZA', r: 1, c: 4 },
  { id: 'KT', r: 1, c: 5 },
  { id: 'JI', r: 1, c: 6 },
  { id: 'YO', r: 1, c: 7 },
  { id: 'BO', r: 1, c: 8 },
  { id: 'KE', r: 2, c: 3 },
  { id: 'KD', r: 2, c: 4 },
  { id: 'KN', r: 2, c: 5 },
  { id: 'BA', r: 2, c: 6 },
  { id: 'GO', r: 2, c: 7 },
  { id: 'AD', r: 2, c: 8 },
  { id: 'OY', r: 3, c: 1 },
  { id: 'EK', r: 3, c: 2 },
  { id: 'NI', r: 3, c: 3 },
  { id: 'FC', r: 3, c: 4 },
  { id: 'NA', r: 3, c: 5 },
  { id: 'PL', r: 3, c: 6 },
  { id: 'OG', r: 4, c: 1 },
  { id: 'TA', r: 3, c: 7 },
  { id: 'OS', r: 4, c: 2 },
  { id: 'KW', r: 4, c: 3 },
  { id: 'KO', r: 4, c: 4 },
  { id: 'BE', r: 4, c: 5 },
  { id: 'LA', r: 5, c: 1 },
  { id: 'ON', r: 5, c: 2 },
  { id: 'AN', r: 5, c: 3 },
  { id: 'EN', r: 5, c: 4 },
  { id: 'EB', r: 5, c: 5 },
  { id: 'CR', r: 5, c: 6 },
  { id: 'ED', r: 6, c: 2 },
  { id: 'IM', r: 6, c: 3 },
  { id: 'AB', r: 6, c: 4 },
  { id: 'AK', r: 6, c: 5 },
  { id: 'DE', r: 7, c: 2 },
  { id: 'BY', r: 7, c: 3 },
  { id: 'RI', r: 7, c: 4 },
  
]

export const columnsCounter = maxBy(STATES_CONFIG, 'c').c
export const rowsCounter = maxBy(STATES_CONFIG, 'r').r
