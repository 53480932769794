import React from 'react'

export const MapTooltip2 = ({ x, y, size, datum, colorScale, area, leftSidefTheMapWidth, bottomSidefTheMapWidth }) => {
  const MAP_CONTAINER_WIDTH = 640
  const MAP_CONTAINER_HEIGHT = 600

  const TOOLTIP_WIDTH = 256
  const TOOLTIP_HEIGHT = 240
  const tooltipRightSideXPos = leftSidefTheMapWidth + x + size + TOOLTIP_WIDTH
  const tooltipBottomSideXPos = bottomSidefTheMapWidth + y + size + TOOLTIP_HEIGHT

  return (
    <div
      className="absolute  z-100 flex flex-col gap-y-2 bg-white p-5 min-w-60"
      // style={
      //   tooltipRightSideXPos < leftSidefTheMapWidth + MAP_CONTAINER_WIDTH
      //     ? { left: x + size, top: y }
      //     : { left: x - TOOLTIP_WIDTH, top: y }
      // }
      style={{
        left: tooltipRightSideXPos < leftSidefTheMapWidth + MAP_CONTAINER_WIDTH ? x + size : x - TOOLTIP_WIDTH,
        top: tooltipBottomSideXPos < bottomSidefTheMapWidth + MAP_CONTAINER_HEIGHT ? y : y - TOOLTIP_HEIGHT
      }}
    >
      <h4 className="text-sm font-semibold uppercase">{datum.stateName}</h4>
      <h4 className="text-sm font-semibold uppercase">{datum.year}</h4>
      {area && (
        <span className="text-sm text-gray-500">
          Area: {parseInt(datum.stateArea.replace(/,/g, ''))} Km<sup>2</sup>
        </span>
      )}
      <div className="legend flex flex-col gap-y-1">
        {colorScale.domain().reverse().map((label) => (
          <div className="legend-element flex justify-between" key={label}>
            <div className="flex gap-x-1">
              <div className="square w-4 h-4" style={{ background: colorScale(label) }} />
              <div className="label text-sm">{label[0].toUpperCase() + label.substring(1)}</div>
            </div>
            <div className="percentage text-sm font-semibold">{datum[label].toFixed(1)}%</div>
          </div>
        ))}
      </div>
    </div>
  )
}
