import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

const FILTER_DATA = {
    overallSatisfaction: '',
    financialWellbeing: 'Compared to when you were 16 years old, how would you say your family income has changed?',
}

export const TreeChartFilter = observer(({ chartType = 'overallSatisfaction' }) => {
    const {
        filter: { treeChartSelected, setTreeChartSelected },
    } = useMst()

    const [filterData, setFilterData] = useState(FILTER_DATA.concern);
    const isSelected = 1;

    useEffect(() => {
        switch (chartType) {
            case 'overallSatisfaction':
                setFilterData(FILTER_DATA.overallSatisfaction)
                break;
            case 'financialWellbeing':
                setFilterData(FILTER_DATA.financialWellbeing)
                break;
            default:
                break;
        }
        setTreeChartSelected(chartType)
    }, [chartType])

    return chartType !== 'overallSatisfaction' ?
        (
            <div className="flex flex-col" >
                <div
                    className={`flex flex-col cursor-pointer ${isSelected ? 'font-bold' : ''}`}
                    style={{ width: 300, color: isSelected ? '#8D796E' : '#000000' }}
                >

{/* <div className="capitalize" style={{ textOverflow: 'ellipsis',fontSize: 16 }}>{filterData}</div>
                </div> */}

                    <div className="" style={{ textOverflow: 'ellipsis',fontSize: 16 }}>{filterData}</div>
                </div>
            </div>
        ) : null
})
