import React from 'react'
import { MultipleBarsBarchart } from '../smallCharts/MultipleBarsBarchart'

export const BarchartTile = ({
  datum,
  xLabel,
  barLabels,
  groups,
  size,
  barLengthScale,
  colorScale,
}) => {
  const height = size * (2 / 3)
  return (
    <div className="tile-container flex flex-col">
      {/* Chart */}
      <svg width={size} height={height}>
        <rect x={0} y={0} width={1} height={height} fill="#EAEAEA" />
        <rect x={size - 1} y={0} width={1} height={height} fill="#EAEAEA" />
        <rect x={0} y={0} width={size} height={1} fill="#EAEAEA" />

        <MultipleBarsBarchart
          groups={groups}
          barLabels={barLabels}
          datum={datum}
          width={size}
          height={height}
          barLengthScale={barLengthScale}
          colorScale={colorScale}
        />

        {/* xAxis */}
        <rect x={0} y={height - 1} width={size} height={1} fill="black" />
      </svg>

      {/* xLabel */}
      <div className="pt-3 text-sm">{datum[xLabel]}</div>
    </div>
  )
}
