import { scaleOrdinal } from 'd3-scale'
import { ReactComponent as Info } from '../assets/info.svg'
// color class names
export const PLUM = 'plum'
export const PUMPKIN = 'pumpkin'
export const PEA = 'pea'
export const PETROLEUM = 'petroleum'
export const BROWN = 'brown'

// desktop layout dimensions
export const MENU_WIDTH = 66
export const MENU_WIDTH2 = 55
export const SLICE_WIDTH_CLOSED = 35
export const TRANSLATE_X_HOVER = 3

// section id
export const GEOGRAPHY_AND_CLIMATE = 'Geography and Climate'
// subsections id
export const ELEVATION = 'Geographical tapestry'
export const LAND_USE = 'Land use'
export const LAND_USE_SPATIAL = 'Raster of land use'
export const URBANISATION = 'Urbanisation trend'
export const URBAN_GROWTH_SPATIAL = 'Metropolis chronicles: 7 selected cities'


export const ROAD_DENSITY_PER_STATE = 'Road density per state'
export const CLIMATE_TRENDS_PRECIPITATIONS = 'Climate trends - precipitations'
export const CLIMATE_TRENDS_TEMPERATURE = 'Climate trends - temperature'
export const SURFACE_TEMPERATURE_SPATIAL = 'Surface temperature'

// section id
export const ECONOMY = 'Economy'
// subsections id
export const GDP = 'Nigeria compared'
export const ECONOMIC_CONTRIBUTION = 'Economic structure'
export const FROM_NIGERIA_TO_NIGERIA = 'From Nigeria: to Nigeria'

// section id
export const DEMOGRAPHICS = 'Demographics'
// subsections id
export const IF_NIGERIA_WAS_100_PEOPLE = 'If Nigeria was 100 people'
export const POPULATION_INCREASE = 'Where do Nigerians live'
export const POPULATION_GROWTH_SPATIAL = 'Population growth'
export const HUMAN_DEVELOPMENT_INDEX = 'Human Development Index: Subnational Indices'
export const NIGERIA_AT_NIGHT_SPATIAL = 'Nigeria at night'
export const SCHOOLS_HEALTH_CENTERS = 'Schools and health centres per capita'
export const RELIGIOUS_CENTERS = 'Religious centres per capita'

// section id
export const PEOPLE = 'Life and Work'
// subsections id
export const A_DAY_IN_THE_LIFE = 'A day in the life of'
export const WHAT_NIGERIANS_DO = 'What Nigerians do'
export const HOW_THEY_GET_AROUND = 'Getting around'
export const POWER_CUTS = 'Lights out'
export const NIGERIA_RELIGION = 'na God o'
export const MOVEMENT = 'On the move'
export const STRENGTH_FROM_ABROAD = 'Strength from abroad'
export const ONLINE = 'Online'
export const WHAT_NIGERIANS_ASK = 'Hot topics'

// section id
export const PERCEPTIONS_AND_ATTITUDES = 'Perceptions and Attitudes'
// subsections id
export const ISSUES_TO_PRIORITISE = 'Most important issues facing Nigeria'
export const TRUST = 'Agree or disagree: trust'
export const CONCERN = 'How concerned are you about...'
export const LGBT = 'Agree or disagree: LGBT acceptance'
export const CLIMATE_CHANGE = 'climate change'
export const FINANCIAL_WELLBEING = 'Change in financial wellbeing'
export const PARTICIPATION_IN_POLITICS = 'Are you interested in politics?'
export const INTENTION_TO_VOTE = 'Towards 2023: intention to vote'
export const REGISTRATION_TO_VOTE = 'Towards 2023: registration to vote'
export const SATISFACTION_WITH_LIFE = 'Wetin dey happen?'

export const PLACEHOLDER_STATE_CODE = 'LA'
export const PLACEHOLDER_LAND_USE_VALUES = {
  Test2: 10,
  Test: 20,
  Vegetation: 50,
  'Built Up': 20,
  'Open Land': 10,
}

export const LAND_USE_COLOR_SCALE = scaleOrdinal()
  //.domain(['Built Up', 'Cropland/vegetation', 'Forest', 'Open Land/Shrubs', 'Water'])
 //.range(['#754e69', '#AB809E', '#C9ADC1', '#E1D1DC', '#F1EBEF'])

  .domain(['Built Up', 'Cropland/vegetation',  'Forest','Open Land/Shrubs','Water/wetland' ])
  .range(['#7a6f82', '#ab809e', '#c2a6ba', '#dbccd6', '#f7f5f7'])

export const NIGERIA_MAIN_TYPE_OF_WORK_COLOR_SCALE = scaleOrdinal()
  .domain(['Self employed (farm)', 'Self employed (non-farm)', 'Employee', 'Not engaged in economic activity'])
  .range(['#2D9992', '#83C2BE', '#b3d7d3', '#ffffff'])

export const MODES_OF_TRANSPORTATION_COLOR_SCALE = scaleOrdinal()
  .domain(['Public transport', 'Drive', 'Walk'])
  .range(['#2D9992', '#82c1be', '#d8eae9'])

export const DAILY_POWER_COLOR_SCALE = scaleOrdinal()
  .domain(['Less than 3 hours', 'More than 7 hours', '3-7 hours' ])
  .range(["#edf6f5", "#1f635e", "#55aaa3" ])

export const RELIGIOUS_SERVICES_COLOR_SCALE = scaleOrdinal()
  .domain(['Frequently', 'Occasionally', 'Never'])
  .range(['#299992', '#82c1be', '#d8eae9'])

export const INTEREST_IN_POLITICS_COLOR_SCALE = scaleOrdinal()
  .domain(['Very interested', 'Quite interested', 'Hardly interested', 'Not at all interested'])
  .range(['#8E796F', '#B2978B', '#D1C1B9', '#E0D5D1'])

export const LIVING_STANDARDS_COLOR_SCALE = scaleOrdinal()
  .domain(['improved', 'same', 'worsened'])
  .range(['#8E796F', '#B2978B', '#DACDC7'])

export const HDI_COLOR_SCALE = scaleOrdinal()
  .domain(['income Index', 'education Index','health Index' ])
  .range(['#B7AA7F', '#CBBC8D', '#E0D7BB'])

  export const HDI_COLOR_SCALE2 = scaleOrdinal()
  .domain(['income Index', 'education Index','health Index' ])
  .range(['#E0D7BB', '#CBBC8D','#B7AA7F' ])

export const MONTHS = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
]



export const PRECIPITATIONS_BARS = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec"
]

export const TEMPERATURES_BARS = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec"
]
export const TEMPERATURES_BAR_COLOR_SCALE = scaleOrdinal()
  .domain([
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec"]
    .reverse())
  .range(['#A880A1'])
  export const TEMPERATURE_COLOR_SCALE = scaleOrdinal().domain(MONTHS).range(['#C9ADC1'])
  
export const PRECIPITATIONS_BAR_COLOR_SCALE = scaleOrdinal()
  .domain([
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec"]
    .reverse())
  .range(['#ab809e'])


  export const PRECIPITATIONS_BAR_COLOR_SCALE2 = scaleOrdinal()
  .domain([
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec"]
    .reverse())
  .range(['#c2a6ba	'])

export const PRECIPITATIONS_COLOR_SCALE = scaleOrdinal().domain(MONTHS).range(['#E1D1DC'])

export const AGE_CLUSTERS = [
  '18-24 years',
  '25-34 years',
  '35-44 years',
  '45-60 years',
  '61 years +',
]
export const WORK_TYPE_COLOR_SCALE = scaleOrdinal()
  .domain(AGE_CLUSTERS)
  .range(['#1E625E', '#267A75', '#309992', '#83C2BE', '#ACD6D3'])

export const TRANSPORT_CLUSTERS = ['rural', 'urban']
export const TRANSPORT_COLOR_SCALE = scaleOrdinal()
  .domain(TRANSPORT_CLUSTERS)
  .range(['#1E625E', '#309992'])

export const TRANSPORT_COLOR_SCALE_BROWN = scaleOrdinal()
  .domain(TRANSPORT_CLUSTERS)
  .range(['#726159', '#B2978B'])

export const INTERNET_CLUSTERS = ['athome', 'atwork']
export const INTERNET_COLOR_SCALE = scaleOrdinal()
  .domain(INTERNET_CLUSTERS)
  .range(['#2D9992', '#82c1be'])

  

export const ISSUES_CLUSTERS = ['Most important', 'Second most important']
export const BROWN_COLOR_SCALE = scaleOrdinal()
  .domain(ISSUES_CLUSTERS)
  .range(['#726159', '#B2978B'])

export const HDI_INDEXES = ['health Index', 'education Index', 'income Index']

export const BARCHARTS_GRID_TILE_SIZE = 197
export const SQUARE_TILE_SIZE = 105
export const MULTIPLE_SQUARES_SIZE = 60

export const LAND_USE_SPATIAL_COLOR_SCALE = scaleOrdinal()
  .domain(['Built up', 'Test', 'Forest / Vegetation', 'Open Land'])
  .range(['#644F5D', '#AB809E', '#C9ADC1', '#F1EBEF'])

export const GROWTH_SPATIAL_COLOR_SCALE = scaleOrdinal()
  .domain(['1975', '2000', '2020'])
  .range(['#D09EBD', '#A46A9D', '#62486D'])

export const TEMPERATURE_SPATIAL_COLOR_SCALE = scaleOrdinal()
  .domain(['> 35°C', '35-32°C', '32-29°C', '29-26°C', '26-23°C', '23-20°C'])
  .range(['#89667E', '#9A738F', '#AB809E', '#C9ADC1', '#E1D1DC', '#F1EBEF'])
