import React, { useState } from 'react'
import { downloadElementToImage } from '../utils/utils';
import { observer } from 'mobx-react-lite'
import { useMst } from '../state'
// import DownloadIcon from '@mui/icons-material/Download';
import { ReactComponent as DownloadIcon } from '../assets/download-icon.svg';

import { ReactComponent as PurplePill } from '../assets/purple-pill.colors.svg'
import { ReactComponent as YellowPill } from '../assets/yellow-pill.colors.svg'
import { ReactComponent as GreenPill } from '../assets/green-pill.colors.svg'
import { ReactComponent as GreyPill } from '../assets/grey-pill.colors.svg'
const loadingIcon = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

export const VersoLegend = observer(() => {
  const [isDownloading, setIsDownloading] = useState(false);

  const {
    data: { ifNigeriaWas100ByCategory },
    filter: { selected100PeopleCategory },
  } = useMst()

  const dataset = ifNigeriaWas100ByCategory(selected100PeopleCategory)
  const pills = [
    <PurplePill key="purple" width={28} />,
    <YellowPill key="yellow" width={28} />,
    <GreenPill key="green" width={28} />,
    <GreyPill key="grey" width={28} />,
  ]


  const handleCaptureDownoadClick = () => {
    const sectionComponent = document.getElementById("subsection");
    const snapshotElem = sectionComponent.getElementsByClassName("verso_component")?.[0];
    if (snapshotElem) {
      setIsDownloading(true);
      setTimeout(async () => {
        await downloadElementToImage(snapshotElem, "if_nigeria_100_people.png");
        setIsDownloading(false);
      }, 0)
    }
  }
  return (
    <div className="flex flex-col gap-y-0" style={{ marginTop: -10 }}>
      <div className="font-bold">How to read it</div>
      <div className="flex flex-col gap-y-1" style={{ width: 220 }}>
        <div className="flex justify-between">
          <span className="text-sm text-black font-semibold">{selected100PeopleCategory}</span>
          <span className="text-sm text-black font-semibold">People</span>
        </div>

        {dataset.map((datum, index) => (
          <div key={datum.label} className="flex justify-between">
            <span className="text-sm" style={{ width: 200 }}>{datum.label}</span>
            <div className="flex gap-x-4 text-sm items-center">
              <span className="font-semibold flex flex-row ">{datum.value}{pills[index]}</span>
            </div>
          </div>
        ))}
        <div style={{ display: 'flex',  alignItems: 'left' ,marginLeft:-10}}>
        <button type="button" onClick={handleCaptureDownoadClick} style={{ color: 'rgb(171, 148, 78)' }}disabled={isDownloading} className="flex items-center">
{isDownloading ? 'Downloading...' : <span className=" text-black"style={{  fontSize:16,fontWeight:700,letterSpacing:0.5 }} >DOWNLOAD</span>}
{!isDownloading && (
  <DownloadIcon
    className="ml-2"
    style={{  width: '26px', height: '26px', stroke: 'rgb(171, 148, 78)', strokeWidth: '2' }} 
  />
)}
</button>
          {isDownloading && <img width={24} src={loadingIcon} alt='' />}
        </div>
      </div>
    </div>
  )
})
