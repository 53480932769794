import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMst } from '../../state';

export const GrowthSpacialVizFilter = observer(() => {
    const states = [
        'Uyo, Akwa Ibom',
        'Onitsha, Anambra',
        'Abuja, FCT',
        'Kano, Kano',
        'Lagos metropolitan',
        'Maiduguri, Borno',
        'Ibadan, Oyo',
    ];

    const {
        filter: { selectedState, setSelectedState },
    } = useMst();

    const handleKeyDown = (event, state) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            setSelectedState(state);
        }
    };

    return (
        <div style={{ paddingTop: 20 }}>
            <div>
                {states.map((state) => {
                    const isSelected = selectedState === state;
                    return (
                        <div
                            key={state}
                            className={`flex items-center cursor-pointer activity-item ${isSelected ? 'selected font-bold' : ''}`}
                            onClick={() => setSelectedState(state)}
                            onKeyDown={(event) => handleKeyDown(event, state)}
                            tabIndex={0}  // Makes the item focusable
                            role="button"  // Indicates that the element behaves like a button
                            aria-pressed={isSelected}  // Indicates whether the item is selected
                            aria-label={`Select ${state}`}  // Provides a descriptive label for screen readers
                            style={{ color: isSelected ? '#966084' : '#000000', backgroundColor: isSelected ? '#ebe1e5' : 'transparent', padding: '5px' }}
                        >
                            <div
                                className={`${isSelected ? '' : 'w-0'}`}
                                style={{ height: 1, color: '#966084' }}
                            />
                            <div style={{ fontSize: 20 }}>{state}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
});
