import { observer } from 'mobx-react-lite'
import React from 'react'
export const HDISummerystatus = observer(() => {

  
  return (
    <div className=" ">
      <div style={{ borderTop: '1px solid lightgray',marginLeft:-10 ,marginBottom:0}}>   </div> 
      
        <div className=' xl:col-span-2 flex flex-col  top-0 'style={{ marginRight: 0,marginTop: 0 }}>

        <table className=' border-b    flex-1 ' style={{  marginRight: 0,maxWidth:3000  }}>
 <tr >
 <th ></th>
 <th></th>
 <th></th>
 <th></th>
 </tr>

 
 <tr>
  



 
 
 <td style={{ minWidth:0 }}>
 <tr class="custom-Demographics1"> Highest HDI in Health </tr>
 <tr class="custom-Demographics2"> Ogun (0.73)</tr>
 <tr class="custom-Demographics2"> Bayelsa (0.73)</tr>
 </td>
 
 <td style={{ minWidth:0 }}>
 <tr class="custom-Demographics1"> Highest HDI in Income </tr>
 <tr class="custom-Demographics2"> Lagos (0.68)   </tr>
 <tr class="custom-Demographics2"> FCT (0.67)</tr>
 </td>

 <td style={{ minWidth:0 }}>
 <tr class="custom-Demographics1"> Highest HDI in Education  </tr>
 <tr class="custom-Demographics2"> Lagos (0.72)  </tr>
 <tr class="custom-Demographics2"> Imo (0.70) </tr>
 </td>

 <td style={{ minWidth:0 }}>
 <tr class="custom-Demographics1"> Lowest HDI in Health  </tr>
 <tr class="custom-Demographics2"> Kebbi (0.43)   </tr>
 <tr class="custom-Demographics2"> Jigawa (0.44)</tr>
 </td>

 <td style={{ minWidth:0 }}>
 <tr class="custom-Demographics1"> Lowest HDI in Income  </tr>
 <tr class="custom-Demographics2"> Yobe (0.48)  </tr>
 <tr class="custom-Demographics2"> Jigawa (0.50) </tr>
 </td>

 <td style={{ minWidth:0 }}>
 <tr class="custom-Demographics1"> Lowest HDI in Education </tr>
 <tr class="custom-Demographics2"> Sokoto (0.17) </tr>
 <tr class="custom-Demographics2"> Kebbi (0.18) </tr>
 </td>


 
 </tr>
 
 </table>
 <div  className='flex-1 text-greyDark' style={{ fontSize: 10 ,minHeight:41,fontWeight:500,marginTop: 5  }}>
Source: Global Data Lab. Subnational Human Development Index (2020). Nijmegen School of Management of Radboud University. Available <a class="custom-link" href=" https://globaldatalab.org/areadata/table/shdi/NGA/" target="_blank" >  here</a>  

 
      
</div>
 </div>
 
 
      
 
      </div>

      


  )

})
