import React, { useEffect, useState } from 'react'
import { downloadElementToImage } from '../utils/utils';

import { MapTile } from '../components/chartContainers/MapTile'
// import DownloadIcon from '@mui/icons-material/Download';
import { ReactComponent as DownloadIcon } from '../assets/download-icon.svg';
import { MAP_TILE_SIZE_DESKTOP } from '../lib/mapConstants'
import { TreeChart } from '../components/smallCharts/TreeChart'
import { useMst } from '../state'
import { SQUARE_TILE_SIZE } from '../lib/constants'
const loadingIcon = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

const CHART_COMMENTS = {
    overallSatisfaction: 'Rating of satisfaction with life',
    financialWellbeing: 'Change in financial well-being'
}

const CHART_INDEX = {
    overallSatisfaction: [
        {
            color: '#4a3527',
            value: 'Satisfied'
        },
        {
            color: '#8b6248',
            value: 'Neither'
        },
        {
            color: '#b78f76',
            value: 'Dissatisfied'
        }
    ],
    financialWellbeing: [
        {
            color: '#4a3527',
            value: 'Improved'
        },
        {
            color: '#8b6248',
            value: 'Remained the same'
        },
        {
            color: '#b78f76',
            value: 'Getting worse'
        }
    ]
}

export const TreeChartLegend = ({ chartType = 'overallSatisfaction' }) => {
    const [isDownloading, setIsDownloading] = useState(false);

    const {
        data: { findConcernData },
        filter: { concernSelected }
    } = useMst()

    const [chartComments, setChartComments] = useState(CHART_COMMENTS.overallSatisfaction)
    const [chartIndex, setChartIndex] = useState(CHART_INDEX.overallSatisfaction)

    useEffect(() => {
        switch (chartType) {
            case 'overallSatisfaction':
                setChartComments(CHART_COMMENTS.overallSatisfaction)
                setChartIndex(CHART_INDEX.overallSatisfaction)
                break;
            case 'financialWellbeing':
                setChartComments(CHART_COMMENTS.financialWellbeing)
                setChartIndex(CHART_INDEX.financialWellbeing)
                break;
            default:
                break;
        }
    }, [chartType])
    const handleCaptureDownoadClick = () => {
        const sectionComponent = document.getElementById("subsection");
        const snapshotElem = sectionComponent.getElementsByClassName("tree-component")?.[0];
        if (snapshotElem) {
          setIsDownloading(true);
          setTimeout(async () => {
            await downloadElementToImage(snapshotElem,"image.png");
           
            setIsDownloading(false);
          }, 0)
        }
      }
    return (
        <div className="flex flex-col gap-y-2">

<div className="flex flex-col gap-y-1 pt-8">
                {chartIndex
                    .map((label) => (
                        <div className="legend-element flex justify-between" key={label.color}>
                            <div className="flex gap-x-1">
                                <div
                                    className="square w-4 h-4"
                                    style={{ background: label.color }}
                                />
                                <div className="label text-sm ">{label.value}</div>
                            </div>
                        </div>
                    ))}
          </div>


          <br/>


            <div className="font-bold">How to read it</div>
            <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP }}>
                Male
                <div
                    className="absolute"
                    style={{ minHeight: MAP_TILE_SIZE_DESKTOP, minWidth: MAP_TILE_SIZE_DESKTOP }}
                >
                    <TreeChart size={80} datum={findConcernData(chartType, concernSelected, "total", "").data} heading="&nbsp;" />
                </div>
                <div
                    className="absolute text-sm text-greyDark font-semibold"
                    style={{ left: MAP_TILE_SIZE_DESKTOP + 15, width: 125 }}
                >Subpopulation
                </div>
            </div>
            <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP ,top: 10}}>
                <div
                    className="absolute"
                    style={{ minHeight: MAP_TILE_SIZE_DESKTOP, minWidth: MAP_TILE_SIZE_DESKTOP }}
                >
                    <TreeChart size={80} datum={findConcernData(chartType, chartType, "total", "total")} heading="&nbsp;" />
                </div>

                <div
                    className="absolute text-sm text-greyDark font-semibold"
                    style={{ left: MAP_TILE_SIZE_DESKTOP + 15, top: 28, width: 125 }}
                >
                    {chartComments}
                </div>
            </div>
  
            
            <div  style={{ display: 'flex',  alignItems: 'left' , marginTop:45,marginLeft:-10}}>
       
               
            <button type="button" onClick={handleCaptureDownoadClick} style={{ color: 'rgb(157, 124, 109)' }}disabled={isDownloading} className="flex items-center">
{isDownloading ? 'Downloading...' : <span className=" text-black"style={{  fontSize:16,fontWeight:700,letterSpacing:0.5 }} >DOWNLOAD</span>}
{!isDownloading && (
  <DownloadIcon
    className="ml-2"
    style={{  width: '26px', height: '26px', stroke: 'rgb(157, 124, 109)', strokeWidth: '2' }} 
  />
)}
</button>
        {isDownloading && <img width={24} src={loadingIcon} alt='' />}
      </div>
        </div>
    )
}
