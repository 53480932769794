import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

import { ChartContent } from './ChartContent'
import { Map } from '../chartContainers/Map'
import { UrbanisationChart } from '../smallCharts/UrbanisationChart'

export const UrbanisationTrendViz = observer(() => {
  const {
    data: { builtUp, urbanisationLatestYear, urbanisationOldestYear },
  } = useMst();


  return (
    <div className="" style={{ height: '90vh', paddingTop: 20 }}>
      <ChartContent
        isMapChart={true}
        chart={
          <Map
            dataset={builtUp}
            latestYear={urbanisationLatestYear}
            oldestYear={urbanisationOldestYear}
            isInteractive={false}
            borderColor="#AB809E"
            MapTileChart={UrbanisationChart}
            chartFill="#AB809E"

          />
        }
      />
    </div>
  )
})
