import React, { useState } from 'react'
import { downloadElementToImage } from '../utils/utils';
// import DownloadIcon from '@mui/icons-material/Download';
import { SquareTile } from '../components/chartContainers/SquareTile'
import { GdpChart } from '../components/smallCharts/GdpChart'
import { ReactComponent as DownloadIcon } from '../assets/download-icon.svg';

import gdpPerCapita from '../dataset/gdpPerCapita.json'
import { SQUARE_TILE_SIZE } from '../lib/constants'
import { useMst } from '../state'
import { observer } from 'mobx-react-lite'
const loadingIcon = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

export const GDPLegend = () => {
  const [isDownloading, setIsDownloading] = useState(false);

  const mockDatumCountry = {
    countryName: 'Algeria',
    continent: 'Africa',
  }

  const mockDatumGdp = {
    continent: 'Africa',
    gdpPerCapita: 195.42,
  }


  const handleCaptureDownoadClick = () => {
    const sectionComponent = document.getElementById("subsection");
    const snapshotElem = sectionComponent.getElementsByClassName("GDP-COMPONENT  " )?.[0] ;
    if (snapshotElem) {
      setIsDownloading(true);
      setTimeout(async () => {
        await downloadElementToImage(snapshotElem,"GDP.png");
        setIsDownloading(false);
      }, 0)
    }
  }
  return (
    <div className="ml-10 flex flex-col gap-y-2">
    <div className="font-bold">How to read it</div>
      <div className="flex gap-x-3">
    
        <SquareTile
          SquareTileChart={GdpChart}
          dataset={gdpPerCapita}
          datum={mockDatumCountry}
          size={SQUARE_TILE_SIZE}
          borderColor="#F2B572"
        />
        <span className="text-greyDark text-xs pt-1 ">Country name</span>
      </div>

      <div className="flex gap-x-3 mt-3">
        <SquareTile
          SquareTileChart={GdpChart}
          dataset={gdpPerCapita}
          datum={mockDatumGdp}
          size={SQUARE_TILE_SIZE}
          nigeriaWidth={2272.84}
          borderColor="#F2B572"
        />
        <span className="text-greyDark text-xs pt-1 ">Country’s GDP<br /> and GDP per capita value(2022)</span>
      </div>

      <div className="flex flex-col gap-y-3 pt-8">
        <div className="flex items-center gap-x-3 text-greyDark text-sm">
          <div className="w-4 h-4" style={{ border: 'solid 2px #C2915B' }} />
          <span
            className="whitespace-nowrap overflow-ellipsis overflow-hidden"
            style={{ maxWidth: '60%' }}
          >
            Nigeria&apos;s GDP<br />and GDP per capita
          </span>
        </div>

        <div className="flex items-center gap-x-3 text-greyDark text-sm">
          <div className="w-4 h-4" style={{ background: '#FAE1C7' }} />
          <span
            className="whitespace-nowrap overflow-ellipsis overflow-hidden"
            style={{ maxWidth: '60%' }}
          >
            Country GDP<br />and GDP per capita 
          </span>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'left',marginTop:20,marginLeft:-10 }}>
      <button type="button" onClick={handleCaptureDownoadClick} style={{ color: 'rgb( 161, 81, 0)' }}disabled={isDownloading} className="flex items-center">
{isDownloading ? 'Downloading...' : <span className=" text-black"style={{  fontSize:16,fontWeight:700,letterSpacing:0.5 }} >DOWNLOAD</span>}
{!isDownloading && (
  <DownloadIcon
    className="ml-2"
    style={{  width: '26px', height: '26px', stroke: 'rgb( 161, 81, 0)', strokeWidth: '2' }} 
  />
)}
</button>
        {isDownloading && <img width={24} src={loadingIcon} alt='' />}
      </div>
    </div>
  )
}


