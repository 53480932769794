import React from 'react';
import { observer } from 'mobx-react-lite';
import { SLICE_WIDTH_CLOSED } from '../lib/constants';
import { fromKeyToIndex } from '../lib/dataUtils';
import { padWithZero } from '../lib/formatUtils';
import { WobbleBubbleIcon } from './WobbleBubble';

export const SubsectionClosed = observer(
  ({ section, subsection, setOpenSubsectionId, setHoveredSubsectionId }) => {
    const index = fromKeyToIndex(section.subsections, subsection.key) + 1;
    const formattedIndex = padWithZero(index);

    const handleKeyDown = (event) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        setOpenSubsectionId(subsection.key);
      }
    };

    return (
      <div className="w-full h-full">
        <div
          className="h-full pt-5.5 pb-2 float-right bg-whiteSmoke hover:bg-white cursor-pointer flex flex-col justify-between content-center items-center"
          style={{ width: SLICE_WIDTH_CLOSED }}
          onClick={() => setOpenSubsectionId(subsection.key)}
          onMouseOver={() => setHoveredSubsectionId(subsection.key)}
          onMouseOut={() => setHoveredSubsectionId(null)}
          tabIndex={0}
          onKeyDown={handleKeyDown}
          aria-label={`Open ${subsection.name} subsection`}
        >
          <div className="flex justify-center" style={{ marginTop: -8 }}>
            <WobbleBubbleIcon colorClass={section.colorClass} width={16} />
          </div>
          <div className="flex-col">
            <div
              className="mb-3 text-ellipsis whitespace-nowrap"
              style={{
                writingMode: 'vertical-rl',
                transform: 'rotate(180deg)',
                maxHeight: '70vh',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {subsection.name}
            </div>
            <div className="font-bold text-center">{formattedIndex}</div>
          </div>
        </div>
      </div>
    );
  }
);
