import React from 'react'

import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

import { ChartContent } from './ChartContent'
import { DoubleSquareGrid } from '../chartContainers/DoubleSquareGrid'
import { SquareTilesGrid } from '../chartContainers/SquareTilesGrid'
import { GdpChart } from '../smallCharts/GdpChart'
import { SQUARE_TILE_SIZE } from '../../lib/constants'
import { style } from 'd3'

export const GDPViz = observer(() => {
  const {

     
    data: {gdpPerCapitaByContinentOrdered2,gdpPerCapitaByContinentOrdered },  
    filter: { GDPSelectedDataset, setGDPSelectedDataset },
  } = useMst()
  return (
    <div className="GDP-COMPONENT"style={{ marginTop:-50 }}>
    < ChartContent 
      chart={
        <SquareTilesGrid 

        SquareTileChart= { GDPSelectedDataset === 'GDP(USD billion)' ? GdpChart:GdpChart }
          
dataset= { GDPSelectedDataset === 'GDP(USD billion)'
          ? gdpPerCapitaByContinentOrdered 
          : gdpPerCapitaByContinentOrdered2
          
        }
          
          size={SQUARE_TILE_SIZE}
          gridGap={10}
          borderColor="#F2B572" 
        grouped={true}
        
        />
        
      }
    />
    <div></div>
</div>
  )
  
})
