import React, { useState } from 'react'
import { downloadElementToImage } from '../utils/utils';
import { MapTile } from '../components/chartContainers/MapTile'
import { AreaChart } from '../components/smallCharts/AreaChart'
import { TEXT_SHADOW } from '../lib/styleUtils'
// import DownloadIcon from '@mui/icons-material/Download';
import { ReactComponent as DownloadIcon } from '../assets/download-icon.svg';
import { MAP_TILE_SIZE_DESKTOP } from '../lib/mapConstants'
import populationIncrease from '../dataset/populationIncrease.json'



const loadingIcon = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

export const PopulationIncreaseLegend = () => {
  const [isDownloading, setIsDownloading] = useState(false);

  const mockDatum = {
    stateCode: 'LA',
    2000: 3.35,
    2020: 6.21,
    pctChange: 86,
  }


  const handleCaptureDownoadClick = () => {
    const sectionComponent = document.getElementById("subsection");
    const snapshotElem = sectionComponent.getElementsByClassName("chartcontent-component")?.[0];
    if (snapshotElem) {
      setIsDownloading(true);
      setTimeout(async () => {
        await downloadElementToImage(snapshotElem,"PopulationIncrease.png");
        setIsDownloading(false);
      }, 0)
    }
  }
  return (
    <div className="ml-10 flex flex-col gap-y-8">
       <div className="font-bold">How to read it</div>
      <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP,marginTop:-20 }}>
        <div className="absolute">
          <MapTile
            size={MAP_TILE_SIZE_DESKTOP}
            borderColor="#BEAB70"
            MapTileChart={() => (
              <div className="absolute text-greyDark top-1 left-1 text-xs">LA</div>
            )}
          />
          <div
            className="absolute top-0 text-sm text-greyDark whitespace-nowrap font-medium"
            style={{ left: 90 }}
          >
            State code
          </div>
        </div>
      </div>

      <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP }}>
        <div
          className="absolute"
          style={{ minHeight: MAP_TILE_SIZE_DESKTOP, minWidth: MAP_TILE_SIZE_DESKTOP }}
        >
          <MapTile
            size={MAP_TILE_SIZE_DESKTOP}
            dataset={populationIncrease}
            datum={mockDatum}
            borderColor="#BEAB70"
            MapTileChart={AreaChart}
            latestYear={2020}
            oldestYear={2000}
            chartFill="#B7AA7F"
          />
 <div className="absolute left-1 top-3 text-black text-sm font-bold">+69%</div>
          <div className="absolute top-0 text-xs text-greyDark" style={{ right: '102%' }}>
            400%
          </div>
          <div className="absolute bottom-0 text-xs text-greyDark" style={{ right: '102%' }}>
            55%
          </div>

          <div className="absolute left-0 text-xs text-greyDark" style={{ top: '105%' }}>
            2000
          </div>
          <div className="absolute text-xs text-greyDark" style={{ top: '105%', left: '80%' }}>
            2020
          </div>
        </div>

        <div
          className="absolute text-sm text-greyDark"
          style={{ left: MAP_TILE_SIZE_DESKTOP + 15 }}
        >
          Population density
        </div>
      </div>

      <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP }}>
        <div
          className="absolute"
          style={{ minHeight: MAP_TILE_SIZE_DESKTOP, minWidth: MAP_TILE_SIZE_DESKTOP }}
        >
          <MapTile
            size={MAP_TILE_SIZE_DESKTOP}
            dataset={populationIncrease}
            datum={mockDatum}
            borderColor="#BEAB70"
            MapTileChart={AreaChart}
            latestYear={2020}
            oldestYear={2000}
          />

          <div className="absolute left-9 bottom-0.5 text-black text-sm font-bold">4033</div>

          <div className="absolute left-0 text-xs text-greyDark" style={{ top: '105%' }}>
            2000
          </div>
          <div className="absolute text-xs text-greyDark" style={{ top: '105%', left: '80%' }}>
            2020
          </div>
        </div>
        <div
          className="absolute text-sm text-greyDark"
          style={{ left: MAP_TILE_SIZE_DESKTOP + 15 }}
        >
          Population increase from 2000 to 2020
        </div>
      </div>
      <div style={{ display: 'flex',  alignItems: 'left' ,marginTop:15,marginLeft:-10}}>
      <button type="button" onClick={handleCaptureDownoadClick} style={{ color: 'rgb(171, 148, 78)' }}disabled={isDownloading} className="flex items-center">
{isDownloading ? 'Downloading...' : <span className=" text-black"style={{  fontSize:16,fontWeight:700,letterSpacing:0.5 }} >DOWNLOAD</span>}
{!isDownloading && (
  <DownloadIcon
    className="ml-2"
    style={{  width: '26px', height: '26px', stroke: 'rgb(171, 148, 78)', strokeWidth: '2' }} 
  />
)}
</button>
        {isDownloading && <img width={24} src={loadingIcon} alt='' />}
      </div>
    </div>
  )
}


