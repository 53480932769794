import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

import { ChartContent } from './ChartContent'
import { Map } from '../chartContainers/Map'

import { PRECIPITATIONS_BAR_COLOR_SCALE, PRECIPITATIONS_BAR_COLOR_SCALE2 } from '../../lib/constants'
import { TemperaturesChartcopy } from '../smallCharts/TemperatureChartcopy'
import { ReactCompareSlider, ReactCompareSliderHandle } from 'react-compare-slider';


const TemperaturesVizByYear = ({ year  }) => {
  const {
    data: { temperaturesBar2020, temperaturesBar19812019 },
  } = useMst()

  return (
    <>
      {year === '<2020' ? (
        <div className='flex justify-between'>
          <div style={{ paddingLeft: 20 }}>
            2000-2020
          </div>
          <div style={{ width: 100, backgroundColor: 'white' }}>
          </div>
        </div>
      ) : (
        <div className='flex justify-end ' style={{ paddingRight: 18 }}>
          2021-2022
        </div>
      )}
      <div style={{ background: 'white' }}>

        <ChartContent
          isMapChart={true}
          chart={
            <Map
              dataset={year === '2020' ? temperaturesBar2020 : temperaturesBar19812019}
              isInteractive={true}
              borderColor="#AA809E"
              colorScale={year === '2020' ?PRECIPITATIONS_BAR_COLOR_SCALE:PRECIPITATIONS_BAR_COLOR_SCALE2}
              MapTileChart={TemperaturesChartcopy}
              year={true}
            />
          }
        />
        
      </div>
    </>
  )
}

export const TemperaturesViz = observer(() => {
  return (
    <div style={{ height: '98vh', paddingTop: 10 }}>
      <ReactCompareSlider
       handle={<ReactCompareSliderHandle style={{ color: '#8e577d' }} />}
//position={50}
       


        itemOne={<TemperaturesVizByYear   year={'<2020'}  />}
       
        itemTwo={<TemperaturesVizByYear year={'2020'} style={{ fill: 'B2978B' }} />}
        
      />
      
    </div>

  )
})
