import React from 'react'
import { ReactComponent as WobblePlum } from '../assets/wobbles/plum.colors.svg'
import { ReactComponent as WobblePumpkin } from '../assets/wobbles/pumpkin.colors.svg'
import { ReactComponent as WobblePea } from '../assets/wobbles/pea.colors.svg'
import { ReactComponent as WobblePetroleum } from '../assets/wobbles/petroleum.colors.svg'
import { ReactComponent as WobbleBrown } from '../assets/wobbles/brown.colors.svg'

function Wobble({ colorClass, width }) {
  switch (colorClass) {
    case 'plum':
      return <WobblePlum style={{ width }} />
    case 'pumpkin':
      return <WobblePumpkin style={{ width }} />
    case 'petroleum':
      return <WobblePetroleum style={{ width }} />
    case 'pea':
      return <WobblePea style={{ width }} />
    case 'brown':
      return <WobbleBrown style={{ width }} />
    default:
      return <WobblePlum style={{ width }} />
  }
}

export function WobbleBubble({ top, left, colorClass, width, animated = false }) {
  return (
    <div
      className="absolute"
      style={{
        top,
        left,
        animation: `move-${colorClass} 3s ease-in-out infinite`,
        transformOrigin: '50% 50%',
      }}
    >
      {Wobble({ colorClass, width })}
    </div>
  )
}

export function WobbleBubbleIcon({ colorClass, width }) {
  return <div className="">{Wobble({ colorClass, width })}</div>
}
