import React from 'react'
import { max } from 'lodash-es'
import { scaleSqrt, scaleLinear } from 'd3-scale'

import roadDensity from '../../dataset/roadDensity.json'
import { TEXT_SHADOW } from '../../lib/styleUtils'

export const ConcernChart = ({ datum, size, borderColor = '#8b6248', heading = "" }) => {
    const concernLevel = datum * size / 100;
    return (
        <div>
            <div style={{ paddingTop: 8 }}>
                {heading}
            </div>
            <div style={{ width: size, height: size, border: `1px solid ${borderColor}`, position: 'relative' }}>
                <div style={{ width: concernLevel, height: concernLevel, background: borderColor, bottom: 0, left: 0, position: 'absolute' }}>
                </div>
                <div style={{ position: 'absolute', bottom: 0, left: 2, color: 'black', fontSize: 12, textShadow: TEXT_SHADOW, fontWeight: 700 }}>{datum.toFixed(2)}%</div>
            </div>
        </div>
    )
}
