import React from 'react'
import { SquareTile } from './SquareTile'
import { SQUARE_TILE_SIZE } from '../../lib/constants'

export const SquareTilesGrid = ({
  dataset,
  SquareTileChart = () => null,
  size,
  borderColor,
  gridGap,
  grouped,
}) => {
  const padding = 10
  const tileSize = SQUARE_TILE_SIZE

  const nigeriaWidth = dataset.flat().find((el) => el.countryName === 'Nigeria')?.gdpPerCapita

  return (
    <div style={{  padding: padding, overflow: 'visible' }}>
      {grouped ? (
        dataset.map((group) => {
          return (
            <div key={group[0].continent}>
              <h3 className="text-xl " style={{ paddingLeft: padding }}>
                {group[0].continent}
              </h3>
              <div
                style={{ height: '2px', background: '#E4E4E4', margin: `0px ${padding}px 10px` }}
              />
              
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: `repeat(auto-fill, minmax(${tileSize}px, 1fr))`,
                  rowGap: gridGap,
                  columnGap: 10,
                  padding: padding,
                }}
              >
                {group.map((datum) => {
                  return (
                    <SquareTile
                      key={datum.countryName}
                      SquareTileChart={SquareTileChart}
                      dataset={dataset}
                      nigeriaWidth={nigeriaWidth}
                      datum={datum}
                      size={size}
                      borderColor={borderColor}
                    />
                  )
                })}
              </div>
            
            </div>
          )
        })
      ) : (
        
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: `repeat(auto-fill, ${tileSize}px)`,
            rowGap: gridGap,
            columnGap: 10,
            padding: padding,
          }}
        >
          {dataset.map((datum, index) => {
            return (
              <SquareTile
                key={index}
                SquareTileChart={SquareTileChart}
                dataset={dataset}
                datum={datum}
                size={size}
                borderColor={borderColor}
              />
            )
          })}
          
        </div>
        
      )}
        <br/><br/><br/>  <br/><br/> 
    </div>
  )
}
