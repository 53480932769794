import React from 'react'
import { kebabCase } from 'lodash-es'

import { ElevationSummerystatus } from '../Summerystatus/ElevationSummerystatus'
import { LandUseSummerystatus } from '../Summerystatus/LandUseSummerystatus'
import { UrbanisationSummerystatus } from '../Summerystatus/UrbanisationSummerystatus'
import { GrowthSpacialSummerystatus} from '../Summerystatus/GrowthSpacialSummerystatus'
import { RoadDensitySummerystatus} from '../Summerystatus/RoadDensitySummerystatus'
import { PrecipitationsSummerystatus} from '../Summerystatus/PrecipitationsSummerystatus'
import { TemperaturesSummerystatus} from '../Summerystatus/TemperaturesSummerystatus'
import { TemperatureSpatialSummerystatus} from '../Summerystatus/TemperatureSpatialSummerystatus'
import { GDPSummerystatus } from '../Summerystatus/GDPSummerystatus'
import { EconomicContributionSummerystatus } from '../Summerystatus/EconomicContributionSummerystatus'
import { ImportExportSummerystatus} from '../Summerystatus/ImportExportSummerystatus'
import { IfNigeriaWas100PeopleSummerystatus} from '../Summerystatus/IfNigeriaWas100PeopleSummerystatus'
import { PopulationIncreaseSummerystatus } from '../Summerystatus/PopulationIncreaseSummerystatus'
import { PopulationSpatialSummerystatus} from '../Summerystatus/PopulationSpatialSummerystatus'
import { HDISummerystatus } from '../Summerystatus/HDISummerystatus'
import { NightSpatialSummerystatus } from '../Summerystatus/NightSpatialSummerystatus'
import { SchoolsHealthCentersSummerystatus } from '../Summerystatus/SchoolsHealthCentersSummerystatus'
import { ReligiousCentersSummerystatus } from '../Summerystatus/ReligiousCentersSummerystatus'
import { ClockSummerystatus } from '../Summerystatus/ClockSummerystatus'
import { NigerianMainTypeOfWorkSummerystatus } from '../Summerystatus/NigerianMainTypeOfWorkSummerystatus'
import { ModesOfTransportationSummerystatus } from '../Summerystatus/ModesOfTransportationSummerystatus'
import { DailyPowerSummerystatus } from '../Summerystatus/DailyPowerSummerystatus'
import { ReligiousServicesSummerystatus } from '../Summerystatus/ReligiousServicesSummerystatus'
import { MovementSummerystatus } from '../Summerystatus/MovementSummerystatus'
import { StrengthFromAbroadSummerystatus } from '../Summerystatus/StrengthFromAbroadSummerystatus'
import { InternetUseSummerystatus } from '../Summerystatus/InternetUseSummerystatus'
import { WhatNigeriansAskSummerystatus } from '../Summerystatus/WhatNigeriansAskSummerystatus'
import { SignificantIssuesSummerystatus } from '../Summerystatus/SignificantIssuesSummerystatus'
import { AgrDgrSummerystatus } from '../Summerystatus/AgrDgrSummerystatus'
import { ConcernsSummerystatus } from '../Summerystatus/ConcernsSummerystatus'
import { WellbeingSummerystatus } from '../Summerystatus/WellbeingSummerystatus'
import { SatisfactionSummerystatus } from '../Summerystatus/SatisfactionSummerystatus'
import { Towards2023Summerystatus } from '../Summerystatus/Towards2023Summerystatus'
import { SATISFACTIONWITHLIFESummerystatus } from '../Summerystatus/SATISFACTIONWITHLIFESummerystatus'

import { RoadDensityResource } from '../Resource/RoadDensityResource'



import { ImportExportViz2 } from '../components/viz/ImportExportViz2'


import { ElevationSpatialViz } from '../components/viz/ElevationSpatialViz'
import { LandUseViz } from '../components/viz/LandUseViz'
import { LandUseSpatialViz } from '../components/viz/LandUseSpatialViz'
import { UrbanisationTrendViz } from '../components/viz/UrbanisationTrendViz'
import { GrowthSpatialViz } from '../components/viz/GrowthSpatialViz'
import { RoadDensityViz } from '../components/viz/RoadDensityViz'
import { PrecipitationsViz } from '../components/viz/PrecipitationsViz'
import { TemperaturesViz } from '../components/viz/TemperatureViz'
import { TemperatureSpatialViz } from '../components/viz/TemperatureSpatialViz'
import { GDPViz } from '../components/viz/GDPViz'
import { EconomicContributionViz } from '../components/viz/EconomicContributionViz'
import { ImportExportViz } from '../components/viz/ImportExportViz'
import { PopulationIncreaseViz } from '../components/viz/PopulationIncreaseViz'
import { PopulationSpatialViz } from '../components/viz/PopulationSpatialViz'
import { HDIViz } from '../components/viz/HDIViz'
import { NightSpatialViz } from '../components/viz/NightSpatialViz'
import { SchoolsAndHealthCentersPerCapitaViz } from '../components/viz/SchoolsAndHealthCentersPerCapitaViz'
import { ReligiousCentersPerCapitaViz } from '../components/viz/ReligiousCentersPerCapitaViz'
import { HealthCentersPerCapitaViz } from '../components/viz/HealthCentersPerCapitaViz'
import { DayInTheLifeViz } from '../components/viz/DayInTheLifeViz'
import { WhatNigeriansDoViz } from '../components/viz/WhatNigeriansDoViz'
import { GettingAroundViz } from '../components/viz/GettingAroundViz'
import { PowerCutsViz } from '../components/viz/PowerCutsViz'
import { InternetUseViz } from '../components/viz/InternetUseViz'
import { MovementViz } from '../components/viz/MovementViz'
import { WhatNigeriansAskViz } from '../components/viz/WhatNigeriansAskViz'
import { IssuesViz } from '../components/viz/IssuesViz'
import { TrustViz } from '../components/viz/TrustViz'
import { LGBTQViz } from '../components/viz/LGBTQViz'
import { ClimateChangeViz } from '../components/viz/ClimateChangeViz'
import { ChangeLivingStandardsViz } from '../components/viz/ChangeLivingStandardsViz'
import { InterestInPoliticsViz } from '../components/viz/InterestInPoliticsViz'
import { IntentionToVoteViz } from '../components/viz/IntentionToVoteViz'
import { RegistrationToVoteViz } from '../components/viz/RegistrationToVoteViz'
import { SatisfactionWithLifeViz } from '../components/viz/SatisfactionWithLifeViz'
import { IfNigeriaWas100PeopleViz } from '../components/viz/IfNigeriaWas100PeopleViz'
import { NigerianMainTypeOfWork } from '../components/viz/NigerianMainTypeOfWork'
import { ModesOfTransportationViz } from '../components/viz/ModesOfTransportationViz'
import { ReligiousServicesViz } from '../components/viz/ReligiousServicesViz'
import { StrengthFromAbroadViz } from '../components/viz/StrengthFromAbroadViz'
import { DailyPowerViz } from '../components/viz/DailyPowerViz'
import { SignificantIssuesViz } from '../components/viz/SignificantIssuesViz'
import { ConcernsViz } from '../components/viz/ConcernsViz'
import { TreeChartViz } from '../components/viz/TreeChartViz'
import { TreeChartViz2 } from '../components/viz/TreeChartViz2'
import { AgrDgrViz } from '../components/viz/AgrDgrViz'

import { LandUseLegend } from '../legend/LandUseLegend'
import { BuiltUpLegend } from '../legend/BuiltUpLegend'
import { RoadDensityLegend } from '../legend/RoadDensityLegend'
import { PrecipitationsLegend } from '../legend/PrecipitationsLegend'
import { TemperaturesLegend } from '../legend/TemperaturesLegend'
import { GDPLegend } from '../legend/GDPLegend'
import { EconomicContributionLegend } from '../legend/EconomicContributionLegend'
import { FromNigeriaToNigeriaLegend } from '../legend/FromNigeriaToNigeriaLegend'
import { VersoLegend } from '../legend/VersoLegend'
import { PopulationIncreaseLegend } from '../legend/PopulationIncreaseLegend'
import { HDILegend } from '../legend/HDILegend'
import { SchoolsHealthCentersLegend } from '../legend/SchoolsHealthCentersLegend'
import { ReligiousCentersLegend } from '../legend/ReligiousCentersLegend'
import { ClockLegend } from '../legend/ClockLegend'
import { WhatNigeriansDoLegend } from '../legend/WhatNigeriansDoLegend'
import { GettingAroundLegend } from '../legend/GettingAroundLegend'
import { MovementLegend } from '../legend/MovementLegend'
import { WhatNigeriansAskLegend } from '../legend/WhatNigeriansAskLegend'
import { InternetUseLegend } from '../legend/InternetUseLegend'
import { IssuesLegend } from '../legend/IssuesLegend'
import { TrustLegend } from '../legend/TrustLegend'
import { ChangeLivingStandardsLegend } from '../legend/ChangeLivingStandardsLegend'
import { InterestInPoliticsLegend } from '../legend/InterestInPoliticsLegend'
import { IntentionToVoteLegend } from '../legend/IntentionToVoteLegend'
import { RegistrationToVoteLegend } from '../legend/RegistrationToVoteLegend'
import { LandUseSpatialLegend } from '../legend/LandUseSpatialLegend'
import { ElevationSpatialLegend } from '../legend/ElevationSpatialLegend'
import { GrowthSpatialLegend } from '../legend/GrowthSpatialLegend'
import { TemperatureSpatialLegend } from '../legend/TemperatureSpatialLegend'
import { PopulationSpatialLegend } from '../legend/PopulationSpatialLegend'
import { NightSpatialLegend } from '../legend/NightSpatialLegend'
import { NigerianMainTypeOfWorkLegend } from '../legend/NigerianMainTypeOfWorkLegend'
import { ModesOfTransportationLegend } from '../legend/ModesOfTransportationLegend'
import { ReligiousServicesLegend } from '../legend/ReligiousServicesLegend'
import { StrengthFromAbroadLegend } from '../legend/StrengthFromAbroadLegend'
import { DailyPowerLegend } from '../legend/DailyPowerLegend'
import { SignificantIssuesLegend } from '../legend/SignificantIssuesLegend'
import { ConcernsLegend } from '../legend/ConcernsLegend'
import { TreeChartLegend } from '../legend/TreeChartLegend'
import { AgrDgrLegend } from '../legend/AgrDgrLegend'

import {
  GEOGRAPHY_AND_CLIMATE,
  DEMOGRAPHICS,
  PEOPLE,
  ECONOMY,
  PERCEPTIONS_AND_ATTITUDES,
  ELEVATION,
  LAND_USE,
  CLIMATE_TRENDS_PRECIPITATIONS,
  CLIMATE_TRENDS_TEMPERATURE,
  ROAD_DENSITY_PER_STATE,
  URBANISATION,
  HUMAN_DEVELOPMENT_INDEX,
  PLUM,
  PUMPKIN,
  PEA,
  BROWN,
  PETROLEUM,
  RELIGIOUS_CENTERS,
  SCHOOLS_HEALTH_CENTERS,
  GDP,
  ECONOMIC_CONTRIBUTION,
  FROM_NIGERIA_TO_NIGERIA,
  IF_NIGERIA_WAS_100_PEOPLE,
  POPULATION_INCREASE,
  A_DAY_IN_THE_LIFE,
  ONLINE,
  WHAT_NIGERIANS_DO,
  HOW_THEY_GET_AROUND,
  POWER_CUTS,
  MOVEMENT,
  WHAT_NIGERIANS_ASK,
  PARTICIPATION_IN_POLITICS,
  INTENTION_TO_VOTE,
  REGISTRATION_TO_VOTE,
  CLIMATE_CHANGE,
  ISSUES_TO_PRIORITISE,
  SATISFACTION_WITH_LIFE,
  TRUST,
  LGBT,
  FINANCIAL_WELLBEING,
  LAND_USE_SPATIAL,
  URBAN_GROWTH_SPATIAL,
  SURFACE_TEMPERATURE_SPATIAL,
  POPULATION_GROWTH_SPATIAL,
  NIGERIA_AT_NIGHT_SPATIAL,
  NIGERIA_RELIGION,
  STRENGTH_FROM_ABROAD,
  CONCERN
} from '../lib/constants'

import { SchoolsAndHealthCentersPerCapitaFilter, GrowthSpacialVizFilter, ImportExportVizFilter, GDPVizFilter , IfNigeriaWas100PeopleVizFilter, DayInTheLifeVizFilter, LandUseVizFilter, ConcernsFilter, TreeChartFilter, AgrDgrFilter,TreeChartFilter2} from '../components/filters'

export const dataset = [
  {
    id: GEOGRAPHY_AND_CLIMATE,
    url: kebabCase(GEOGRAPHY_AND_CLIMATE),
    colorClass: PLUM,
    name: 'Geography and Climate',
    
    description: '',
    subsections: [
      {
        key: ELEVATION,
        name: ELEVATION,
        info: 'NASA. 2020. Shuttle Radar Topography Mission (SRTM). Available at: https://www2.jpl.nasa.gov/srtm/',
        description: '',
        //Legend: () => {
          //return <ElevationSpatialLegend />  },
      
          Content: () => {
            
        
            return (
              <div className="responsive-viz">
                <ElevationSpatialViz />
              </div>
            );
          },
         Summerystatus: () => {
          return <ElevationSummerystatus />
        },
      },
      {
        key: LAND_USE,
        name: 'Land use',
        info: '1975 - 2013: Tappan, G. G., Cushing, W.M., Cotillon, S.E.,Mathis, M.L., Hutchinson, J.A., Herrmann, S.M., and Dalsted, K.J., 2016, West Africa Land Use Land Cover Time Series: U.S. Geological Survey data release. Available at: http://dx.doi.org/10.5066/F73N21JF 2020: Karra, Kontgis, et al. “Global land use/land cover with Sentinel-2 and deep learning.” IGARSS 2021-2021 IEEE International Geoscience and Remote Sensing Symposium. IEEE, 2021. Available at: https://www.arcgis.com/home/item.html?id=d6642f8a4f6d4685a24ae2dc0c73d4ac',
        description: '',
        Filter: () => {
          return <LandUseVizFilter />
        },
        Summerystatus: () => {
          return <LandUseSummerystatus />
        },
        Content: () => {
          return <LandUseViz />
        },
        Legend: () => {
          return <LandUseLegend />
        },
      },
      {
        key: URBANISATION,
        name: 'Urbanization trends',
        info: '1975 - 2013: Tappan, G. G., Cushing, W.M., Cotillon, S.E., Mathis, M.L., Hutchinson, J.A., Herrmann, S.M., and Dalsted, K.J., 2016, West Africa Land Use Land Cover Time Series: U.S. Geological Survey data release. Available at: http://dx.doi.org/10.5066/F73N21JF 2020: Karra, Kontgis, et al. “Global land use/land cover with Sentinel-2 and deep learning.” IGARSS 2021-2021 IEEE International Geoscience and Remote Sensing Symposium. IEEE, 2021. Available at: https://www.arcgis.com/home/item.html?id=d6642f8a4f6d4685a24ae2dc0c73d4ac',
        description: '',
        Summerystatus: () => {
          return <UrbanisationSummerystatus />
        },
        Content: () => {
          return <UrbanisationTrendViz />
        },
        Legend: () => {
          return <BuiltUpLegend />
        },
      },
      {
        key: URBAN_GROWTH_SPATIAL,
        name: URBAN_GROWTH_SPATIAL,
        info: '1975 - 2013: Tappan, G. G., Cushing, W.M., Cotillon, S.E., Mathis, M.L., Hutchinson, J.A., Herrmann, S.M., and Dalsted, K.J., 2016, West Africa Land Use Land Cover Time Series: U.S. Geological Survey data release. Available at: http://dx.doi.org/10.5066/F73N21JF 2020: Karra, Kontgis, et al. “Global land use/land cover with Sentinel-2 and deep learning.” IGARSS 2021-2021 IEEE International Geoscience and Remote Sensing Symposium. IEEE, 2021. Available at: https://www.arcgis.com/home/item.html?id=d6642f8a4f6d4685a24ae2dc0c73d4ac',
        description: '',
        Filter: () => {
          return <GrowthSpacialVizFilter />
        },
        Summerystatus: () => {
          return <GrowthSpacialSummerystatus />
        },
        Content: () => {
          return <GrowthSpatialViz />
        },
        Legend: () => {
          return <GrowthSpatialLegend />
        },
      },
      {
        key: ROAD_DENSITY_PER_STATE,
        name: 'Road density',
        info: 'Open street map OSM. 2020. OpenStreetMap data for Nigeria. Available at:https://download.geofabrik.de/africa/nigeria.htm',
        description: '',
        Summerystatus: () => {
          return <RoadDensitySummerystatus />
        },
        Resource: () => {
          return  <RoadDensityResource />
        },
        Content: () => {
          return <RoadDensityViz />
        },
        Legend: () => {
          return <RoadDensityLegend />
        },
      },
      {
        key: CLIMATE_TRENDS_PRECIPITATIONS,
        name: 'Precipitation trends by state',
        info: 'National Aeronautics and Space Administration (NASA) Langley Research Center (LaRC) Prediction of Worldwide Energy Resource (POWER)Project funded through the NASA Earth Science/Applied Science Program: Monthly & Annual Precipitation, POWER Data Access Viewer v2.0.0,1981-2020. Available at: https://developers.google.com/earth-engine/datasets/catalog/MODIS_061_MOD11A2',
        description: '',
        Summerystatus: () => {
          return <PrecipitationsSummerystatus />
        },
        Content: () => {
          return <PrecipitationsViz />
        },
        Legend: () => {
          return <PrecipitationsLegend />
        },
      },
      {
        key: CLIMATE_TRENDS_TEMPERATURE,
        name: 'Temperature trends by state',
        info: 'National Aeronautics and Space Administration (NASA) Langley Research Center (LaRC) Prediction of Worldwide Energy Resource (POWER)Project funded through the NASA Earth Science/Applied Science Program: Monthly & Temperature, POWER Data Access Viewer v2.0.0,1981-2020. Available at: https://developers.google.com/earth-engine/datasets/catalog/MODIS_061_MOD11A2',
        description: '',
        Summerystatus: () => {
          return <TemperaturesSummerystatus />
        },
        Content: () => {
          return <TemperaturesViz />
        },
        Legend: () => {
          return <TemperaturesLegend />
        },
      },
      {
        key: SURFACE_TEMPERATURE_SPATIAL,
        name: SURFACE_TEMPERATURE_SPATIAL,
        info: 'Wan, Z., S. Hook, G. Hulley. MODIS/Terra Land Surface Temperature/Emissivity Daily L3 Global 1km SIN Grid V061. 2021, distributed by NASA EOSDIS Land Processes DAAC. Available at:https://doi.org/10.5067/MODIS/MOD11A1.061 . Accessed 2022-10-06.',
        description: '',
        Summerystatus: () => {
          return <TemperatureSpatialSummerystatus />
        },
        Content: () => {
          return <TemperatureSpatialViz />
        },
        Legend: () => {
          return <TemperatureSpatialLegend />
        },
      },
    ],
  },

  {
    id: ECONOMY,
    url: kebabCase(ECONOMY),
    colorClass: PUMPKIN,
    name: 'Economy',
    description: '',
    subsections: [
      {
        key: GDP,
        name: 'Nigeria compared',
        info: 'International Monetary Fund. 2022. World Economic Outlook. Available at: https://www.imf.org/en/Publications/WEO/weo-database/2022/October [Accessed 12 October 2021].',
        description:'GDP and GDP per capita',
       
        Filter: () => {
          return <GDPVizFilter />
        },
        Summerystatus: () => {
          return <GDPSummerystatus />
        },
        Content: () => {
          return <GDPViz />
        },
        Legend: () => {
          return <GDPLegend />
        },
      },
      {
        key: ECONOMIC_CONTRIBUTION,
        name: 'Economic structure',
        info: 'National Bureau of Statistics. 2022. Nigerian Gross Domestic Product Report (Q2 2022). Available at: https://nigerianstat.gov.ng/elibrary [Accessed July 2022].',
        description: 'Sector contribution to GDP',
        Summerystatus2: () => {
          return <EconomicContributionSummerystatus />
        },
        Content: () => {
          return <EconomicContributionViz />
        },
        Legend: () => {
          return <EconomicContributionLegend />
        },
      },
      {
        key: FROM_NIGERIA_TO_NIGERIA,
        name: 'From Nigeria to Nigeria',
        info: 'United Nations Statistics Division. 2021. UN Comtrade database. Available at: https://comtrade.un.org/ [Accessed October 2022]',
        description: 'Trade flows by partner and product',
        
        Filter: () => {
          return <ImportExportVizFilter />
        },
        Summerystatus: () => {
          return <ImportExportSummerystatus />
        },
        Content: () => {
          return <ImportExportViz />
        },
        Legend: () => {
          return <FromNigeriaToNigeriaLegend />
        },
      },
    ],
  },

  {
    id: DEMOGRAPHICS,
    url: kebabCase(DEMOGRAPHICS),
    colorClass: PEA,
    name: 'Demographics',
    description: '',
    subsections: [
      {
        key: IF_NIGERIA_WAS_100_PEOPLE,
        name: 'If Nigeria was 100 people',
        info: 'National Bureau of Statistics. 2020. Nigeria Living Standards Survey. Available at: https://nigerianstat.gov.ng/elibrary?queries=Nigeria%20Living%20Standards%20Survey.United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.',
        description: '',
        Filter: () => {
          return <IfNigeriaWas100PeopleVizFilter />
        },
        Summerystatus2: () => {
          return <IfNigeriaWas100PeopleSummerystatus />
        },
        Content: () => {
          return <IfNigeriaWas100PeopleViz />
        },
        Legend: () => {
          return <VersoLegend />
        },
      },
      {
        key: POPULATION_INCREASE,
        name: 'Population distribution',
        info: 'WorldPop.2020. Population Counts. Available at: https://hub.worldpop.org/geodata/summary?id=49705',
        description: 'Est. population density and growth',
        Summerystatus2: () => {
          return <PopulationIncreaseSummerystatus />
        },
        Content: () => {
          return <PopulationIncreaseViz />
        },
        Legend: () => {
          return <PopulationIncreaseLegend />
        },
      },
      {
        key: POPULATION_GROWTH_SPATIAL,
        name: POPULATION_GROWTH_SPATIAL,
        info: 'Schiavina M., Freire S., MacManus K. (2022) GHS-POP R2022A - GHS population grid multitemporal (1975-2030).European Commission, Joint Research Centre (JRC). Available at: http://data.europa.eu/89h/d6d86a90-4351-4508-99c1-cb074b022c4a, doi:10.2905/D6D86A90-4351-4508-99C1-CB074B022C4A',
        description: '',
        Summerystatus2: () => {
          return <PopulationSpatialSummerystatus />
        },
        Content: () => {
          return <PopulationSpatialViz />
        },
        Legend: () => {
          return <PopulationSpatialLegend />
        },
      },
      {
        key: HUMAN_DEVELOPMENT_INDEX,
        name: 'Human Development Index',
        info: 'Global Data Lab. Subnational Human Development Index (2020). Nijmegen School of Management of Radboud University. Available at: https://globaldatalab.org/areadata/table/shdi/NGA/',
        description: 'Subnational Indices',
        Summerystatus: () => {
          return <HDISummerystatus />
        },
        Content: () => {
          return <HDIViz />
        },
        Legend: () => {
          return <HDILegend />
        },
      },
      {
        key: NIGERIA_AT_NIGHT_SPATIAL,
        name: NIGERIA_AT_NIGHT_SPATIAL,
        info: '	Earth Observation Group, Payne Institute for Public Policy. C. D. Elvidge, M. Zhizhin, T. Ghosh, F-C. Hsu, "Annual time series of global VIIRS nighttime lights derived from monthly averages: 2012 to 2019", Remote Sensing (In press). Available at: https://www.mdpi.com/2072-4292/13/5/922 . https://developers.google.com/earth-engine/datasets/catalog/NOAA_DMSP-OLS_NIGHTTIME_LIGHTS?hl=en',
        description: '',
        Summerystatus: () => {
          return <NightSpatialSummerystatus />
        },
        Content: () => {
          return <NightSpatialViz />
        },
        Legend: () => {
          return <NightSpatialLegend />
        },
      },
      {
        key: SCHOOLS_HEALTH_CENTERS,
        name: 'Schools and Health facilities per capita',
        info: 'schools:GRID3. 2020. Designated institution to provide learning spaces and learning environments for the teaching of students. Available at: https://grid3.gov.ng/datasets?q=schools.// Health Centers:GRID3. 2020. Entities that provide medical and/or healthcare services and/or engage in the use generally of natural and/or artificial materials to create or dispense drugs; hospitals, pharmacy, clinic, health post dispensary. Available at: https://grid3.gov.ng/datasets?&sector=[%22health-and-safety%22]',
        description: '',
       Filter: () => {
          return <SchoolsAndHealthCentersPerCapitaFilter/>
       },
        Summerystatus: () => {
          return <SchoolsHealthCentersSummerystatus />
        },
        Content: () => {
          return <SchoolsAndHealthCentersPerCapitaViz />
        },
        Legend: () => {
          return <SchoolsHealthCentersLegend />
        },
      },
      // {
      //   key: RELIGIOUS_CENTERS,
      //   name: 'Places of wordship per capita',
      //   info: 'GRID3. 2020. Building used for religious activities particularly for rituals and worship services. Available at: https://grid3.gov.ng/datasets?&sector=[%22religion%22]',
      //   description: '',
      //   Summerystatus: () => {
      //     return <ReligiousCentersSummerystatus />
      //   },
      //   Content: () => {
      //     return <ReligiousCentersPerCapitaViz />
      //   },
      //   Legend: () => {
      //     return <ReligiousCentersLegend />
      //   },
      // },
    ],
  },

  {
    id: PEOPLE,
    url: kebabCase(PEOPLE),
    colorClass: PETROLEUM,
    name: 'Life and Work',
    description: '',
    subsections: [
      {
        key: A_DAY_IN_THE_LIFE,
        name: 'Wetin dey happen',
        info: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.',
        description: 'Average time spent on main activities',
        Filter: () => {
          return <DayInTheLifeVizFilter />
        },
        Summerystatus2: () => {
          return <ClockSummerystatus />
        },
        Content: () => {
          return <DayInTheLifeViz />
        },
        Legend: () => {
          return <ClockLegend />
        },
      },
      {
        key: WHAT_NIGERIANS_DO,
        name: 'How your hustle be na?',
        info: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.',
        description: 'Main Type of Work',
        Summerystatus2: () => {
          return <NigerianMainTypeOfWorkSummerystatus />
        },
        Content: () => {
          return <NigerianMainTypeOfWork />
        },
        Legend: () => {
          return <NigerianMainTypeOfWorkLegend />
        },
      },
      {
        key: HOW_THEY_GET_AROUND,
        name: 'How you dey move around?',
        info: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.',
        description: 'Main modes of transportation',
        Summerystatus2: () => {
          return <ModesOfTransportationSummerystatus />
        },
        Content: () => {
          return <ModesOfTransportationViz />
        },
        Legend: () => {
          return <ModesOfTransportationLegend />
        },
      },
      {
        key: POWER_CUTS,
        name: 'How light be for your area?',
        info: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.',
        description: 'Average daily power cuts experienced',
        Summerystatus2: () => {
          return <DailyPowerSummerystatus />
        },
        Content: () => {
          return <DailyPowerViz />
        },
        Legend: () => {
          return <DailyPowerLegend />
        },
      },
      {
        key: NIGERIA_RELIGION,
        name: 'na God o',
        info: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.',
        description: 'Attendance of religious services',
        Summerystatus2: () => {
          return <ReligiousServicesSummerystatus />
        },
        Content: () => {
          return <ReligiousServicesViz />
        },
        Legend: () => {
          return <ReligiousServicesLegend />
        },
      },
      {
        key: MOVEMENT,
        name: 'Ajala D’ Traveller',
        description:'State of origin vs state of residence',
        info: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.',
        //description: 'Internal migration',
        Summerystatus2: () => {
          return <MovementSummerystatus />
        },
        Content: () => {
          return <MovementViz />
        },
        Legend: () => {
          return <MovementLegend />
        },
      },
      {
        key: STRENGTH_FROM_ABROAD,
        name: 'Japa sef dey pay o!',
        info: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.',
        description: 'Receipt of external remittances',
        Summerystatus2: () => {
          return <StrengthFromAbroadSummerystatus />
        },
        Content: () => {
          return <StrengthFromAbroadViz />
        },
        Legend: () => {
          return <StrengthFromAbroadLegend />
        },
      },
      {
        key: ONLINE,
        name: 'U get internet?',
        info: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.',
        description: 'Internet use',
        Summerystatus2: () => {
          return <InternetUseSummerystatus />
        },
        Content: () => {
          return <InternetUseViz />
        },
        Legend: () => {
          return <InternetUseLegend />
        },
      },
      {
        key: WHAT_NIGERIANS_ASK,
       // name: 'Hot topics',
       name: 'Oya, make we check google',
        info: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.',
        //description: 'Trending Google searches 2022 (as of December 2022)',
        description: ' (October 2022 to September 2023)',
        Summerystatus2: () => {
          return <WhatNigeriansAskSummerystatus />
        },
        Content: () => {
          return <WhatNigeriansAskViz />
        },
        Legend: () => {
          return <WhatNigeriansAskLegend />
        },
      },
    ],
  },

  {
    id: PERCEPTIONS_AND_ATTITUDES,
    url: kebabCase(PERCEPTIONS_AND_ATTITUDES),
    colorClass: BROWN,
    name: 'Perceptions and Attitudes',
    description: '',
    subsections: [
      {
        key: ISSUES_TO_PRIORITISE,
        name: 'Most significant issues facing Nigeria',
        info: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.',
        description: '',
        Summerystatus2: () => {
          return <SignificantIssuesSummerystatus />
        },
        Filter: () => {
          return <TreeChartFilter2 chartType='significantIssues' />
        },

        Content: () => {
          
        //   return <ImportExportViz2  />
        // },
          
          return <TreeChartViz2 chartType='significantIssues' /> },
        //   return <SignificantIssuesViz />
        // },
        
        Legend: () => {
          return <SignificantIssuesLegend />
        },
      },
      {
        key: TRUST,
        name: 'Agree or disagree',
        info: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.',
        description: '',
        Filter: () => {
          return <AgrDgrFilter chartType='agrDgr' />
        },
        Summerystatus2: () => {
          return <AgrDgrSummerystatus />
        },
        Content: () => {
          return <AgrDgrViz chartType='agrDgr' />
        },
        Legend: () => {
          return <AgrDgrLegend chartType='agrDgr' />
        },
      },
      {
        key: CONCERN,
        name: 'How concerned are you about...',
        info: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.',
        description: '',
        Filter: () => {
          return <ConcernsFilter />
        },
        Summerystatus2: () => {
          return <ConcernsSummerystatus />
        },
        Content: () => {
          return <ConcernsViz chartType='concern' />
        },
        Legend: () => {
          return <ConcernsLegend />
        },
      },
      // {
      //   key: LGBT,
      //   name: 'Agree or disagree (LGBT)',
      //   description: 'Gay men and lesbians should be free to live their own lives as they wish?',
      //   Content: () => {
      //     return <LGBTQViz />
      //   },
      //   Legend: () => {
      //     return <TrustLegend />
      //   },
      // },
      // {
      //   key: CLIMATE_CHANGE,
      //   name: 'Climate change',
      //   description: 'How worried are you about climate change?',
      //   Content: () => {
      //     return <ClimateChangeViz />
      //   },
      //   Legend: () => {
      //     return <TrustLegend />
      //   },
      // },
      {
        key: FINANCIAL_WELLBEING,
        name: 'Change in financial well-being',
        info: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.',
        description: '',
        Summerystatus2: () => {
          return <WellbeingSummerystatus/>
         },

        Filter: () => {
          return <TreeChartFilter chartType='financialWellbeing' />
        },

        Content: () => {
          return <TreeChartViz chartType='financialWellbeing' />
        },
        Legend: () => {
          return <TreeChartLegend chartType='financialWellbeing' />
        },
      },
      // {
      //   key: PARTICIPATION_IN_POLITICS,
      //   name: 'Satisfaction in government\'s performance',
      //   info: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.',
      //   description: '',
      //   Filter: () => {
      //     return <ConcernsFilter chartType='satisfaction' />
      //   },
      //   Summerystatus: () => {
      //     return <SatisfactionSummerystatus />
      //   },
      //   Content: () => {
      //     return <ConcernsViz chartType='satisfaction' />
      //   },
      //   Legend: () => {
      //     return <ConcernsLegend chartType='satisfaction' />
      //   },
      // },
      // {
      //   key: INTENTION_TO_VOTE,
      //   name: 'Towards 2023: intention to vote',
      //   description: '',
      //   Content: () => {
      //     return <IntentionToVoteViz />
      //   },
      //   Legend: () => {
      //     return <IntentionToVoteLegend />
      //   },
      // },
      {
        key: REGISTRATION_TO_VOTE,
        name: 'Civic participation',
        info: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.',
        description: '',
        Filter: () => {
          return <ConcernsFilter chartType='towards2023' />
        },
        Summerystatus2: () => {
          return <Towards2023Summerystatus />
        },
        Content: () => {
          return <ConcernsViz chartType='towards2023' />
        },
        Legend: () => {
          return <ConcernsLegend chartType='towards2023' />
        },
      },
      {
        key: SATISFACTION_WITH_LIFE,
        name: 'Wetin dey happen?',
        info: 'United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.',
        description: 'Ratings of overall satisfaction with life',
        Filter: () => {
          return <TreeChartFilter chartType='overallSatisfaction' />
        },
        Summerystatus2: () => {
          return <SATISFACTIONWITHLIFESummerystatus />
        },
        Content: () => {
          return <TreeChartViz chartType='overallSatisfaction' />
        },
        Legend: () => {
          return <TreeChartLegend chartType='overallSatisfaction' />
        },
      },
    ],
  },
]


  
     
      
    


