import React from 'react'
import { scaleLinear } from 'd3-scale'
import { MultipleBarsBarchart } from './MultipleBarsBarchart'
import { INTERNET_CLUSTERS } from '../../lib/constants'

export const InternetUseChart = ({ datum, size, colorScale, barLabels }) => {
  const barLengthScale = scaleLinear().domain([0, 100]).range([0, size])

  return (
    <>
      <svg x={0} y={0} width={size} height={size}>
        <g>
          <MultipleBarsBarchart
            groups={INTERNET_CLUSTERS}
            datum={datum}
            width={size*1.05}
            height={size}
            barLengthScale={barLengthScale}
            colorScale={colorScale}
            isMapBarchart={true}
            barLabels={barLabels}
          />
        </g>
      </svg>
    </>
  )
}
