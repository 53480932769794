import React from 'react'

export const SquareTile = ({
  dataset,
  datum,
  SquareTileChart = () => null,
  size,
  borderColor,
  nigeriaWidth,
  isAlignedRight,
  fill,
  label,
  maxValue,
}) => {
  return (
    <div
      className="relative"
      style={{
        boxSizing: 'content-box',
        borderColor: borderColor,
        borderWidth: 1,
        width: size,
        height: size,
      }}
    >
      <SquareTileChart
        dataset={dataset}
        datum={datum}
        size={size}
        borderColor={borderColor}
        nigeriaWidth={nigeriaWidth}
        isAlignedRight={isAlignedRight}
        fill={fill}
        label={label}
        maxValue={maxValue}
      />
    </div>
  )
}
