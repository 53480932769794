import React, { useRef, useState, useEffect } from 'react';
import useSize from '@react-hook/size';
import { observer } from 'mobx-react-lite';
import { noop } from 'lodash-es';

import { ReactComponent as CloseIcon } from '../assets/close.svg';
import { padWithZero } from '../lib/formatUtils';
import { fromKeyToIndex } from '../lib/dataUtils';
import { WobbleBubbleIcon } from './WobbleBubble';

export const SubsectionOpen = observer(({ section, subsection }) => {
  const { name, description, Content = () => null, Legend = () => null, Filter = () => null, Summerystatus = () => null, Summerystatus2 = () => null } = subsection;
  const index = fromKeyToIndex(section.subsections, subsection.key) + 1;
  const formattedIndex = padWithZero(index);

  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const headerRef = useRef(null);
  const legendRef = useRef(null);

  const [headerWidth, headerHeight] = useSize(headerRef);
  const [legendWidth, legendHeight] = useSize(legendRef);

  const [realLegendHeight, setRealLegendHeight] = useState(null);

  useEffect(() => {
    if (legendHeight !== 0 && realLegendHeight == null) {
      setRealLegendHeight(legendRef.current.scrollHeight);
    }
  }, [legendHeight]);

  const handleKeyDown = (event, action) => {
    if (event.key === 'Enter' || event.key === ' ') {
      action();
    }
  };

  return (
    <div className="w-full h-full bg-white overflow-x-hidden relative" id="subsection-page">
      <div
        ref={headerRef}
        className="w-full border-b bg-white px-5 py-1 flex justify-between items-center font-bold fixed z-10"
        style={{ marginTop: 0, paddingTop: 8 }}
      >
        <div>
          {`${formattedIndex} ${name}`}
          {description && <span className="font-normal">{`: ${description}`}</span>}
        </div>

        <div className="flex justify-center items-center pl-3 6">
          <div className={`text-${section.colorClass}Dark text-sm mr-1`}>
            {section.name}
          </div>
          <WobbleBubbleIcon colorClass={section.colorClass} width={16} />
        </div>
      </div>

      <div className="grid grid-cols-12 gap-2 h-full" style={{ paddingTop: 20 }}>
        <div
          className="
            md:col-start-1 md:col-span-4
            lg:col-start-1 lg:col-span-4
            xl:col-start-1 xl:col-span-4
            2xl:col-start-1 2xl:col-span-3
            flex flex-col items-center pt-10"
          ref={legendRef}
          style={{ transform: 'scale(0.75) translateY(-15%)', height: '90vh' }}
        >
          <div className="flex flex-col justify-between" style={{ paddingLeft: 30 }}>
            <div className="flex flex-col justify-between" style={{ transform: 'translateY(-3%)' }}>
              <Filter />
            </div>
            <div className="flex flex-col justify-between" style={{ marginTop: 13 }}>
              <h2></h2>
              <Legend />
            </div>
          </div>
        </div>

        <div
          className="
            pr-3 -pl-0
            md:col-end-13 md:col-span-8
            lg:col-end-13 lg:col-span-8
            xl:col-end-13 xl:col-span-8
            2xl:col-end-13 2xl:col-span-9
            flex flex-col"
        >
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', transform: 'scale(0.79) translateY(-11%)' }}>
            <div style={{ width: 900, marginBottom: 20 }}>
              <Content />
              <br />
            </div>
          </div>
          <div id="subsection" style={{ width: '100%', display: 'flex', justifyContent: 'center', position: 'absolute', left: -10000 }}>
            <div style={{ width: 800, marginBottom: -72 }}>
              <Content />
              <br />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-white overflow-y-hidden fixed flex flex-col gap-y-0 bottom-0" style={{ paddingLeft: 10, marginLeft: 0, marginTop: -101 }}>
        <Summerystatus />
      </div>

      <div className="w-full bg-white overflow-y-hidden fixed flex flex-col gap-y-0 bottom-0" style={{ paddingLeft: 10, marginLeft: 0, marginTop: -55 }}>
        <Summerystatus2 />
      </div>
    </div>
  );
});
