import React from 'react'
import { scaleLinear } from 'd3-scale'
import { SingleBarBarchart } from './SingleBarBarchart'

export const EconomicContributionChart = ({ datum, size }) => {
  const contributionScale = scaleLinear().domain([0, 100]).range([0, size])

  const height = contributionScale(datum.share)
  const fill = 'rgba(242, 181, 114, 0.9)'

  return (
    <>
      <SingleBarBarchart size={size} barHeight={height} barFill={fill} />

      <div className="absolute left-1 flex flex-col gap-y-1" style={{ bottom: height }}>
        <span className="text-black text-sm font-bold">{datum.share.toFixed(2)}%</span>
      </div>

      <div className="text-black text-sm pt-3">{datum.sector}</div>
      <div
        className="absolute"
        style={{ background: 'black', height: 1, width: size, bottom: '-1px' }}
      />
    </>
  )
}
