import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMst } from '../../state';

export const SchoolsAndHealthCentersPerCapitaFilter = observer(() => {
    const {
        filter: { SchoolsAndHealthCentersPerCapitaSelected, setSchoolsAndHealthCentersPerCapitaSelected },
    } = useMst();

    const handleKeyDown = (event, dataType) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            setSchoolsAndHealthCentersPerCapitaSelected(dataType);
        }
    };

    return (
        <div style={{ paddingTop: 20 }}>
            <div className="">
                {['Health facilities', 'Schools'].map((dataType) => {
                    const isSelected = SchoolsAndHealthCentersPerCapitaSelected === dataType;
                    return (
                        <div
                            key={dataType}
                            className={`flex items-center cursor-pointer activity-item ${isSelected ? 'selected font-bold' : ''}`}
                            onClick={() => setSchoolsAndHealthCentersPerCapitaSelected(dataType)}
                            onKeyDown={(event) => handleKeyDown(event, dataType)}
                            tabIndex={0}
                            style={{
                                color: isSelected ? '#AF9A5B' : '#000000',
                                backgroundColor: isSelected ? '#e9e1cf' : 'transparent',
                                padding: '5px',
                            }}
                            aria-label={`Filter by ${dataType}`}
                        >
                            <div className={`${isSelected ? '' : 'w-0'}`} />
                            <div className="capitalize" style={{ fontSize: 20 }}>{dataType}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
});
