
   
  



import { observer } from 'mobx-react-lite';
import React, { useState } from 'react'
import { useMst } from '../../state';

export const TreeChart2 = observer(({ datum, size, borderColor = '#B2978B', heading = "", isInteractive = false }) => {
    const {
        filter: { horizontalChartSelected }
    } = useMst()
    let employment_livelihoodHeight = size * datum['employment_livelihood'] * 0.01;
    let basic_serviceHeight = employment_livelihoodHeight + size * datum['basic_service'] * 0.01;
    let economyHeight = basic_serviceHeight + size * datum['economy'] * 0.01;
    let insecurityHeight = economyHeight + size * datum['insecurity'] * 0.01;
    let corruptionHeight = insecurityHeight + size * datum['corruption'] * 0.01;
    let othersHeight = corruptionHeight + size * datum['others'] * 0.01;

    
    
    const [tileHover, setTileHover] = useState(false)
    return (
        <div>
            <div style={{ paddingTop: 8 }}>
                {heading}
            </div>
            <div
                style={{ width: size + 2, height: size + 2, border: `1px solid ${borderColor}`, position: 'relative' }}
                onMouseEnter={() => {
                    setTileHover(true)
                }}
                onMouseLeave={() => {
                    setTileHover(false)
                }}
            >
                <div style={{ right: size - employment_livelihoodHeight, height: size, background: '#4a3527', bottom: 0, left: 0, position: 'absolute' }}>
                </div>
                <div style={{ right: size - basic_serviceHeight, height: size, background: '#8b6248', bottom: 0, left: employment_livelihoodHeight, position: 'absolute' }}>
                </div>
                <div style={{ right: size - economyHeight, height: size, background: '#b78f76', bottom: 0, left: basic_serviceHeight, position: 'absolute' }}>
                </div>
                <div style={{ right: size - insecurityHeight, height: size, background: '#c8a996', bottom: 0, left: economyHeight, position: 'absolute' }}>
                </div>
                
                <div style={{ right: size - corruptionHeight, height: size, background: '#d9c4b6', bottom: 0, left: insecurityHeight, position: 'absolute' }}>
                </div>
                
                <div style={{ right: size - othersHeight, height: size, background: '#e9ded6', bottom: 0, left: corruptionHeight, position: 'absolute' }}>
                </div>
                 
                {/* <div style={{ position: 'absolute', bottom: 0, left: 2, color: 'white', fontSize: 12 }}>{datum}%</div> */}
                {isInteractive && tileHover && (
                    <div style={{ position: 'absolute', bottom: size / 22 - 20, right: size + 10, border: '1px solid #B2978B', background: 'white', width: 270, height: 180, zIndex: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ padding: 10 }}>
                                <div style={{ fontSize: 15, fontWeight: 1000, paddingBottom: 15 }}>
                                    {heading === "\u00A0" ? 'Total' : heading}
                                </div>
                                <div className="legend-element flex justify-between">
                                    <div className="flex gap-x-1">
                                        <div className="square w-4 h-4" style={{ background: '#4a3527' }} />
                                        <div className="label text-sm">employment_livelihood</div>
                                    </div>
                                    <div className="percentage text-sm font-semibold">{datum['employment_livelihood'].toFixed(2)}%</div>
                                </div>


                                {(horizontalChartSelected !== 'I feel safe walking alone in my neigbourhood after dark') && (
                                    <div className="legend-element flex justify-between">
                                        <div className="flex gap-x-1">
                                            <div className="square w-4 h-4" style={{ background: '#8b6248' }} />
                                            <div className="label text-sm">basic service</div>
                                        </div>
                                        <div className="percentage text-sm font-semibold">{datum['basic_service'].toFixed(2)}%</div>
                                    </div>
                                )}
                                <div className="legend-element flex justify-between">
                                    <div className="flex gap-x-1">
                                        <div className="square w-4 h-4" style={{ background: '#b78f76' }} />
                                        <div className="label text-sm">economy</div>
                                    </div>
                                    <div className="percentage text-sm font-semibold">{datum['economy'].toFixed(2)}%</div>
                                


                                
                                </div>
                                    <div className="legend-element flex justify-between">
                                        <div className="flex gap-x-1">
                                            <div className="square w-4 h-4" style={{ background: '#c8a996' }} />
                                            <div className="label text-sm">insecurity</div>
                                        </div>
                                        <div className="percentage text-sm font-semibold">{datum['insecurity'].toFixed(2)}%</div>
                                    </div>


                                    <div className="legend-element flex justify-between">
                                    <div className="flex gap-x-1">
                                        <div className="square w-4 h-4" style={{ background: '#d9c4b6' }} />
                                        <div className="label text-sm">corruption</div>
                                    </div>
                                    <div className="percentage text-sm font-semibold">{datum['corruption'].toFixed(2)}%</div>
                                </div>



                                <div className="legend-element flex justify-between">
                                    <div className="flex gap-x-1">
                                        <div className="square w-4 h-4" style={{ background: '#e9ded6' }} />
                                        <div className="label text-sm">others</div>
                                    </div>
                                    <div className="percentage text-sm font-semibold">{datum['others'].toFixed(2)}%</div>
                                </div>






                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
})
