import React from 'react'
import { area } from 'd3'
import { scaleLinear } from 'd3-scale'

import { maxDatumPerColumn } from '../../lib/dataUtils'

export const AreaChart = ({ dataset, datum, size, latestYear, oldestYear, chartFill }) => {
  const datasetScale = scaleLinear()
    .domain([
      -3,
      Math.max(maxDatumPerColumn(dataset, oldestYear), maxDatumPerColumn(dataset, latestYear)),
    ])
    .range([0, size - 30])

  const areaData = [
    { x: 0, y: size - datasetScale(datum[oldestYear]) },
    { x: size, y: size - datasetScale(datum[latestYear]) },
  ]

  const areaFunc = area()
    .x((d) => d.x) // Position of both line breaks on the X axis
    .y1((d) => d.y) // Y position of top line breaks
    .y0(size) // Y position of bottom line breaks

  return (
    <>
      <svg x={0} y={0} width={size} height={size}>
        <path d={areaFunc(areaData)} fill={chartFill} />
      </svg>
    </>
  )
}
