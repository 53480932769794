import React from 'react'

import { MultipleSquaresTile } from '../chartContainers/MultipleSquaresTile'
import { BARCHARTS_GRID_TILE_SIZE } from '../../lib/constants'

export const MultipleSquaresGrid = ({
  dataset,
  maxValue,
  unit,
  decimals,
  colorScale,
  strokeColor,
  borderColor,
  bgColor
}) => {
  const padding = 10
  const leftPadding = 10;
  const tileSize = BARCHARTS_GRID_TILE_SIZE

  return (
    <div >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(auto-fill, ${tileSize}px)`,
          gap: padding,
          padding: padding,
          paddingLeft: leftPadding,
         
        }}
      >
        {Object.keys(dataset).map((state) => {
          return (
            <div key={state}>
              <MultipleSquaresTile datum={dataset[state]} borderColor={borderColor} bgColor={bgColor} />
            </div>
          )
        })}
      </div>
    </div>
  )
}
