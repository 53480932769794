import { uniqBy, groupBy, orderBy, pick, find } from 'lodash-es'
import { types as t, getRoot } from 'mobx-state-tree'
import landUseRawDataset from '../dataset/landUse.json'
import builtUpRawDataset from '../dataset/builtUp.json'
import roadDensityRawDataset from '../dataset/roadDensity.json'
import precipitationsRawDataset from '../dataset/precipitations.json'
import precipitationsBar2020RawDataset from '../dataset/precipitationsBar2020.json'
import precipitationsBar19812019RawDataset from '../dataset/precipitationsBar19812019.json'
import temperatureRawDataset from '../dataset/temperature.json'
import temperaturesBar2020RawDataset from '../dataset/temperaturesBar2020.json'
import temperaturesBar19812019RawDataset from '../dataset/temperaturesBar19812019.json'
import gdpPerCapitaRawDataset from '../dataset/gdpPerCapita.json'
import gdpRawDataset from '../dataset/gdp.json'
import economicContributionRawDataset from '../dataset/economicContribution.json'
import fromNigeriaToNigeriaImportExportRawDataset from '../dataset/fromNigeriaToNigeriaImportExport.json'
import fromNigeriaToNigeriaTop5RawDataset from '../dataset/fromNigeriaToNigeriaTop5Partner.json'
import ifNigeriaWas100RawDataset from '../dataset/ifNigeriaWas100.json'
import populationIncreaseRawDataset from '../dataset/populationIncrease.json'
import humanDevelopmentIndexRawDataset from '../dataset/humanDevelopmentIndex.json'
import HealthCentersDatasetRawDataset from '../dataset/HealthCentersDataset.json'
import SchoolsDatasetRawDataset from '../dataset/SchoolsDataset.json'
import religiousCentersRawDataset from '../dataset/religiousCenters.json'
import aDayInTheLifeRawDataset from '../dataset/aDayInTheLife.json'
import whatNigeriansDoRawDataset from '../dataset/workType.json'
import GettingAroundRawDataset from '../dataset/transport.json'
import PowerCutsRawDataset from '../dataset/powerCuts.json'
import movementRawDataset from '../dataset/movement.json'

import internetUseRawDataset from '../dataset/internetUse.json'
import internetUseAthomeRawDataset from '../dataset/internetUseAthome.json'
import internetUseAtworkRawDataset from '../dataset/internetUseAtwork.json'

import whatNigeriansAskRawDataset from '../dataset/whatNigeriansAsk.json'
import issuesRawDataset from '../dataset/issues.json'
import trustRawDataset from '../dataset/trust.json'
import lgbtqRawDataset from '../dataset/lgbtq.json'
import climateChangeRawDataset from '../dataset/climateChange.json'
import changeLivingStandardsRawDataset from '../dataset/changeLivingStandards.json'
import interestInPoliticsRawDataset from '../dataset/interestInPolitics.json'
import intentionToVoteRawDataset from '../dataset/towards2023.json'
import registrationToVoteRawDataset from '../dataset/registeredToVote.json'
import satisfactionWithLifeRawDataset from '../dataset/satisfactionWithLife.json'
import nigerianMainTypeOfWorkRawDataset from '../dataset/nigerianMainTypeOfWork.json'
import modesOfTransportationRawDataset from '../dataset/modesOfTransportation.json'
import religiousServicesRawDataset from '../dataset/religiousServices.json'
import strengthAbroadRawDataset from '../dataset/strengthAbroad.json'
import dailyPowerRawDataset from '../dataset/dailyPower.json'

import significantIssuesData from '../dataset/significantIssues.json'
import concernData from '../dataset/concernData.json'
import satisfactionData from '../dataset/satisfactionData.json'
import toward2023Data from '../dataset/towards2023New.json'
import overallSatisfaction from '../dataset/overallSatisfaction.json'
import financialWellbeing from '../dataset/financialWellbeing.json'
import agrDgr from '../dataset/agrDgr.json'

export const DataModel = t
  .model('DataModel', {})
  .views((self) => ({
    get root() {
      return getRoot(self)
    },
  }))
  .views((self) => ({
    /**************************
     * Geography and Climate
     **************************/
    // 01 Land use

    landUseByYear(year) {
      return landUseRawDataset.filter((d) => d.year === year)
    },

    get landUseYears() {
      return uniqBy(landUseRawDataset, 'year')
        .map((d) => d.year)
        .sort()
    },

    // 02 Urbanisation trend
    get builtUp() {
      return builtUpRawDataset
    },

    get urbanisationLatestYear() {
      return '2021'
    },

    get urbanisationOldestYear() {
      return '1975'
    },

    // 03 Road density per state

    get roadDensity() {
      return roadDensityRawDataset
    },

    // 04 Climate trends - precipitations
    get precipitationsBar2020() {
      // let dataset = precipitationsRawDataset.filter((d) => d.year === '2020');
      // let newdataset = dataset.map((data) => {
      //   let quarter1 = data.jan + data.feb + data.mar;
      //   let quarter2 = data.apr + data.may + data.jun;
      //   let quarter3 = data.jul + data.aug + data.sep;
      //   let quarter4 = data.oct + data.nov + data.dec;

      //   return ({
      //     ...data,
      //     quarter_1: quarter1 / 3,
      //     quarter_2: quarter2 / 3,
      //     quarter_3: quarter3 / 3,
      //     quarter_4: quarter4 / 3
      //   })
      // })

      // let newNewDataset = newdataset.map((data) => {
      //   for (let barData of precipitationsBar2020RawDataset) {
      //     if (barData.stateName === data.stateName) {
      //       return ({
      //         ...data,
      //         stateCode: barData.stateCode
      //       })
      //     }
      //   }
      // })
      // console.log(newNewDataset)

      return precipitationsBar2020RawDataset
    },

    get precipitationsBar19812019() {
      return precipitationsBar19812019RawDataset
    },

    get precipitations() {
      return precipitationsRawDataset
    },

    get precipitationsGroupedByRegion() {
      return Object.values(groupBy(orderBy(self.precipitations, 'region', 'cityName'), 'region'))
    },

    get precipitationsGroupedByCity() {
      return self.precipitationsGroupedByRegion.map((group) => groupBy(group, 'cityName'))
    },

    // 05 Climate trends - temperature
    get temperaturesBar2020() {
      return temperaturesBar2020RawDataset
    },

    get temperaturesBar19812019() {
      return temperaturesBar19812019RawDataset
    },

    get temperature() {
      return temperatureRawDataset
    },

    get temperatureGroupedByRegion() {
      return Object.values(groupBy(orderBy(self.temperature, 'region', 'cityName'), 'region'))
    },

    get temperatureGroupedByCity() {
      return self.temperatureGroupedByRegion.map((group) => groupBy(group, 'cityName'))
    },

    /**************************
     * Economy
     **************************/

    // 01 GDP

    get gdpPerCapita() {
      return gdpRawDataset  
    },
    get gdp() {
       return gdpPerCapitaRawDataset
    },
 
    get gdpPerCapitaByContinentOrdered() {
     return Object.values(
     groupBy(orderBy(self.gdpPerCapita, 'continent', 'countryName'), 'continent')
      )
    },
    get gdpPerCapitaByContinentOrdered2() {
      return Object.values(
      groupBy(orderBy(self.gdp, 'continent', 'countryName'), 'continent')
       )
     },
    
    // 02 Economic contribution

    get economicContribution() {
      return economicContributionRawDataset
    },

    get economicContributionOrdered() {
      return orderBy(self.economicContribution, 'share', 'desc')
    },

    // 03 Import export

    get fromNigeriaToNigeriaImportExport() {
      return fromNigeriaToNigeriaImportExportRawDataset
    },

    get fromNigeriaToNigeriaTop5() {
      return fromNigeriaToNigeriaTop5RawDataset
    },

    get fromNigeriaToNigeriaTop5ByCategory() {
      return Object.values(groupBy(self.fromNigeriaToNigeriaTop5, 'productCategory'))
    },

    // 03 From Nigeria to Nigeria

    /**************************
     * Demographics and Well-Being
     **************************/

    // 01 If Nigeria was 100 people

    get ifNigeriaWas100() {
      return ifNigeriaWas100RawDataset
    },

    get ifNigeriaWas100Categories() {
      return uniqBy(self.ifNigeriaWas100, 'category').map((d) => d.category)
    },

    ifNigeriaWas100ByCategory(category) {
      return self.ifNigeriaWas100.filter((d) => d.category === category)
    },

    // 02 Population distribution and growth

    get populationIncrease() {
      return populationIncreaseRawDataset
    },

    get populationLatestYear() {
      return '2020'
    },

    get populationOldestYear() {
      return '2000'
    },

    // 03 Human development Index by state
    get humanDevelopmentIndex() {
      return humanDevelopmentIndexRawDataset
    },

    get nigeriaAvg() {
      return humanDevelopmentIndexRawDataset.find((el) => el.stateName === 'Nigeria')
    },

    // 04 Schools and health centers per capita
    get schoolsAndHealthCentersPerCapita() {
      return HealthCentersDatasetRawDataset  
    },

    get HealthCentersPerCapita() {
      return SchoolsDatasetRawDataset 
    },

    get privateSchoolsAndHealthCentersPerCapita() {
      return HealthCentersDatasetRawDataset 

    },


    // 05 Religious centers per capita
    get religiousCentersPerCapita() {
      return religiousCentersRawDataset
    },

    /**************************
     * People and Society
     **************************/

    // 01 A day in the life

    get aDayInTheLife() {
      return aDayInTheLifeRawDataset
    },

    get dailyActivities() {
      return ['Working', 'leisure','Sleeping']
    },

    aDayInTheLifeByActivity(activity) {
      return self.aDayInTheLife
        .map((datum) => pick(datum, ['stateCode', `${activity}Pct`, `${activity}Time`]))
        .map((el) => ({
          stateCode: el.stateCode,
          pct: el[`${activity}Pct`],
          time: el[`${activity}Time`],
        }))
    },
    // 02 what Nigerians do

    get nigerianMainTypeOfWork() {
      return nigerianMainTypeOfWorkRawDataset
    },

    get whatNigeriansDo() {
      return whatNigeriansDoRawDataset
    },

    get workCategories() {
      return uniqBy(self.whatNigeriansDo, 'category')
        .map((d) => d.category)
        .sort()
    },

    whatNigeriansDoByCategory(category) {
      return self.whatNigeriansDo.filter((d) => d.category === category)
    },

    // 03 Getting around
    get modesOfTransportation() {
      return modesOfTransportationRawDataset
    },

    get gettingAround() {
      return GettingAroundRawDataset
    },

    // 04 Power cuts
    get dailyPower() {
      return dailyPowerRawDataset
    },

    dailyPowerByActivity(activity) {
      const newData = self.dailyPower
        .map((el) => ({
          stateCode: el.stateCode,
          state: el.state,
          pieData: [
            { name: "lessThan3Hr", value: el.lessThan3Hr, label: 'Less than 3 hours' },
            // { name: "notGrid", value: el.notGrid, label: 'Not connected to grid' },
            { name: "moreThan7Hr", value: el.moreThan7Hr, label: 'More than 7 hours' },
            { name: "from3To7Hr", value: el.from3To7Hr, label: '3-7 hours' },
          
          ]
        }))
      return newData;
    },

    get powerCuts() {
      return orderBy(PowerCutsRawDataset, 'order')
    },

    //05/new Religious Services
    get religiousServices() {
      return religiousServicesRawDataset
    },

    // 05 Internal migration

    get movement() {
      return movementRawDataset
    },

    get movementGroupedByState() {
      return groupBy(orderBy(self.movement, 'stateCurrent'), 'stateCurrent')
    },

    //06 Strength from abroad
    get strengthAbroad() {
      return strengthAbroadRawDataset
    },

    // 06 internet use

    get internetUsePlace() {
      return ['athome', 'at least a few times a week', 'atwork', 'never']
    },


    get internetUse() {
      return internetUseRawDataset
    },



    get internetUseFrequency() {
      return ['athome', 'at least a few times a week', 'atwork', 'never']
    },

 
    internetUseByFrequency(frequency) {
      return self.internetUse
        .map((datum) =>
          pick(datum, ['stateCode', 'stateName', `athome ${frequency}`, `atwork ${frequency}`])
        )
        .map((el) => ({
          stateCode: el.stateCode,
          stateName: el.stateName,
          athome: el[`athome ${frequency}`],
          atwork: el[`atwork ${frequency}`],
        }))
    },


    
    // 07 What Nigerians ask

    get whatNigeriansAsk() {
      return whatNigeriansAskRawDataset
    },

    /**************************
     * Perceptions and Attitudes
     **************************/

    // 01 Issues
    get issues() {
      return orderBy(issuesRawDataset, 'order')
    },

    // 02 Trust
    get trust() {
      return orderBy(trustRawDataset, 'order')
    },

    // 03 Lgbtq
    get lgbtq() {
      return orderBy(lgbtqRawDataset, 'order')
    },

    // 03 Climate change
    get climateChange() {
      return orderBy(climateChangeRawDataset, 'order')
    },

    // 04 Change of living standards
    get changeLivingStandards() {
      return changeLivingStandardsRawDataset
    },

    // 06 Interest in politics
    get interestInPolitics() {
      return interestInPoliticsRawDataset
    },

    // 07 intention to vote

    get intentionToVote() {
      return intentionToVoteRawDataset
    },

    get intentions() {
      return ['extremely', 'very', 'moderately', 'slightly', 'not', 'unknown']
    },

    intentionToVoteByLikeliness(intention) {
      return self.intentionToVote
        .map((datum) => pick(datum, ['stateCode', 'stateName', `${intention}Likely`]))
        .map((el) => ({
          stateCode: el.stateCode,
          stateName: el.stateName,
          likeliness: el[`${intention}Likely`],
        }))
    },

    // 08 registration to vote

    get registrationToVote() {
      return registrationToVoteRawDataset
    },

    get registrations() {
      return ['Registered to vote', 'Plan to register', 'Has voted last election']
    },

    registrationToVoteByLikeliness(registration) {
      return self.registrationToVote
        .map((datum) => pick(datum, ['stateCode', 'stateName', registration]))
        .map((el) => ({
          stateCode: el.stateCode,
          stateName: el.stateName,
          registration: el[registration],
        }))
    },

    // 09 Satisfaction with life

    get satisfactionWithLife() {
      return orderBy(satisfactionWithLifeRawDataset, 'order')
    },

    get significantIssues() {
      return significantIssuesData
    },

    findData(variable1, variable2) {
      let { var1, var2, ...obj } = find(self.significantIssues, { var1: variable1, var2: variable2 });
      return obj
    },

    get concernData() {
      return concernData
    },

    get satisfactionData() {
      return satisfactionData
    },

    get toward2023Data() {
      return toward2023Data
    },

    get overallSatisfaction() {
      return overallSatisfaction
    },
    get financialWellbeing() {
      return financialWellbeing
    },
    get significantIssues() {
      return significantIssuesData
    },
    get agrDgr() {
      return agrDgr
    },

    findConcernData(chartType, dataType, variable1, variable2) {
      let data;
      switch (chartType) {
        case 'concern':
          data = find(self.concernData, { type: dataType, var1: variable1, var2: variable2 })
          break;
        case 'satisfaction':
          data = find(self.satisfactionData, { type: dataType, var1: variable1, var2: variable2 })
          break;
        case 'towards2023':
          data = find(self.toward2023Data, { type: dataType, var1: variable1, var2: variable2 })
          break;
         
        //tree charts
        case 'overallSatisfaction':
          data = find(self.overallSatisfaction, { type: dataType, var1: variable1, var2: variable2 })
          break;
        case 'financialWellbeing':
          data = find(self.financialWellbeing, { type: dataType, var1: variable1, var2: variable2 })
          break;
          case 'significantIssues':
            data = find(self.significantIssues, { type: dataType, var1: variable1, var2: variable2 })
            break;
  


          
        //Horizontal chart
        case 'agrDgr':
          data = find(self.agrDgr, { type: dataType, var1: variable1, var2: variable2 })
          break;
        default:
          break;
      }

      return data ? data : { data: 0 };
    }

  }))
  .actions((self) => ({}))

