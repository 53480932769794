import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

import { ChartContent } from './ChartContent'
import { Map2 } from '../chartContainers/Map2'
import { InternetUseChart } from '../smallCharts/InternetUseChart'
import { ReactCompareSlider, ReactCompareSliderHandle } from 'react-compare-slider';
import { StackedBarchart } from '../smallCharts/StackedBarchart'
import { INTERNET_COLOR_SCALE, INTERNET_CLUSTERS } from '../../lib/constants'

const InternetUseByPlace = ({ place }) => {
  const {
    data: { internetUseByFrequency, internetUsePlace },
    filter: { selectedFrequency, setSelectedFrequency },
  } = useMst()

  return (
    // <div className={`${place}-component`} style={{ marginTop: -4 }}>
    <div className={`${place}-component`} style={{ marginTop: -4 }}>

      <div className='flex justify-between' style={{ backgroundColor: 'white' }}>
        {place === 'atwork' && <div>
          At Work
        </div>}


        {place === 'internet_use' && <div className='flex justify-end'>
          {/* At Home */}
        </div>}

      </div>
      {place === '' ? (
        <>

          <div style={{ backgroundColor: 'white' }}>
            <ChartContent
              isMapChart={true}
              chart={
                <Map2
                  dataset={internetUseByFrequency(place)}
                  isInteractive={false}
                  borderColor="#309992"
                  colorScale={INTERNET_COLOR_SCALE}
                  MapTileChart={InternetUseChart}
                  barLabels={INTERNET_CLUSTERS}
                  stateArea={true}


                />
              }
            />

          </div>
        </>
      ) :
        (
          <>


            <ChartContent
              isMapChart={true}
              chart={
                <Map2
                  dataset={internetUseByFrequency(place)}
                  isInteractive={true}
                  borderColor="#309992"
                  colorScale={INTERNET_COLOR_SCALE}
                  MapTileChart={InternetUseChart}

                  barLabels={INTERNET_CLUSTERS}
                  
                />
              }
            />
          </>
        )}
    </div>
  )
}

export const InternetUseViz = () => {
  return (
    <div className="" style={{ height: '90vh', paddingTop: 20 }}>
      {/* <ReactCompareSlider
        handle={<ReactCompareSliderHandle style={{ color: '#309992' }} />}
        itemOne={<InternetUseByPlace place='atwork' />}
        itemTwo= */}
        
        {<InternetUseByPlace place='internet_use' />}

   {/* /> */}
    </div>

  )
}
