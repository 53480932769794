import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useMst } from '../../state';

import { ChartContent } from './ChartContent';
import { SquareTilesGrid } from '../chartContainers/SquareTilesGrid';
import { WhatNigeriansAskChart } from '../smallCharts/WhatNigeriansAskChart';
import { SQUARE_TILE_SIZE } from '../../lib/constants';

export const WhatNigeriansAskViz = observer(() => {
  const {
    data: { whatNigeriansAsk },
  } = useMst();

  return (
    <div id="chartContainer" style={{  }}>
      <ChartContent
        chart={
          <SquareTilesGrid
            SquareTileChart={WhatNigeriansAskChart}
            dataset={whatNigeriansAsk}
            size={SQUARE_TILE_SIZE}
            gridGap={110}
            borderColor="#E4E4E4"
          />
        }
      />
    </div>
  );
});
