import React from 'react'

import { GROWTH_SPATIAL_COLOR_SCALE } from '../lib/constants'

export const GrowthSpatialLegend = () => {
  return (
    <div className="ml-1 flex flex-col gap-y-2">
      <div  className="font-bold">How to read it</div>
      <div className="flex flex-col gap-y-1" style={{ marginLeft: 0,marginTop:-10 }}>
        {GROWTH_SPATIAL_COLOR_SCALE.domain().map((label) => (
          <div className="legend-element flex justify-between" key={label}>
            <div className="flex gap-x-1">
              <div
                className="square w-4 h-4"
                style={{ background: GROWTH_SPATIAL_COLOR_SCALE(label) }}
              />
              <div className="label text-sm">{label}</div>
            </div>
          </div>
        ))}
      </div>
      
    </div>
  )
}
