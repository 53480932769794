import React, { useState } from 'react'
import { downloadElementToImage } from '../utils/utils';import { Top5LegendChart } from './Top5LegendChart'
// import DownloadIcon from '@mui/icons-material/Download';
import { ReactComponent as DownloadIcon } from '../assets/download-icon.svg';

const loadingIcon = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

export const Top5Legend = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const handleCaptureDownoadClick = () => {
    const sectionComponent = document.getElementById("subsection");
    const snapshotElem = sectionComponent.getElementsByClassName("import-component")?.[0];
    if (snapshotElem) {
      setIsDownloading(true);
      setTimeout(async () => {
        await downloadElementToImage(snapshotElem,"Top5.png");
        setIsDownloading(false);
      }, 0)
    }
  }
  return (
    <>
    
      <div className="flex flex-col gap-y-3">
      <div className="font-bold">How to read it</div>
        <div className="flex flex-col gap-y-3 w-min items-center">
          <Top5LegendChart />

          <div className="text-black text-sm font-bold">Product Category</div>
        </div>

        <div className="flex items-center gap-x-3 text-greyDark text-sm">
          <div className="w-4 h-4" style={{ background: '#DAA367' }} />
          <span
            className="whitespace-nowrap overflow-ellipsis overflow-hidden"
            style={{ maxWidth: '60%' }}
          >
            Import
          </span>
        </div>

        <div className="flex items-center gap-x-3 text-greyDark text-sm">
          <div className="w-4 h-4" style={{ background: '#F5C48E' }} />
          <span
            className="whitespace-nowrap overflow-ellipsis overflow-hidden"
            style={{ maxWidth: '60%' }}
          >
            Export
          </span>
        </div>
        <div style={{ display: 'flex',  alignItems: 'left',marginLeft:-10 }}>
        <button type="button" onClick={handleCaptureDownoadClick} style={{ color: 'rgb( 161, 81, 0)' }}disabled={isDownloading} className="flex items-center">
{isDownloading ? 'Downloading...' : <span className=" text-black"style={{  fontSize:16,fontWeight:700,letterSpacing:0.5 }} >DOWNLOAD</span>}
{!isDownloading && (
  <DownloadIcon
    className="ml-2"
    style={{  width: '26px', height: '26px', stroke: 'rgb( 161, 81, 0)', strokeWidth: '2' }} 
  />
)}
</button>
        {isDownloading && <img width={24} src={loadingIcon} alt='' />}
      </div>
      </div>
    </>
  )
}
