import React, { useState } from 'react'
import { downloadElementToImage } from '../utils/utils';

import { MapTile } from '../components/chartContainers/MapTile'
// import DownloadIcon from '@mui/icons-material/Download';
import { ReactComponent as DownloadIcon } from '../assets/download-icon.svg';
import { MAP_TILE_SIZE_DESKTOP } from '../lib/mapConstants'
import { StrengthChart } from '../components/smallCharts/StrengthChart'
const loadingIcon = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

export const StrengthFromAbroadLegend = () => {
    const [isDownloading, setIsDownloading] = useState(false);

    const mockDatumDensity = {
        stateCode: 'LA',
        stateName: 'Lagos',
        geoZone: 'South West',
        "density":	10.84,

    }

    const mockDatumLength = {
        stateCode: 'LA',
        stateName: 'Lagos',
        geoZone: 'South West',
        length: 16428.9,
    }
    const handleCaptureDownoadClick = () => {
        const sectionComponent = document.getElementById("subsection");
        const snapshotElem = sectionComponent.getElementsByClassName("map-component")?.[0];
        if (snapshotElem) {
          setIsDownloading(true);
          setTimeout(async () => {
            await downloadElementToImage(snapshotElem,"StrengthFromAbroad.png");
            setIsDownloading(false);
          }, 0)
        }
      }
    return (
        <>
        <div className="font-bold">How to read it</div>
        <div style={{ paddingTop: 10}}>
        <div className="flex flex-col gap-y-8">
            <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP }}>
                <div className="absolute">
                    <MapTile
                        size={MAP_TILE_SIZE_DESKTOP}
                        borderColor="#2D9992"
                        MapTileChart={() => (
                            <div className="absolute text-greyDark top-1 left-1 text-xs">LA</div>
                        )}
                    />
                    <div
                        className="absolute top-0 text-sm text-greyDark whitespace-nowrap font-medium"
                        style={{ left: 90 }}
                    >
                        State code
                    </div>
                </div>
            </div>
            <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP }}>
                <div
                    className="absolute"
                    style={{ minHeight: MAP_TILE_SIZE_DESKTOP, minWidth: MAP_TILE_SIZE_DESKTOP }}
                >
                    <MapTile
                        size={MAP_TILE_SIZE_DESKTOP}
                        datum={mockDatumDensity}
                        borderColor="#2D9992"
                        MapTileChart={StrengthChart}
                    />
                </div>

                <div
                    className="absolute text-sm text-greyDark font-semibold"
                    style={{ left: MAP_TILE_SIZE_DESKTOP + 15, width: 125 }}
                >
                    Percent of household receiving remittances
                </div>
            </div>
        </div>

        <br/>
        <div style={{ display: 'flex',  alignItems: 'left' ,marginLeft:-10}}>
        <button type="button" onClick={handleCaptureDownoadClick} style={{ color: 'rgb(9, 126, 118)' }}disabled={isDownloading} className="flex items-center">
          {isDownloading ? 'Downloading...' : <span className=" text-black"style={{  fontSize:16,fontWeight:700,letterSpacing:0.5 }} >DOWNLOAD</span>}
          {!isDownloading && (
            <DownloadIcon
              className="ml-2"
              style={{  width: '26px', height: '26px', stroke: 'rgb(9, 126, 118)', strokeWidth: '2' }} 
            />
          )}
        </button>
        {isDownloading && <img width={24} src={loadingIcon} alt='' />}
      </div>
        </div>
        </>
    )
}
