import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

export const ChartContent = observer(({ chart = null, isMapChart, Filters = () => null }) => {
  const {
    ui: { isMobile },
  } = useMst()

  const filtersExist = Filters() !== null
  const isFlexLayout = filtersExist && !isMobile && isMapChart

  return (
    <div className=" chartcontent-component" id='chartcontent-component' style={isFlexLayout ? { display: 'flex', flexDirection: 'column' } : null}>
      {' '}
      {filtersExist && (
        <div className="p-5 w-fit">
          <Filters />
        </div>
      )}{' '}
      <div className="left-0 relative"> {chart} </div>
    </div>
  )
})
