export function ellipsisTextLineStyle(lineNumber) {
  return {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: lineNumber,
    WebkitBoxOrient: 'vertical',
  }
}

export const TEXT_SHADOW = '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff'
export const BROWN_TEXT_SHADOW =
  '-1px -1px 0 #A1887D, 1px -1px 0 #A1887D, -1px 1px 0 #A1887D, 1px 1px 0 #A1887D'
