import React from 'react'
import { scaleSqrt } from 'd3-scale'

import { TEXT_SHADOW } from '../../lib/styleUtils'
import { maxDatumPerColumn } from '../../lib/dataUtils'

export const SemicircleChart = ({ dataset, datum, size, semicircleLabels }) => {
  const [label1, label2] = semicircleLabels

  const radiusScale = scaleSqrt()
    .domain([
      0,
      Math.max(
        label1 ? maxDatumPerColumn(dataset, label1) : 10,
        label2 ? maxDatumPerColumn(dataset, label2) : 5
      ),
    ])
    .range([0, size / 2 - 5])

  const firstRadius = label1 ? radiusScale(datum[label1]) : null
  const secondRadius = label2 ? radiusScale(datum[label2]) : null
  const firstMaskId = `semicircle${Math.random()}`
  const secondMaskId = `semicircle${Math.random()}`
  return (
    <>
      <svg x={0} y={0} width={size} height={size}>
        {label2 && (
          <>
            <mask id={secondMaskId}>
              <rect
                width={size}
                height={size / 2}
                fill="white"
                transform={`translate(${size / 2} ${size / 2})
          rotate(-135)
          translate(${-size / 2} ${-size / 2})`}
              />
            </mask>
            <circle
              cx={size / 2}
              cy={size / 2}
              r={secondRadius}
              fill="rgba(122, 110, 72, 0.8)"
              mask={`url(#${secondMaskId})`}
            />
          </>
        )}

        {label1 && (
          <>
            <mask id={firstMaskId}>
              <rect
                width={size}
                height={size / 2}
                fill="white"
                transform={`translate(${size / 2} ${size / 2})
          rotate(45)
          translate(${-size / 2} ${-size / 2})`}
              />
            </mask>
            <circle
              cx={size / 2}
              cy={size / 2}
              r={firstRadius}
              fill="rgba(190, 171, 112, 0.8)"
              mask={`url(#${firstMaskId})`}
            />
          </>
        )}
      </svg>

      {label1 && (
        <div
          className="absolute right-1 top-1 text-black text-sm font-bold"
          style={{ textShadow: TEXT_SHADOW }}
        >
          {datum[label1] !== 0 ? datum[label1].toFixed(2) : 0}
        </div>
      )}

      {label2 && (
        <div
          className="absolute left-1 bottom-0.5 text-black text-sm font-bold"
          style={{ textShadow: TEXT_SHADOW }}
        >
          {datum[label2] !== 0 ? datum[label2].toFixed(2) : 0}
        </div>
      )}
    </>
  )
}
