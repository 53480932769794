import { observer } from 'mobx-react-lite'
import React from 'react'
export const NightSpatialSummerystatus = observer(() => {

  
  return (
    <div className=" ">
      <div style={{ borderTop: '1px solid lightgray',marginLeft:-10 ,marginBottom:0}}>   </div> 
      
        <div className=' xl:col-span-2 flex flex-col  top-0 'style={{ marginRight: 0,marginTop: 0 }}>

 <table className=' border-b    flex-1 ' style={{  marginRight: 0,maxWidth:3000 ,minHeight:40 }}>
 <tr >
 <th ></th>
 <th></th>
 <th></th>
 <th></th>
 </tr>

 
 <tr>

 

 <td style={{ minWidth:0 }}>
 <tr class="custom-Demographics1">Most intensely illuminated LGAs</tr>
 <tr class="custom-Demographics2">Lagos Island and Apapa, Lagos </tr>
 </td>
 

 <td style={{ minWidth:0 }}>
 <tr style={{ fontSize: 11,textTransform: 'uppercase' ,fontWeight: 900,padding:3 ,color:'#AF9A5B'}}>Most intensely illuminated state</tr>
<tr class="custom-Demographics2">Lagos  </tr>
 </td>
 
 <td style={{ minWidth:0 }}>
 <tr class="custom-Demographics1">Least intensely illuminated state</tr>
 <tr class="custom-Demographics2">Borno  </tr>
 </td>



 <td style={{ minWidth:0 }}>
 <tr class="custom-Demographics1">Least intensely illuminated LGAs </tr>
<tr class="custom-Demographics2">Kaga and Kukawa, Borno  </tr>


 </td>
 



 
 
 </tr>
 
 </table>
 <div  className='flex-1 text-greyDark' style={{ fontSize: 10 ,minHeight:44,fontWeight:500,marginTop: 5  }}>
Source: Earth Observation Group, Payne Institute for Public Policy. C. D. Elvidge, M. Zhizhin, T. Ghosh, F-C. Hsu, "Annual time series of global VIIRS nighttime lights derived from monthly averages: 2012 to 2019", Remote Sensing (In press). Available  <a class="custom-link" href="https://developers.google.com/earth-engine/datasets/catalog/NOAA_DMSP-OLS_NIGHTTIME_LIGHTS?hl=en" target="_blank" > here</a> 
 
      
</div>
 </div>
 
 
      
 
      </div>

      


  )

})
