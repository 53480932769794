import React, { useState } from 'react'
import { downloadElementToImage } from '../utils/utils';
import { ReactComponent as DownloadIcon } from '../assets/download-icon.svg';

import { MapTile } from '../components/chartContainers/MapTile'
import { ClockChart } from '../components/smallCharts/ClockChart'
// import DownloadIcon from '@mui/icons-material/Download';
import { MAP_TILE_SIZE_DESKTOP } from '../lib/mapConstants'
const loadingIcon = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

export const ClockLegend = () => {
  const [isDownloading, setIsDownloading] = useState(false);

  const mockDatum = {
    stateCode: 'FC',
    pct: 0.77,	
    time: "9:18",
  }


  const handleCaptureDownoadClick = () => {
    const sectionComponent = document.getElementById("subsection");
    const snapshotElem = sectionComponent.getElementsByClassName("map-component")?.[0];
    if (snapshotElem) {
      setIsDownloading(true);
      setTimeout(async () => {
        await downloadElementToImage(snapshotElem,"clock.png");
        setIsDownloading(false);
      }, 0)
    }
  }
  return (
    <div className="ml-1 flex flex-col gap-y-2">
      <div className="font-bold">How to read it</div>
      <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP}}>
        <div className="absolute">
          <MapTile
            size={MAP_TILE_SIZE_DESKTOP}
            borderColor="transparent"
            MapTileChart={() => (
              <>
                <div className="absolute text-greyDark top-1 left-1 text-xs">LA</div>
                <svg>
                  <circle
                    r={MAP_TILE_SIZE_DESKTOP / 2 - 5}
                    fill="transparent"
                    stroke="#309992"
                    strokeWidth={1}
                    transform={`translate(${MAP_TILE_SIZE_DESKTOP / 2}, ${
                      MAP_TILE_SIZE_DESKTOP / 2
                    })`}
                  />
                </svg>
              </>
            )}
          />
          <div
            className="absolute top-0 text-sm text-greyDark whitespace-nowrap font-medium"
            style={{ left: 90 }}
          >
            State code
          </div>
        </div>
      </div>

      <div className="relative flex gap-x-3 mt-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP }}>
        <div
          className="absolute"
          style={{ minHeight: MAP_TILE_SIZE_DESKTOP, minWidth: MAP_TILE_SIZE_DESKTOP }}
        >
          <MapTile
            borderColor="transparent"
            size={MAP_TILE_SIZE_DESKTOP}
            datum={mockDatum}
            chartFill="#309992"
            MapTileChart={ClockChart}
          />

          <div
            className="absolute text-xs text-gray-400"
            style={{ left: '45%', bottom: MAP_TILE_SIZE_DESKTOP - 5 }}
          >
            12
          </div>
          <div
            className="absolute text-xs text-gray-400"
            style={{ left: '45%', top: MAP_TILE_SIZE_DESKTOP }}
          >
            6
          </div>

          <div
            className="absolute text-xs text-gray-400"
            style={{ right: MAP_TILE_SIZE_DESKTOP, top: '45%' }}
          >
            9
          </div>
          <div
            className="absolute text-xs text-gray-400"
            style={{ left: MAP_TILE_SIZE_DESKTOP, top: '45%' }}
          >
            3
          </div>
        </div>
        <div
          className="absolute text-sm text-greyDark"
          style={{ left: MAP_TILE_SIZE_DESKTOP + 16 }}
        >
          Average time spent on activity
        </div>
      </div>
     <div></div>
      <div style={{ display: 'flex', alignItems: 'left', marginTop:20,marginLeft:-10}}>
      <button type="button" onClick={handleCaptureDownoadClick} style={{ color: 'rgb(9, 126, 118)' }}disabled={isDownloading} className="flex items-center">
          {isDownloading ? 'Downloading...' : <span className=" text-black"style={{  fontSize:16,fontWeight:700,letterSpacing:0.5 }} >DOWNLOAD</span>}
          {!isDownloading && (
            <DownloadIcon
              className="ml-2"
              style={{  width: '26px', height: '26px', stroke: 'rgb(9, 126, 118)', strokeWidth: '2' }} 
            />
          )}
        </button>
        {isDownloading && <img width={24} src={loadingIcon} alt='' />}
      </div>
    </div>
  )
}
