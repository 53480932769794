import React from 'react'
import { scaleLinear } from 'd3-scale'

import { SingleBarBarchart } from './SingleBarBarchart'
import { BROWN_TEXT_SHADOW } from '../../lib/styleUtils'

export const RegistrationToVoteChart = ({ size, datum }) => {
  const scale = scaleLinear().domain([0, 100]).range([0, size])
  const height = scale(datum.registration)

  return (
    <>
      <SingleBarBarchart size={size} barHeight={height} barFill="#A1887D" />

      <div
        className="absolute right-1 bottom-0.5 text-white text-sm "
        style={{ textShadow: BROWN_TEXT_SHADOW }}
      >
        {datum.registration}%
      </div>
    </>
  )
}
