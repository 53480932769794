import React from 'react'
import { max } from 'lodash-es'
import { scaleSqrt } from 'd3-scale'

import { TEXT_SHADOW } from '../../lib/styleUtils'

export const GdpChart = ({ dataset, datum, size, nigeriaWidth }) => {
  const gdpScale = scaleSqrt()
    .domain([0, max(dataset.flat().map((d) => d.gdpPerCapita))])
    .range([0, size])

  const width = gdpScale(datum.gdpPerCapita)
  const nigeriaWidthScaled = nigeriaWidth ? gdpScale(nigeriaWidth) : null
  return (
    <>
      <svg x={0} y={0} width={size} height={size}>
        {datum.gdpPerCapita && (
          <rect x={0} y={size - width} width={width} height={width} fill="#FAE1C7" />
        )}
        {nigeriaWidthScaled && (
          <rect
            x={0}
            y={size - nigeriaWidthScaled}
            width={nigeriaWidthScaled}
            height={nigeriaWidthScaled}
            fill="none"
            stroke="#C2915B"
            strokeWidth={1}
          />
        )}
      </svg>

      <div
        className="absolute left-1 top-1 flex flex-col gap-y-1"
        style={{ textShadow: TEXT_SHADOW }}
      >
        {datum.countryName && <span className="text-black text-xs">{datum.countryName}</span>}
        {datum.gdpPerCapita && (
          <span className="text-black text-sm font-bold">${(datum.gdpPerCapita.toFixed(2))}</span>
        )}
      </div>
    </>
  )
}
