import { observer } from 'mobx-react-lite'
import React from 'react'
export const TemperaturesSummerystatus = observer(() => {

  
  return (
    <div className=" ">
      <div style={{ borderTop: '1px solid lightgray',marginLeft:-10 ,marginBottom:0}}>   </div> 
      
        <div className=' xl:col-span-2 flex flex-col  top-0 'style={{ marginRight: 0,marginTop: 0 }}>

 <table className=' border-b    flex-1 ' style={{  marginRight: 0,maxWidth:3000 ,minHeight:40 }}>
 <tr >
 <th ></th>
 <th></th>
 <th></th>
 <th></th>
 </tr>

 
 <tr>
 
 {/* <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> Average change in annual temperature </tr>
 <tr class="custom-Geography2">  (2000-2020 to 2021-2022): 1.0% </tr>
 </td> */}
 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> Hottest Month (ave. 2021-2022) </tr>
 <tr class="custom-Geography2"> April (34.44<sup>ºC</sup>) </tr>
 <tr style={{ fontSize: 12 ,fontWeight: 500,padding:3,color: '#FFFFFF'}}> .</tr>
 <tr style={{ fontSize: 12 ,fontWeight: 500,padding:3,color: '#FFFFFF'}}> .</tr>
 </td>
 
 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> Coldest Month (ave. 2021-2022) </tr>
 <tr class="custom-Geography2">August (26.82<sup>ºC</sup>)</tr>
 <tr style={{ fontSize: 12 ,fontWeight: 500,padding:3,color: '#FFFFFF'}}> .</tr>
 <tr style={{ fontSize: 12 ,fontWeight: 500,padding:3,color: '#FFFFFF'}}> .</tr>
 </td>
 

 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1">  Hottest states (2021-2022) </tr>
 <tr class="custom-Geography2">Borno </tr>
 <tr class="custom-Geography2">Yobe</tr>
 <tr class="custom-Geography2"> Sokoto</tr>
 </td>
 
 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> Coldest states (2021-2022)</tr>
 <tr class="custom-Geography2"> Bayelsa</tr>
 <tr class="custom-Geography2"> Rivers </tr>
 <tr class="custom-Geography2"> Akwa Ibom</tr>
 </td>


 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> Greatest increase in ave. temp </tr>
 <tr class="custom-Geography2"> Kwara</tr>
 <tr class="custom-Geography2"> FCT</tr>
 <tr class="custom-Geography2"> Niger </tr>
 </td>

 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> Greatest decrease in ave temp</tr>
 <tr class="custom-Geography2"> Bayelsa</tr>
 <tr class="custom-Geography2">Rivers </tr>
 <tr class="custom-Geography2">  Akwa Ibom </tr>
 </td>


 
 
 </tr>
 
 </table>
 <div  className='flex-1 text-greyDark' style={{ fontSize: 10 ,minHeight:29,fontWeight:500,marginTop: 3  }}>
Source: National Aeronautics and Space Administration (NASA) Langley Research Center (LaRC) Prediction of Worldwide Energy Resource (POWER)Project funded through the NASA Earth Science/Applied Science Program: Monthly & Annual Precipitation, POWER Data Access Viewer v2.0.0,1981-2020. Available  <a class="custom-link" href="https://developers.google.com/earth-engine/datasets/catalog/MODIS_061_MOD11A2" target="_blank" > here</a> 

 
      
</div>
 </div>
 
 
      
 
      </div>

      


  )

})
