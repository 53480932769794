import { observer } from 'mobx-react-lite'
import React from 'react'
export const LandUseSummerystatus = observer(() => {

  
  return (
    <div className=" ">
      <div style={{ borderTop: '1px solid lightgray',marginLeft:-10 }}>   </div> 
      
        <div className=' xl:col-span-2 flex flex-col  top-0 'style={{ marginRight: 0,marginTop: 0 }}>

 <table className=' border-b    flex-1 ' style={{  marginRight: 0,maxWidth:3000  }}>
 <tr >
 <th ></th>
 <th></th>
 <th></th>
 <th></th>
 </tr>

 
 <tr>
 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> Open land/shrubs </tr>
 <tr class="custom-Geography2"> 1975:52%</tr>
 <tr class="custom-Geography2"> 2020:36%</tr>
 <tr style={{ fontSize: 12 ,fontWeight: 500,padding:3,color: '#FFFFFF'}}> .</tr>
 </td>
 


 

 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> Forest </tr>
 <tr class="custom-Geography2"> 1975:22%</tr>
 <tr class="custom-Geography2"> 2020:14%</tr>
 <tr style={{ fontSize: 12 ,fontWeight: 500,padding:3,color: '#FFFFFF'}}> .</tr>
 </td>
 
 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> Cropland/vegetation </tr>
 <tr class="custom-Geography2"> 1975:21%</tr>
 <tr class="custom-Geography2"> 2020:37%</tr>
 <tr style={{ fontSize: 12 ,fontWeight: 500,padding:3,color: '#FFFFFF'}}> .</tr>
 </td>


 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1">Largest forest (km<sup>2</sup>) </tr>
 <tr class="custom-Geography2"> Taraba, Cross River, Delta</tr>

 <tr class="custom-Geography1">Largest forest (% state area)</tr>
 <tr class="custom-Geography2"> Bayelsa, Delta, Rivers</tr>

 </td>
 
 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> Largest cropland (km<sup>2</sup>) </tr>
 <tr class="custom-Geography2"> Niger, Borno, Bauchi</tr>

 <tr class="custom-Geography1"> Largest cropland (% state area) </tr>
 <tr class="custom-Geography2"> Kano, Jigawa, Katsina</tr>

 </td>

 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> Largest open land/shrubs (km<sup>2</sup>) </tr>
 <tr class="custom-Geography2"> Borno, Niger, Yobe</tr>

 <tr class="custom-Geography1"> Largest open land/shrubs (% state area) </tr>
 <tr class="custom-Geography2"> Osun, Ekiti, Ondo</tr>

 </td>
 </tr>
 
 </table>
 <div  className='flex-1  text-greyDark' style={{ fontSize: 10 ,minHeight:29,fontWeight:500,marginTop: 2 }}>
Source: 1975 - 2013: Tappan, G. G., Cushing, W.M., Cotillon, S.E., Mathis, M.L., Hutchinson, J.A., Herrmann, S.M., and Dalsted, K.J., 2016, West Africa Land Use Land Cover Time Series: U.S. Geological Survey data release. Available <a class="custom-link" href="http://dx.doi.org/10.5066/F73N21JF" target="_blank" > here</a>  
 2020: Karra, Kontgis, et al. “Global land use/land cover with Sentinel-2 and deep learning.” IGARSS 2021-2021 IEEE International Geoscience and Remote Sensing Symposium. IEEE, 2021. Available <a class="custom-link" href="https://www.arcgis.com/home/item.html?id=d6642f8a4f6d4685a24ae2dc0c73d4ac" target="_blank" > here</a>

 
      
</div>
 </div>
 
 
      
 
      </div>

      


  )

})
