import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMst } from '../../state';

export const ImportExportVizFilter = observer(() => {
    const {
        filter: { importExportSelectedDataset, setImportExportSelectedDataset },
    } = useMst();

    const handleKeyDown = (event, dataType) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            setImportExportSelectedDataset(dataType);
        }
    };

    return (
        <div style={{ paddingTop: 20 }}>
            {['Import and export(USD billion)', 'Top 5 trade partners'].map((dataType) => {
                const isSelected = importExportSelectedDataset === dataType;
                return (
                    <div
                        key={dataType}
                        className={`flex items-center cursor-pointer activity-item ${isSelected ? 'selected font-bold' : ''}`}
                        onClick={() => setImportExportSelectedDataset(dataType)}
                        onKeyDown={(event) => handleKeyDown(event, dataType)}
                        tabIndex={0}  // Makes the item focusable
                        style={{ color: isSelected ? '#f0ac67' : '#000000', backgroundColor: isSelected ? 'rgba(251, 231, 209, 0.5)' : 'transparent', padding: '5px' }}
                        aria-label={`Select ${dataType}`}  // Provides a descriptive label for screen readers
                        role="button"  // Indicates the element behaves like a button
                    >
                        <div className={`${isSelected ? '' : 'w-0'}`} />
                        <div style={{ fontSize: 20 }}>{dataType}</div>
                    </div>
                );
            })}
        </div>
    );
});
