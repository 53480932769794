import React, { useMemo, PureComponent, useState } from 'react'
import { arc, pie, text } from 'd3'

import { PieChart as PieChrt, Pie, Sector, Cell, ResponsiveContainer, Tooltip } from 'recharts';

import { TEXT_SHADOW } from '../../lib/styleUtils'

const COLORS = ["#edf6f5","#1f635e" , "#55aaa3", "#add6d3"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
    const x = cx + radius * Math.cos(-midAngle * RADIAN) + 2;
    const y = cy + radius * Math.sin(-midAngle * RADIAN) + 2;

    return (
        <text style={{ fontSize: 12 }} x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(2)}%`}
        </text>
    );
};

export const PieChart = ({ datum, size, chartFill, isInteractive = true }) => {
    const [tileHover, setTileHover] = useState(false)
    return (
        <div
            style={{ width: size, height: size, position: 'relative' }}
            onMouseEnter={() => {
                setTileHover(true)
            }}
            onMouseLeave={() => {
                setTileHover(false)
            }}
        >
            <ResponsiveContainer width="100%" height="100%">
                <PieChrt width={400} height={400}>
                    <Pie
                        data={datum.pieData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={size / 2}
                        fill="#8884d8"
                        dataKey="value"
                        isAnimationActive={false}
                        stroke=''
                    >
                        {datum.pieData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                </PieChrt>
            </ResponsiveContainer>
            {
                isInteractive && tileHover && (
                    <div style={{ position: 'absolute', bottom: size / 2 - 90, right: size + 10, border: '1px solid #39726b', background: 'white', width: 250, height: 120, zIndex: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ padding: 10 }}>
                                <div style={{ fontSize: 15, fontWeight: 1000, paddingBottom: 25 }}>
                                    {datum.state}
                                </div>
                                {datum.pieData.map((entry, index) => (
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <div className="flex gap-x-1">
                                            <div className="square w-4 h-4" style={{ background: COLORS[index % COLORS.length] }} />
                                            <div className="label text-sm">{entry.label}</div>
                                        </div>
                                        <div className="percentage text-sm font-semibold">{entry.value.toFixed(2)}%</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}
