import React from 'react'
import { hierarchy, treemap } from 'd3'

export const TreeMapChart = ({ datum, size, label, fill }) => {
  const datumFilteredByLabel = datum.filter((d) => label === d.type)

  const root = hierarchy({
    name: 'root',
    children: datumFilteredByLabel.map((d) => ({ ...d, value: d.pct })),
  }).sum((d) => {
    return d.hasOwnProperty('pct') ? d.pct : 0
  })

  const tree = treemap().size([size, size])(root)
  const rects = tree.children ?? []

  return (
    <>
      <svg x={0} y={0} width={size} height={size}>
        {rects.map((d, i) => {
          return (
            <g key={i}>
              <rect
                x={d.x0}
                y={d.y0}
                height={d.y1 - d.y0}
                width={d.x1 - d.x0}
                fill={fill}
                stroke="white"
              />
              <text x={d.x0 + 1} y={d.y0 + 8} fontSize="7px">
                {d.data.iso3}
              </text>
            </g>
          )
        })}
      </svg>
    </>
  )
}
