import React from 'react'
import { scaleLinear } from 'd3-scale'

import { HDI_INDEXES } from '../../lib/constants'
import { TEXT_SHADOW } from '../../lib/styleUtils'
import { MultipleBarsBarchart } from './MultipleBarsBarchart'
import { MultipleLinesBarchart } from './MultipleLinesBarchart'

export const HDIChart = ({ dataset, datum, size, colorScale }) => {
  const barLengthScale = scaleLinear().domain([0, 1]).range([0, size])

  const nigeriaAvg = dataset ? dataset.find((el) => el.stateName === 'Nigeria') : null

  return (
    <>
      <svg x={0} y={0} width={size} height={size}>
        <g>
          {datum.HDI && (
            <MultipleBarsBarchart
              groups={HDI_INDEXES}
              datum={datum}
              width={size*1.05}
              height={size}
              barLengthScale={barLengthScale}
              colorScale={colorScale}
            />
          )}

          {nigeriaAvg && (
            <MultipleLinesBarchart
              groups={HDI_INDEXES}
              datum={nigeriaAvg}
              width={size}
              height={size}
              barLengthScale={barLengthScale}
              fill="#7A6E48"
            />
          )}
        </g>
      </svg>

      {datum.HDI && (
        <div
          className="absolute right-1 top-1 text-black text-sm font-bold"
          style={{ textShadow: TEXT_SHADOW }}
        >
          {datum.HDI.toFixed(2)}
        </div>
      )}
    </>
  )
}
