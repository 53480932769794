import { createContext, useContext } from 'react'
import { types as t } from 'mobx-state-tree'
import { UIModel } from './UIModel'
import { DataModel } from './DataModel'
import { FilterModel } from './FilterModel'

export const StateModel = t.model('StateModel', {
  ui: t.optional(UIModel, {}),
  data: t.optional(DataModel, {}),
  filter: t.optional(FilterModel, {}),
})

export const stateInstance = StateModel.create()

const RootStateContext = createContext(null)
export const Provider = RootStateContext.Provider

export function useMst() {
  const state = useContext(RootStateContext)
  if (state === null) throw new Error('State cannot be null, please add a context provider')
  return state
}
