import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

import { ChartContent } from './ChartContent'
import { MultipleSquaresGrid } from '../chartContainers/MultipleSquaresGrid'
import { MultipleSquaresTile } from '../chartContainers/MultipleSquaresTile'

export const MovementViz = observer(() => {
  const {
    data: { movementGroupedByState },
  } = useMst()

  return (
    <div style={{ marginBottom: -900}}>
      <ChartContent
        chart={
          <MultipleSquaresGrid
            dataset={movementGroupedByState}
            MultipleSquaresTileChart={MultipleSquaresTile}
          />
        }
      />
    </div>
  )
})
