import React from 'react';
import { useNavigate } from 'react-router-dom';
import { dataset } from '../dataset/dataset';
import { MENU_WIDTH } from '../lib/constants';
import { WobbleBubbleIcon } from './WobbleBubble';

export function VerticalIndexMenu({ activeSection }) {
  const navigate = useNavigate();

  // Function to handle keyboard navigation
  const handleKeyDown = (event, section) => {
    if (event.key === 'Enter') {
      navigate(`/${section.url}`);
    }
  };

  return (
    <div
      className="h-full pt-4 pb-6 bg-whiteSmoke z-10 border flex flex-col flex-wrap justify-between"
      style={{ width: MENU_WIDTH }}
    >
      <div className="flex flex-col mt-16 ml-3">
        {dataset.map((section) => {
          const isActive = activeSection.id === section.id;

          return (
            <div
              key={section.id}
              className={`group flex items-center min-w-9 max-w-9 hover:max-w-96 h-9 mb-1 rounded-full cursor-pointer border hover:bg-black transition-all duration-1000 ${
                isActive ? 'bg-white border-black' : 'bg-transparent border-transparent'
              }`}
              style={{ paddingLeft: (36 - 20) / 2 - 1 }}
              onClick={() => navigate(`/${section.url}`)}
              tabIndex={0} 
              onKeyDown={(event) => handleKeyDown(event, section)} 
            >
              <WobbleBubbleIcon colorClass={section.colorClass} width={18} />
              <div className="font-bold text-transparent group-hover:text-white pl-3 overflow-hidden whitespace-nowrap transition-maxWidth duration-1000 pr-3">
                {section.name}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
