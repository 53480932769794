import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMst } from '../../state';

export const GDPVizFilter = observer(() => {
    const {
        filter: { GDPSelectedDataset, setGDPSelectedDataset },
    } = useMst();

    const handleKeyDown = (event, dataType) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            setGDPSelectedDataset(dataType);
        }
    };

    return (
        <div style={{ paddingTop: 20, paddingLeft: 35 }}>
            {['GDP(USD billion)', 'GDP per capita(USD)'].map((dataType) => {
                const isSelected = GDPSelectedDataset === dataType;
                return (
                    <div
                        key={dataType}
                        className={`flex items-center cursor-pointer activity-item ${isSelected ? 'selected font-bold' : ''}`}
                        onClick={() => setGDPSelectedDataset(dataType)}
                        onKeyDown={(event) => handleKeyDown(event, dataType)}
                        tabIndex={0}
                        style={{ color: isSelected ? '#f0ac67' : '#000000', backgroundColor: isSelected ? 'rgba(251, 231, 209, 0.5)' : 'transparent', padding: '5px' }}
                        aria-label={`Select ${dataType}`}
                    >
                        <div className={`${isSelected ? '' : 'w-0'}`} />
                        <div style={{ fontSize: 20 }}>{dataType}</div>
                    </div>
                );
            })}
        </div>
    );
});
