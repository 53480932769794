import React, { useState } from 'react'
import { downloadElementToImage } from '../utils/utils';
import { MapTile } from '../components/chartContainers/MapTile'
import { StackedBarchart } from '../components/smallCharts/StackedBarchart'
// import DownloadIcon from '@mui/icons-material/Download';
import { ReactComponent as DownloadIcon } from '../assets/download-icon.svg';
import { NIGERIA_MAIN_TYPE_OF_WORK_COLOR_SCALE } from '../lib/constants'
const loadingIcon = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

export const NigerianMainTypeOfWorkLegend = () => {
    const [isDownloading, setIsDownloading] = useState(false);

    const mockDatum = {
        stateCode: 'LA',
        stateName: 'Lagos',
        'Self employed (farm)': 2,
        'Self employed (non-farm)': 63,
        'Employee': 27,
        'Not engaged in economic activity': 7,
    }

    const handleCaptureDownoadClick = () => {
        const sectionComponent = document.getElementById("subsection");
        const snapshotElem = sectionComponent.getElementsByClassName("chartcontent-component")?.[0];
        if (snapshotElem) {
          setIsDownloading(true);
          setTimeout(async () => {
            await downloadElementToImage(snapshotElem,'Main_Type_Of_Work.png');
            setIsDownloading(false);
          }, 0)
        }
      }
    return (
        <div className="ml-10 flex flex-col gap-y-2">
             <div className="font-bold">How to read it</div>
            <div className="relative flex gap-x-3" style={{ minHeight: 75 }}>
                <div className="absolute">
                    <MapTile
                        size={75}
                        borderColor="#2D9992"
                        MapTileChart={() => (
                            <div className="absolute text-greyDark top-1 left-1 text-xs">LA</div>
                        )}
                    />
                    <div
                        className="absolute top-0 text-sm text-greyDark whitespace-nowrap font-medium"
                        style={{ left: 90 }}
                    >
                        State code
                    </div>
                </div>
            </div>

            <div className="relative flex gap-x-3 mt-3" style={{ minHeight: 75 }}>
                <div className="absolute" style={{ minHeight: 75, minWidth: 75 }}>
                    <MapTile
                        size={75}
                        datum={mockDatum}
                        colorScale={NIGERIA_MAIN_TYPE_OF_WORK_COLOR_SCALE}
                        borderColor="#2D9992"
                        MapTileChart={StackedBarchart}
                    />

                    <div className="absolute top-0 text-xs text-greyDark" style={{ right: '102%' }}>
                        100%
                    </div>
                    <div className="absolute bottom-0 text-xs text-greyDark" style={{ right: '102%' }}>
                        0%
                    </div>
                </div>
                <div className="absolute text-sm text-greyDark font-medium" style={{ left: 90 }}>
                    Type of Work(%)
                </div>
            </div>

            <div className="flex flex-col gap-y-1 mt-3">
                {NIGERIA_MAIN_TYPE_OF_WORK_COLOR_SCALE.domain()
                    .reverse()
                    .map((label) => (
                        <div className="legend-element flex justify-between" key={label}>
                            <div className="flex gap-x-1">
                                <div
                                    className="square w-4 h-4"
                                    style={{ background: NIGERIA_MAIN_TYPE_OF_WORK_COLOR_SCALE(label), border: '1px solid #2D9992' }}
                                />
                                <div className="label text-sm">{label}</div>
                            </div>
                        </div>
                    ))}
            </div>
            <div style={{ display: 'flex', alignItems: 'left',marginTop:20,marginLeft:-10 }}>
            <button type="button" onClick={handleCaptureDownoadClick} style={{ color: 'rgb(9, 126, 118)' }}disabled={isDownloading} className="flex items-center">
          {isDownloading ? 'Downloading...' : <span className=" text-black"style={{  fontSize:16,fontWeight:700,letterSpacing:0.5 }} >DOWNLOAD</span>}
          {!isDownloading && (
            <DownloadIcon
              className="ml-2"
              style={{  width: '26px', height: '26px', stroke: 'rgb(9, 126, 118)', strokeWidth: '2' }} 
            />
          )}
        </button>
        {isDownloading && <img width={24} src={loadingIcon} alt='' />}
      </div>
        </div>
    )
}
