import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

import { ChartContent } from './ChartContent'
import { SquareTilesGrid } from '../chartContainers/SquareTilesGrid'
import { EconomicContributionChart } from '../smallCharts/EconomicContributionChart'
import { SQUARE_TILE_SIZE } from '../../lib/constants'

export const EconomicContributionViz = observer(() => {
  const {
    data: { economicContributionOrdered },
  } = useMst()

  return ( <div className="Economic_structure_component"style={{  paddingTop: 10 }}> 
    
    <ChartContent
      chart={
        <SquareTilesGrid
          SquareTileChart={EconomicContributionChart}
          dataset={economicContributionOrdered}
          size={SQUARE_TILE_SIZE}
          gridGap={90}
          borderColor="#E4E4E4"
        />
      }
    />
  </div>
  )
})
