import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

import { ChartContent } from './ChartContent'
import { Map } from '../chartContainers/Map'
import { StrengthChart } from '../smallCharts/StrengthChart'

export const StrengthFromAbroadViz = observer(() => {
    const {
        data: { strengthAbroad },
    } = useMst()

    return (
        <div style={{ paddingTop: 20}}>
        <ChartContent
            isMapChart={true}
            chart={
                <Map
                    dataset={strengthAbroad}
                    isInteractive={false}
                    borderColor="#2D9992"
                    MapTileChart={StrengthChart}
                />
            }
        />
        </div>
    )
})
