import React from 'react'

import { ChartContent } from './ChartContent'

export const NightSpatialViz = () => {
  return (
    <div style={{  paddingTop: 10 }}>
    <ChartContent
      chart={
        <div className="flex items-center h-full pl-2 pt-5">
          <img
            src={`spatial/NightLight.jpg`}
            alt="night light raster"
            style={{ maxHeight: '100vh', maxWidth: 'auto', objectFit: 'contain' }}
          />
        </div>
      }
    />
     </div>
  )
}
