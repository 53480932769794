import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useMst } from '../../state';

const FILTER_DATA = {
    concern: [
        'the coronavirus pandemic?',
        'being a victim of crime?',
        'having enough money to cover household necessities?',
        'climate change?'
    ],
    satisfaction: [
        'Describe your level of satisfaction with the way the Government is doing its job?'
    ],
    towards2023: [
        'Interest in politics',
        'Trust in political parties'
    ]
};

export const ConcernsFilter = observer(({ chartType = 'concern' }) => {
    const {
        filter: { concernSelected, setConcernSelected },
    } = useMst();

    const [filterData, setFilterData] = useState(FILTER_DATA.concern);

    useEffect(() => {
        switch (chartType) {
            case 'concern':
                setFilterData(FILTER_DATA.concern);
                break;
            case 'satisfaction':
                setFilterData(FILTER_DATA.satisfaction);
                break;
            case 'towards2023':
                setFilterData(FILTER_DATA.towards2023);
                break;
            default:
                break;
        }
        setConcernSelected(FILTER_DATA[chartType][0]);
    }, [chartType]);

    const handleKeyDown = (event, dataType) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            setConcernSelected(dataType);
        }
    };

    return (
        <div className="flex flex-col">
            {filterData.map((dataType) => {
                const isSelected = concernSelected === dataType;
                return (
                    <div
                        key={dataType}
                        className={`flex items-center cursor-pointer activity-item ${isSelected ? 'selected font-bold' : ''}`}
                        onClick={() => setConcernSelected(dataType)}
                        onKeyDown={(event) => handleKeyDown(event, dataType)}
                        tabIndex={0}
                        style={{ width: 300, color: isSelected ? '#8D796E' : '#000000', paddingBottom: 10, backgroundColor: isSelected ? '#e2ddda' : 'transparent', padding: '5px' }}
                        aria-label={`Filter by ${dataType}`}
                    >
                        <div className="" style={{ textOverflow: 'ellipsis', fontSize: 20 }}>{dataType}</div>
                    </div>
                );
            })}
        </div>
    );
});
