import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ReactComponent as Logo } from '../assets/home_icon.svg'
import { ReactComponent as Logo2 } from '../assets/UNDP_logo.svg'


import { dataset } from '../dataset/dataset'
import { WobbleBubbleIcon } from './WobbleBubble'
import { ReactComponent as AccordionOpenIcon } from '../assets/accordion-open.colors.svg'
import { ReactComponent as AccordionClosedIcon } from '../assets/accordion-closed.colors.svg'

const rowHeight = 50

export default function MenuMobile() {
  const { pathname } = useLocation()
  const currentSection = dataset.find((section) => pathname.includes(section.url))

  const [openItem, setOpenItem] = useState(currentSection.id)
  const [colorClass, setColorClass] = useState(currentSection.colorClass)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const navigate = useNavigate()

  return (
    
    <div style={{ marginTop:46 }}>
         
      <div style={{ position: 'fixed', bottom: 0, left: 0, right: 0}}>
        <div
          style={{
            position: 'absolute',
            bottom: rowHeight,
            left: 0,
            right: 0,
            overflow: 'hidden',
            maxHeight: isMenuOpen ? rowHeight * (dataset.length + 1) : 0,
            transition: 'all 1s ease',
          }}
        >
           <Logo className="w-6 ml-2 cursor-pointer" onClick={() => navigate('/')} />
          {dataset.map((section, i) => {
            return (
              <Row
                key={section.id}
                item={section.id}
                iconColor={section.colorClass}
                onClick={() => {
                  navigate(`/${section.url}`)
                  setOpenItem(section.id)
                  setColorClass(section.colorClass)
                  setIsMenuOpen(false)
                }}
                isInMenu
              />
              
            )
          })}

        </div>
        
        <Row
          key={openItem}
          item={openItem}
          iconColor={colorClass}
          withAccordion
          accordionOpen={isMenuOpen}
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
          }}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        />
      </div>
     
    </div>
  )
}

function Row({
  item,
  onClick = () => {},
  style = {},
  withAccordion = false,
  accordionOpen = false,
  isInMenu = false,
  iconColor,
}) {

  return (
    
    <div
      key={item}
      style={{
        display: 'flex',
        height: rowHeight,
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 15px',
        backgroundColor: isInMenu ? 'black' : '#4e4e4e',
        cursor: 'pointer',
        ...style,
      }}
      
      onClick={onClick}
    >


      <div style={{ display: 'flex', alignItems: 'center' }}>
        
      <div style={{ width: 0, marginRight:30,marginLeft:-5}}>
  {/* {withAccordion ? (
    accordionOpen ? (
      <Logo2 className="w-5.5 ml-0 "  />
    ) : (
      <Logo2 className="w-5.5 ml-0 "  />
    )
  ) : null} */}

</div>
        <WobbleBubbleIcon colorClass={iconColor} width={20} />
        <div style={{ marginLeft: 10, color: 'white' }}>{item}</div>
      </div>
      <div style={{ width: 20 }}>
        {withAccordion ? (
          accordionOpen ? (
            <AccordionOpenIcon width={20} />
          ) : (
            <AccordionClosedIcon width={20} />
          )
        ) : null}
        
      </div>



    </div>
    
  )
  
}
