import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

import { MapDesktop } from './MapDesktop'
import { MapMobile } from './MapMobile'

import { MAP_TILE_SIZE_MOBILE, MAP_TILE_SIZE_DESKTOP } from '../../lib/mapConstants'

export const Map = observer(({ ...props }) => {
  const {
    ui: { isMobile },
  } = useMst()

  const tileSizeDesktop = MAP_TILE_SIZE_DESKTOP
  const tileSizeMobile = MAP_TILE_SIZE_MOBILE

  return (
    <div style={{ padding: 10}}>
      {isMobile ? (
        <MapMobile tileSize={tileSizeMobile} {...props} />
      ) : (
        <MapDesktop tileSize={tileSizeDesktop} {...props}  />
      )}
    </div>
  )
})
