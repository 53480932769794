import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

import { ChartContent } from './ChartContent'
import { Map2 } from '../chartContainers/Map2'
import { StackedBarchart } from '../smallCharts/StackedBarchart'

import { RELIGIOUS_SERVICES_COLOR_SCALE } from '../../lib/constants'

export const ReligiousServicesViz = observer(() => {
    const {
        data: { religiousServices },
    } = useMst()

    return (
        <div style={{ paddingTop: 20}}>
        <ChartContent
            isMapChart={true}
            chart={
                <Map2
                    dataset={religiousServices}
                    isInteractive={true}
                    borderColor="#2D9992"
                    colorScale={RELIGIOUS_SERVICES_COLOR_SCALE}
                    MapTileChart={StackedBarchart}
                />
            }
            Legend={() => null}
        />
        </div>
    )
})
