import React, { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

import { ConcernChart } from '../smallCharts/ConcernChart'

export const ConcernsViz = observer(({ chartType = 'concern' }) => {
    const {
        data: { findConcernData },
        filter: { concernSelected }
    } = useMst()

    const containerRef = useRef(null)

    return (
        <div id="ConcernsVizContainer" ref={containerRef} className="" style={{  marginBottom: '0vh' }}>
        <div className='concern-component grid grid-cols-1 lg:grid-cols-1 xl:grid-cols-3 mx-20 pt-10 pb-10 gap-y-2' style={{
            
       
        }}>
            <div className='flex flex-col col-span-3 xl:col-span-1'>
            <div style={{ fontWeight: 800 ,fontSize:15 }}>
                    Total
                </div >
                <ConcernChart size={93} datum={findConcernData(chartType, concernSelected, "total", "total").data} heading="&nbsp;" />
            </div>
            <div className='xl:col-span-2 flex flex-col'>
                <div style={{ fontWeight: 800 ,fontSize:15 }}>
                    Gender
                </div>
                <div className='flex-1' style={{ borderTop: '1px solid black', marginRight: 80 }}></div>
                <div className='grid grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 'style={{ fontSize:14 }}>
                    <ConcernChart size={93} datum={findConcernData(chartType, concernSelected, 'gender', 'male').data} heading="Male" />
                    <ConcernChart size={93} datum={findConcernData(chartType, concernSelected, 'gender', 'female').data} heading="Female" />
                </div>
            </div>
            <div className='col-span-3 flex flex-col'>
            <div style={{ fontWeight: 800 ,fontSize:15 }}>
                    Age Group
                </div>
                <div className='flex-1' style={{ borderTop: '1px solid black', marginRight: 80 }}></div>
                <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 'style={{ fontSize:14 }}>
                    <ConcernChart size={93} datum={findConcernData(chartType, concernSelected, 'age', '18-34').data} heading='18-34' />
                    <ConcernChart size={93} datum={findConcernData(chartType, concernSelected, 'age', '35-60').data} heading='35-60' />
                    <ConcernChart size={93} datum={findConcernData(chartType, concernSelected, 'age', '61 years +').data} heading='61 years +' />
                </div>
            </div>
            <div className='col-span-3 flex flex-col'>
            <div style={{ fontWeight: 800 ,fontSize:15 }}>
                    Geopolitical Zone
                </div>
                <div className='flex-1' style={{ borderTop: '1px solid black', marginRight: 80 }}></div>
                <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 'style={{ fontSize:14 }}>
                    <ConcernChart size={93} datum={findConcernData(chartType, concernSelected, 'zone', 'North Central').data} heading='North Central' />
                    <ConcernChart size={93} datum={findConcernData(chartType, concernSelected, 'zone', 'North East').data} heading='North East' />
                    <ConcernChart size={93} datum={findConcernData(chartType, concernSelected, 'zone', 'North West').data} heading='North West' />
                    <ConcernChart size={93} datum={findConcernData(chartType, concernSelected, 'zone', 'South East').data} heading='South East' />
                    <ConcernChart size={93} datum={findConcernData(chartType, concernSelected, 'zone', 'South South').data} heading='South South' />
                    <ConcernChart size={93} datum={findConcernData(chartType, concernSelected, 'zone', 'South West').data} heading='South West' />
                </div>
            </div>
            
        </div>
        </div>
    )
})
