import React from 'react'
import { scaleLinear } from 'd3-scale'
import { SingleBarBarchart } from './SingleBarBarchart'

export const WhatNigeriansAskChart = ({ datum, size }) => {
  const trendScale = scaleLinear().domain([0, 100]).range([0, size])

  const height = trendScale(datum.trendValue)
  const fill = 'rgba(48, 153, 146, 0.9)'

  return (
    <>
      <SingleBarBarchart size={size} barHeight={height} barFill={fill} />

      <div className="absolute left-1 flex flex-col gap-y-1" style={{ bottom: height }}>
        <span className="text-black text-sm font-bold">{(datum.trendValue).toFixed(2)}</span>
      </div>

      <div className="text-black text-sm pt-3">{datum.keyword}</div>
      <div
        className="absolute"
        style={{ background: 'black', height: 1, width: size, bottom: '-1px' }}
        
      />
      <br/> <br/> <br/> <br/> <br/> 
    </>
    
  )
}
